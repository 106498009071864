import React, {useState, useEffect} from 'react';
import moment from 'moment';

import Title from '../../components/Custom/Title';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomTabs from '../../components/antdCustom/CustomTabs';

import Header from './Header';

import { getLotdata } from '../../services/api/BatchQuery';

import {MySubtitleH4} from '../../styled/publicTag';

const innerWidth = window.innerWidth;

const BatchQuery = () => {
  
  const [s_dataSource, set_s_dataSource] = useState([]);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_TabKey, set_s_TabKey] = useState('F1');

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    page: 1,
    pageSize: 10
  });

  const [s_searchData, set_s_searchData] = useState({
    'wlID': '',
    'pdtlot': '',
    'start': moment(new Date()).format('YYYY-MM-DD'),     // 開始日期
    'end': moment(new Date()).format('YYYY-MM-DD'),       // 結束日期
  });

  const tableColumns = [
    {
      title: '批號',
      dataIndex: 'pdtlot',
      key: 'pdtlot',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '工單號',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: 'SOP單號',
      dataIndex: 'operN',
      key: 'operN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: '控程',
      dataIndex: 'sopno',
      key: 'sopno',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
    },
    {
      title: '觸發序',
      dataIndex: 'flagC',
      key: 'flagC',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${60}px`
    },
    {
      title: '段數序',
      dataIndex: 'stepC',
      key: 'stepC',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${70}px`
    },
    {
      title: '投料序',
      dataIndex: 'feedC',
      key: 'feedC',
      align: 'center',
      width: innerWidth <= 1440 ? `${60}px` : `${60}px`
    },
    {
      title: '氮氣',
      dataIndex: 'n2tube',
      key: 'n2tube',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${50}px`
    },
    {
      title: '氧氣',
      dataIndex: 'o2tube',
      key: 'o2tube',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${50}px`
    },
    {
      title: '冷卻水',
      dataIndex: 'cwpipe',
      key: 'cwpipe',
      align: 'center',
      width: innerWidth <= 1440 ? `${60}px` : `${60}px`
    },
    {
      title: '冰水',
      dataIndex: 'wpipe',
      key: 'wpipe',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`
    },
    {
      title: '氣壓',
      dataIndex: 'airtube',
      key: 'airtube',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`
    },
    {
      title: '搜集槽',
      dataIndex: 'catchW',
      key: 'catchW',
      align: 'center',
      width: innerWidth <= 1440 ? `${60}px` : `${60}px`
    },
    {
      title: '煤油溫度',
      dataIndex: 'keroTemp',
      key: 'keroTemp',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`
    },
    {
      title: '段數時間',
      dataIndex: 'timer',
      key: 'timer',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`
    },
    {
      title: '設定溫度',
      dataIndex: 'setTemp',
      key: 'setTemp',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`
    },
    {
      title: '槽內溫度',
      dataIndex: 'inTemp',
      key: 'inTemp',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`
    },
    {
      title: '氮氣流量',
      dataIndex: 'flowN',
      key: 'flowN',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`
    },
    {
      title: '氧氣流量',
      dataIndex: 'flowO',
      key: 'flowO',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`
    },
    {
      title: '轉速',
      dataIndex: 'frequency',
      key: 'frequency',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`
    },
    {
      title: '扭力',
      dataIndex: 'torque',
      key: 'torque',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`
    },
    {
      title: '重量差',
      dataIndex: 'diffW',
      key: 'diffW',
      align: 'center',
      width: innerWidth <= 1440 ? `${60}px` : `${60}px`,
      render: text => {
        return parseFloat(text.toFixed(2));
      }
    },
    {
      title: '重量',
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      width: innerWidth <= 1440 ? `${60}px` : `${60}px`,
      render: text => {
        return parseFloat(text.toFixed(2));
      }
    },
    {
      title: '切片型態',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    // {
    //   title: '途單歷程',
    //   dataIndex: 'lifeF',
    //   key: 'lifeF',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`
    // },
    // {
    //   title: '歷程時間',
    //   dataIndex: 'newlifeFT',
    //   key: 'newlifeFT',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    // },
    // {
    //   title: '工站',
    //   dataIndex: 'wsNM',
    //   key: 'wsNM',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    // },
  ]

  // call 表身 api
  useEffect(() => {
    const call_getLotdata = async () => {

      const res = await getLotdata(s_searchData);
      console.log('res = ', res)
      if(res.status === true) {
        set_s_dataSource(res.data)
      } else {
        set_s_dataSource([])
      }
    };
    call_getLotdata()
  }, [s_searchData]);

  const TabsData = [
    {
      'key': 'F1',
      'tabName': '第一槽'
    },
    {
      'key': 'F2A',
      'tabName': '第二A槽'
    },
    {
      'key': 'F2B',
      'tabName': '第二B槽'
    },
    {
      'key': 'F3',
      'tabName': '第三槽'
    }
  ];

  const test = s_dataSource.find(item => item.wlID === s_TabKey)

  console.log('test = ', test)

  return (
    <>
      <Title title='生產批號查詢'>
        <Header
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />  
      </Title>
      
      <CustomTabs
        onChange={activeKey => set_s_TabKey(activeKey)}
        activeKey={s_TabKey}
        TabsData={TabsData}
      />

      <CustomTable 
        // loading={tableLoading}
        scroll={{ x: "max-content",y: window.innerHeight - 60 - 45 - 220 }}
        size="15px"
        columns={tableColumns}
        dataSource={test && test.lotdata && test.lotdata.map((item, index) => {
          return  { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      /> 
    </>
  )
};

export default BatchQuery;