import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得所有車間資料 ----------------------------------------
export const getAllPS = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllPS`);
};

//----------------------------------------- 新增車間資料 ----------------------------------------
export const addPS = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPS`, data);
};

//----------------------------------------- 修改車間資料 ----------------------------------------
export const modifyPS = (id, data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyPS?psID=${id}`, data);
};

//----------------------------------------- 刪除車間資料 ----------------------------------------
export const deletePS = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePS`, data);
};

//----------------------------------------- 搜尋車間資料 ----------------------------------------
export const getPS = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPS?psID=${id}`);
};