import React, { useState, useEffect } from "react";

import StandardTable from './Tables/StandardTable/index';
import ItemTable from './Tables/ItemTable/index';

import { getAllSiptype } from '../../services/api/sipAPI';

export const DataContext = React.createContext();

const SIP = () => {
  
  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料
  const [ c_drawingno, set_c_drawingno ] = useState([]);   // 途號

  // 取得監控參數下拉選單
  useEffect(() => {
    const call_getAllSiptype = async () => {
      try {
        const res = await getAllSiptype();
        console.log('全部監控參數 = ', res)
        if(res.status === true) {
          set_c_drawingno(res.data);
        } else {
          set_c_drawingno([]);
        }
      } catch (e) {
        console.log('-------- call_getAllSiptype --------');
        set_c_drawingno([]);
      }
    }
    call_getAllSiptype();
  }, []);

  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData, c_drawingno}}>
      <div style={{height: '100%'}}>
        <div style={{height: '50%'}}>
          <StandardTable />
        </div>
        <div style={{height: '50%'}}>
          {Object.keys(c_lightData).length > 0 ? <ItemTable /> : null}
        </div>
        
      </div>
    </DataContext.Provider>
  );
};

export default SIP;