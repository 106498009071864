import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, message } from 'antd';

import { MySubtitleH4 } from '../../../../styled/publicTag';

import { CheckCircleFilled, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import StandardModalContainer from './StandardModalContainer'

import Title from '../../../../components/Custom/Title';
import CustomTable from '../../../../components/antdCustom/CustomTable';
import CustomModal from '../../../../components/antdCustom/CustomModal';

import { getAllSophd, deleteSop } from "../../../../services/api/sopAPI";

import { DataContext } from '../../index';


import {
  IconTrash,
  IconPen
} from '../../../../components/Icon'


message.config({
  duration: 1.5
})

const StandardTable = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);     // 是否開啟 dialog
  const [s_editData, set_s_editData] = useState({});          // 要編輯的資料
  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字
  const [scrollY, setScrollY] = useState("")

  let countRef = useRef(null);

  const { c_lightData, set_c_lightData } = useContext(DataContext); // 表身要顯示資料的key

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // 新增製程標準管理資料
  const createStandard = () => {
    set_s_showModal(true);
  }

  // 搜尋製程標準管理資料
  const onSearch = (value, event) => {

    const call_getAllSophd = async (value) => {

      if (value === '') return;

      const res = await getAllSophd({ sopID: value });

      if (res.status) {
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        set_s_tableData([]);
        message.error(res.msg);
      }

    }
    call_getAllSophd(value);
  }

  // 編輯製程標準管理資料
  const editStandard = rowData => {
    set_s_showModal(true);
    set_s_editData(rowData);
  }

  // 刪除製程標準管理資料
  const removeStandard = rowData => {
    Modal.confirm({
      title: '製程標準資料刪除確認',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      content: '請先確認要刪除的製程標準資料無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteSop(rowData);
        console.log('res = ', res)
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllStandard();
        set_c_lightData({})
      },
      cancelText: '取消',
    });
  }

  // 刪除搜尋文字時重新抓資料
  const handleChange = async value => {
    console.log(value)
    set_s_searchValue(value)
    if (value === '') {
      try {
        const res = await getAllSophd();
        console.log('res = ', res)
        if (res.data) {
          console.log('全部製程標準管理資料 = ', res.data);
          const tmpData = res.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          });
          set_s_tableData(tmpData)
        }

      } catch (e) {
        console.log('---------- api getAllSophd error ----------');
        console.log(e)
      }
    }
  }

  // 取得所有製程標準管理資料
  const call_getAllStandard = async () => {
    try {
      const res = s_searchValue === '' ? await getAllSophd() : await getAllSophd({ item: s_searchValue });
      console.log('res = ', res)
      if (res.data) {
        console.log('全部製程標準資料 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      }

    } catch (e) {
      console.log('---------- api getAllSophd error ----------');
      console.log(e)
    }
  }

  const tableColumns = [
    {
      title: "SOP文件編號",
      dataIndex: "sopID",
      key: "sopID",
      align: "center",
      // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: "版碼",
      dataIndex: "version",
      key: "version",
      align: "center",
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      align: "center",
      width: innerWidth <= 1440 ? `${120}px` : `${150}px`,
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      align: "left",
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`,
    },
    {
      title: "監控型態",
      dataIndex: "soptype",
      key: "soptype",
      align: "center",
      render: (item) => {
        return item === "A" ? "操作次序確認" : "數值比對確認"
      },
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: "控點編號",
      dataIndex: "ctrlspot",
      key: "ctrlspot",
      align: "center",
      render: (_, items) => {
        return items.ctrlspot === "wl" ? items.wlNM : items.wsNM
      },
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      key: "ctrlspot",
      align: "center",

      render: (item) => {
        return item === "wl" ? "工線比對" : "工站比對"
      },
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`
    },

    {
      title: "總控程",
      dataIndex: "sopno",
      key: "sopno",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
    },
    {
      title: "總觸發",
      dataIndex: "flagC",
      key: "flagC",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "總工段",
      dataIndex: "stepC",
      key: "stepC",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "總投料",
      dataIndex: "feedC",
      key: "feedC",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    },
    {
      title: "有效",
      dataIndex: "isvalid",
      key: "isvalid",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${100}px`,
      render: (item) => {
        return (
          <div className="checkIcon">
            {item === "0" ? <CloseCircleOutlined style={{color: 'gray'}} /> : <CheckCircleFilled className='successIcon' />}
          </div>
        )
      },
    },
    {
      title: "更新時間",
      dataIndex: "newUpdtT",
      key: "newUpdtT",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "操作",
      dataIndex: "render",
      key: "render",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex" }}>
            <IconPen
              onClick={(e) => {
                e.stopPropagation();
                editStandard(rowData);

              }}
            />
            <IconTrash
              onClick={(e) => {
                e.stopPropagation();
                removeStandard(rowData);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  // 即時計算table高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 64
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let tmpvh = Object.keys(c_lightData).length > 0 ? '50vh' : '100vh'

    let height = `calc(${tmpvh} - ${tHeaderBottom + extraHeight}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    console.log('height = ', height)
    return height
  }

  // 取得所有製程標準管理資料
  useEffect(() => {
    if (s_showModal === false) {
      call_getAllStandard();
      // call_fakeStandard();
    }
  }, [s_showModal]);

  // 表身有資料時計算高度
  useEffect(() => {
    setScrollY(getTableScroll({ ref: countRef }))
  }, [c_lightData]);

  return (
    <div>
      <Title
        title='製程標準管理'
        placeholder='SOP文件編號查詢'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createStandard}
      />
      <div ref={countRef}>
        <CustomTable
          size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
          scroll={{ x: 'max-content', y: scrollY }}
          columns={tableColumns}
          dataSource={s_tableData.map((item, index) => {
            return {
              key: index,
              ...item
            }
          })}
          rowClassName={(record) => {
            const rowKey = record.sopID + record.version + record.spotID;
            const dataKey = c_lightData.sopID + c_lightData.version + c_lightData.spotID;
            return rowKey === dataKey ? "clickRowStyle" : "";
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                const rowKey = record.sopID + record.version + record.spotID;
                const dataKey = c_lightData.sopID + c_lightData.version + c_lightData.spotID;
                if (rowKey === dataKey) {
                  set_c_lightData({})
                } else {
                  set_c_lightData(record)
                }
              }
            }
          }}
          pagination={{
            defaultCurrent: 1,
            total: pageInfo.totalItems,
            // current: abcBoardConfig.page,
            showSizeChanger: true,
            pageSize: pageInfo.pageSize,
            pageSizeOptions: ["10", "20", "30"],
            onChange: (current, size) => {
              setPageInfo({
                ...pageInfo,
                pageSize: size
              })
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />

      </div>
      {s_showModal ? (
        <CustomModal
          width='60%'
          title={Object.keys(s_editData).length > 0 ? "修改製程標準資料" : "新建製程標準資料"}
          visible={s_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          afterClose={() => {
            set_s_editData({});
            call_getAllStandard();
          }}
        >
          <StandardModalContainer
            set_s_showModal={set_s_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            call_getAllStandard={call_getAllStandard}
          />
        </CustomModal>
      ) : null}

    </div>
  )
}

export default StandardTable;