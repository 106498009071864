import styled from "styled-components";

// export const fontSize = {
//   h1: "26px",
//   h2: "23px",
//   h3: "20px",
//   h4: "18px",
//   p: "14px",
// };

// export const fontSize = {
//   h1: "22px",
//   h2: "19px",
//   h3: "17px",
//   h4: "14px",
//   p: "11px",
// };

export const fontSize = {
  h1: "29px",
  h2: "27px",
  h3: "25px",
  h4: "23px",
  h5: "21px",
  p: "19px",
};
export const MySubtitleH4 = styled.h4`
  font-size: ${fontSize.h4};
  font-weight: 400;
  white-space: nowrap;
  opacity: 0.6;
  margin: 3px;
  display: inline-block;
  color: ${({ theme }) => theme.text};
`;
export const MyMainColorH1 = styled.h1`
  font-size: ${fontSize.h1};
  font-weight: 400;
  white-space: nowrap;
  margin: 0;
  color: ${({ theme }) => theme.mainColor};
`;

//pageName的背景
export const MyPageName = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: ${fontSize.h2};
  background-color: ${({ theme }) => theme.pageBg};
  padding: 8px 0px;
`;

export const MyComponent = styled.div`
  background-color: ${({ theme }) => theme.componentsBg};
  padding: 15px;
  margin: 3px;
  border-radius: 15px;
  border: 0.5px solid #ffffff;
`;

export const MyComponentTwo = styled.div`
  box-shadow: 0px 0px 10px #bababa;
  background-color: #f9f9f9;
  padding: 5px;
`;

export const MyComponentThree = styled.div`
  background-color: ${({ theme }) => theme.pageBg};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  padding: 15px;
  border-radius: 15px;
`;

export const MyPageBG = styled.div`
  background-color: ${({ theme }) => theme.pageBg};
  padding: 14px;
  margin: 0 8px;
  border-radius: 5px;
  border: 0.5px solid #ffffff;
`;

export const MyPageTitle = styled.div`
  width: 250px;
  font-weight: 400;
  font-size: ${fontSize.h2};
  padding: 5px;
  background-color: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.retext};
  text-align: center;
`;

export const MySituFLightBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;
export const MyWsWlTagBox = styled.div`
  border: 0.1px solid #ffffff;
  background-color: ${({ theme }) => theme.mainColor};
  text-align: center;
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  font-size: ${fontSize.h4};
  padding: 6px;
`;

export const MyDisabled = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    background-color: ${(props) =>
      props.disabled
        ? ({ theme }) => theme.disabledColor
        : ({ theme }) => theme.mainColor};
    position: ${(props) => (props.disabled ? "absolute" : "static")};
    display: ${(props) => (props.disabled ? "block" : "none")};
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    cursor: no-drop;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    color: ${({ theme }) => theme.text};
    text-align: center;
    font-size: ${fontSize.h1};
    content: "";
  }
`;

//------------------ 2021-09-03 BK ------------------------

/*import styled from 'styled-components'

export const SysteamName = styled.p`
  font-size: 26px;
  font-weight: 400;
  margin: 0 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color:${({ theme }) => theme.systeamName};
`
export const HeaderTag = styled.div`
  font-size: 26px;
  font-weight: 400;
  white-space: nowrap;
  color:${({ theme }) => theme.text};
`
export const HeaderTime = styled.div`
  font-size: 26px;
  font-weight: 400;
  white-space: nowrap;
  color:${({ theme }) => theme.time};
`*/
