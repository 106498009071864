import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得盒鬚圖資料 ----------------------------------------
export const trend = (data) => {
  console.log(data)
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/trend`, {params: data});
};

//----------------------------------------- 取得盒鬚圖資料 ----------------------------------------
export const mpExport = (data) => {
  console.log(data)
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/mpExport`, {params: data});
};

//----------------------------------------- 取得製程分析資料 ----------------------------------------
// export const getNgItem = (data) => {
//   return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/analysis`, {params: data});
// };