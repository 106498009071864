import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

export const getAllRun90 = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllRun90`, {
    params: _params,
  });
};
export const getAllRun91 = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllRun91`, {
    params: _params,
  });
};
export const getAllRun92 = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllRun92`, {
    params: _params,
  });
};

//----------------------------------------- 取得全部產基本資料 ----------------------------------------
export const getAllProduct = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllProduct`);
};

//----------------------------------------- 取得產基本資料 ----------------------------------------
export const getProduct = (searchValue) => {
  return instance.get(
    `${apiBasic.conn}://${apiBasic.url}/api/getProduct?PN=${searchValue}`
  );
};

//----------------------------------------- 取得單一產品的全部途層 ----------------------------------------
export const getWpc = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpc?PN=${id}`);
};

//----------------------------------------- 新增產基本資料 ----------------------------------------
export const addProduct = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addProduct`,
    data
  );
};

//----------------------------------------- 取得所有工站資料 ----------------------------------------
export const getAllStation = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStation`);
};

//----------------------------------------- 修改產基本資料 ----------------------------------------
export const modifyProduct = (id, data) => {
  console.log("id = ", id);
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifyProduct?PN=${id}`,
    data
  );
};

//----------------------------------------- 凍結員工資料 ----------------------------------------
export const freezeStaff = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/freezeStaff`,
    data
  );
};

//----------------------------------------- 刪除產基本資料 ----------------------------------------
export const deleteProduct = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteProduct`,
    data
  );
};

//----------------------------------------- 產品基本資料 - 上傳檔案 ----------------------------------------
export const productUpload = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/productUpload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
