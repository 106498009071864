import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components'
import { Row, Col, Space, Input, message, Table, Modal, Select } from "antd";
import {
  MyPageName,
  fontSize,
  MySubtitleH4,
  MyMainColorH1,
} from "../../styled/publicTag";
import { getAllAbc, getAbcSelect, judge } from "../../services/api/AbcBoard";
import { formatFloat } from "../../utility/formatFloat";

import Title from '../../components/Custom/Title';

import moment from "moment";
import "moment/locale/zh-tw";
import "./index.css";

const CustomTable = styled(Table)`
  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 4px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.p};
  }
  
  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container, .ant-table {
    border-radius: 10px!important;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px!important;
  }
  
  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .ant-table-thead .ant-table-cell { 
    padding: 8px;
    font-size: ${fontSize.text};
    white-space: nowrap;
  }

  // 使用字級 + 4px
  .lineHeight {
    line-height: 25px;
  }
`

function AbcBoard() {
  const { Option } = Select;

  const Ref = useRef()

  const [abcBoardConfig, setAbcBoardConfig] = useState({
    page: 1,
    pageSize: 10,
    abnSN: "",
    woN: "",
    PN: "",
    light: "", //狀況
    rlsMsg: "", //異常信息
    rptType: "", //提報類別
    rptItem: "", //提報項目
    abnormDri: "", //責任單位
    wlID: "", //工線
  });

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
  });

  const [tableLoading, setTableLoading] = useState(false);

  const { TextArea } = Input;

  const [tableData, setTableData] = useState([]);

  const [judgeModal, setJudgeModal] = useState(false);

  const [s_options, set_s_Options] = useState({});  // 包含全部項目、全部單位、全部站別

  // 全部站別、線別資料
  const [s_wsIDs, set_s_wsIDs] = useState([]);

  // 線會根據站別的不同而有所改變，所以自己獨立一個 state
  const [s_wlID_options, set_s_wlID_options] = useState([]);

  const [judgeData, setJudgeData] = useState({
    abnSN: "",
    abnormDri: "",
    judgeMsg: "",
  });

	//如果每列都設寬度會出現表格跑版的情況 故留幾列不設寬度
	const tableColumns = [
		{
			title: "異常編號",
			key: "abnSN",
			dataIndex: "abnSN",
      align: 'center',
      width: 160
		},
		{
			title: "燈號",
			key: "light",
			dataIndex: "light",
      width: 80,
      align: 'center',
			render: (value, recode) => {
        //console.log('value = ', value, '  recode = ',recode)
				return showLightJudge(recode.light, recode);
			},
		},
		{
			title: "工站",
			key: "wsID",
			dataIndex: "wsID",
      width: 80,
      align: 'center',
		},
		{
			title: "站別",
			key: "wsNM",
			dataIndex: "wsNM",
      width: 80,
      align: 'center',
		},
		{
			title: "線別",
			key: "wlNM",
			dataIndex: "wlNM",
      width: 80,
      align: 'center',
		},
		{
			title: "工單號",
			key: "woN",
			dataIndex: "woN",
      align: 'center',
      width: 160,
		},
		{
			title: "料號",
			key: "PN",
			dataIndex: "PN",
      align: 'center',
      width: 120
		},
		{
			title: "提報時間",
			key: "rpt_time",
			dataIndex: "rpt_time",
      width: 160,
      align: 'center',
			render: (value) => {
        const date = moment(value).format("MM-DD HH:mm:ss").split(' ');
				return <span>{date[0]}<br />{date[1]}</span>;
			},
		},
		{
			title: "項目",
			key: "rptItemNM",
			dataIndex: "rptItemNM",
      align: 'center',
      width: 100
		},
		{
			title: "異常信息",
			key: "rpt_msg",
			dataIndex: "rpt_msg",
      align: 'center',
      width: 160
		},
		{
			title: "責任單位",
			key: "abnDriNM",
			dataIndex: "abnDriNM",
      align: 'center',
      width: 120
		},
		{
			title: "判責意見",
			key: "judge_msg",
			dataIndex: "judge_msg",
      align: 'center',
      width: 160,
			render: (value, recode) => {
				return writeJudgeMsg(value, recode);
			},
		},
		{
			title: "異常工時(秒)",
			key: "cumworkH",
			dataIndex: "cumworkH",
      align: 'center',
      width: 120
		},
		{
			title: "提報單位",
			key: "rptUnitNM",
			dataIndex: "rptUnitNM",
      align: 'center',
      width: 120
		},
		{
			title: "提報人員",
			key: "rpt_id",
			dataIndex: "rpt_id",
      align: 'center',
      width: 120
		},
	];
	
  //取得下拉選單 api
  useEffect(() => {
    const call_getAbcSelect = async () => {
      
      //console.log('------------------ getAllSelect start ------------------')
      const res = await getAbcSelect();
      console.log('res = ', res)
      //console.log('getAllSelect res = ', res)
      if(!res.status) return

      //console.log('res.data = ', res.data)

      //items => 全部項目, dri => 全部單位, wsIDs => 全部站別(包含線別)
      const {rptItem, abnormDri, wsIDs} = res.data;

      //全部項目
      let rptItem_options = rptItem.map(item => {
        return(
          <Option key={item.itemID} value={item.itemID}>{item.itemNM}</Option>
        )
      });
      rptItem_options.splice(0, 0, <Option key='index1' value=''>全部項目</Option>);

      //全部單位
      let abnormDri_options = abnormDri.map(item => {
        return(
          <Option key={item.itemID} value={item.itemID}>{item.dri}</Option>
        )
      });
      abnormDri_options.splice(0, 0, <Option key='index1' value=''>全部單位</Option>);

      //全部站別
      let wsID_options = wsIDs.map((item, index) => {
        return(
          <Option key={index} value={item.wsID.wsID}>{item.wsID.wsNM}</Option>
        )
      })
      wsID_options.splice(0, 0, <Option key='index1' value=''>全部站別</Option>);

      // 線會根據站別的不同而有所改變，所以自己獨立一個 state，因此這裡只把資料存起來不做成 option
      
      //全部站別、線別資料
      set_s_wsIDs(wsIDs);

      set_s_Options({rptItem_options, abnormDri_options, wsID_options});

      //console.log('------------------ getAllSelect end ------------------')
    }
    call_getAbcSelect()
  }, []);

  const AllwlIdOption = () => {
    let options = [];
    options.push(
      <Option key="D00" value="">
        全部線別
      </Option>
    );
    for (let i = 1; i < 16; i++) {
      options.push(
        <Option
          value={`D${i < 10 ? "0" + i : i}`}
          key={`D${i < 10 ? "0" + i : i}`}
        >{`D${i < 10 ? "0" + i : i}`}</Option>
      );
    }
    return options;
  };

  const call_judge = async () => {
    try {
      const res = await judge(judgeData);
      if (res.status) {
        message.success(res.msg);
        setJudgeModal(false);
        call_getAllAbc();
      } else {
        message.error(res.msg);
      }
    } catch (e) {
      console.log("資料獲取失敗", e);
      message.error("資料更新失敗");
    }
  };

  const call_getAllAbc = async () => {
    try {
      
      // API 尚未回來時鎖住 UI
      setTableLoading(true); 
      
      const res = await getAllAbc(abcBoardConfig);
      if (res.status) {
				//console.log('res.data = ', res.data)
        
        // 塞資料
        setPageInfo(res.data.pageInfo);
        setTableData(res.data.woNs);

        // UI 上告訴使用者資料狀態
        message.success(res.msg);

      } else {

        console.log("資料取得失敗, res = ", res)
        setTableData([]);
        message.error(res.msg);
      }
      setTableLoading(false);

    } catch (e) {

      setTableLoading(false);
      setTableData([]);

      console.log("資料獲取失敗", e);

      message.error("資料獲取失敗");
    }
  };

  function formatTime(second) {
    return [
      formatFloat(parseInt(second / 60 / 60), 0),
      formatFloat((second / 60) % 60, 0),
      formatFloat(second % 60, 0),
    ]
      .join(":")
      .replace(/\b(\d)\b/g, "0$1");
  }

  /*useEffect(() => {
    call_getAllAbc();
  }, [abcBoardConfig]);*/

  function changeJudgeDataAbnormDri(value) {
    setJudgeData({ ...judgeData, abnormDri: value });
  }

  function changeJudgeDataJudgeMsg(e) {
    setJudgeData({ ...judgeData, judgeMsg: e.target.value });
  }
  
  //header 部分的所有 onChange
  const handleChange = (value, flagName, eventType) => {

    let wlID = abcBoardConfig.wlID;
    if(flagName === 'wsID') {
      wlID = ''
    }

    const tmpAbcBoardConfig = {
      ...abcBoardConfig, 
      wlID: wlID,
      [flagName]: value,
      page: 1
    }
    setAbcBoardConfig(tmpAbcBoardConfig); 
  };

  // 文字輸入延遲.5s再call api
  useEffect(() => {
    console.log('----------- handleChange abcBoardConfig --------------');
    //console.log(abcBoardConfig)
    const timeoutID = setTimeout( () => { 
      call_getAllAbc();
    }, 500);
    return () => {
      clearTimeout(timeoutID)
    }
    //call api
  }, [abcBoardConfig]);

  // 選站別後要清空線別資料更新線別資料
  useEffect(() => {
    if(s_wsIDs.length === 0) return;  // 沒有資料

    // 工站資訊(包含工線)
    const wsObj = s_wsIDs.find(item => item.wsID.wsID === abcBoardConfig.wsID);
    
    // 有選站別
    if(wsObj && wsObj.wlIDs.length > 0) {
      const options = wsObj.wlIDs.map(item => <Option key={item.wlID} value={item.wlID}>{item.wlNM}</Option>);
      options.splice(0, 0, <Option key='index1' value=''>全部線別</Option>);
      set_s_wlID_options(options);
    } else {
      // 沒選站別
      const options = s_wsIDs.reduce((prev, curr) => {
        const tmpOptions = curr.wlIDs.map(item => <Option key={item.wlID} value={item.wlID}>{item.wlNM}</Option>);
        prev = prev.concat(tmpOptions);
        return prev;
      }, []);
      options.splice(0, 0, <Option key='index1' value=''>全部線別</Option>);

      set_s_wlID_options(options);
    }

  }, [abcBoardConfig.wsID, s_wsIDs]);

  const showLightJudge = (value, recode) => {
    //console.log('value = ', typeof value)
    // let color =
    //   value === "1"
    //     ? "red"
    //     : value == "2"
    //     ? "yellow"
    //     : value == 7
    //     ? "block"
    //     : "white";
    const colorMap = {
      2: 'red',
      3: "yellow",
      0: "rgb(54, 230, 28)",
      1: "rgb(58, 71, 78)"
    }
    let color = colorMap[value];
    const style = {
      width: '20px', 
      height: '20px', 
      backgroundColor: color,
      display: "inline-block",
      borderRadius: "50%",
      border: "0.1px solid #E0E0E0",
      verticalAlign: "middle",
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={style}/>
        {/* <span style={{ margin: "5px" }}>
          {recode.rls_msg === "" ? recode.rpt_item : recode.rls_msg}
        </span> */}
      </div>
    );
  };

  const writeJudgeMsg = (value, recode) => {
    return (
      <>
        {value == "" ? (
          <a
            onClick={() => {
              setJudgeModal(true);
              setJudgeData({ ...judgeData, abnSN: recode.abnSN });
              console.log(judgeData);
            }}
          >
            填寫意見
          </a>
        ) : (
          value
        )}
      </>
    );
  };

  return (
    <>
      <Title title='異常管理看板'>
        <div style={{ width: '100%', display: 'flex',  justifyContent: 'space-between' }}>
          <div style={{ width: '50%', display: 'flex' }}>
            <Input
              placeholder="查詢異常編號"
              value={setAbcBoardConfig.abnSN}
              style={{ marginLeft: '30px', marginRight: '15px', width: '200px'}} 
              size='large'
              onChange={(e) => handleChange(e.target.value, 'abnSN', 'textInput')}
            />
            <Input
              placeholder="查詢制令工單"
              value={setAbcBoardConfig.woN}
              style={{ marginRight: '15px', width: '200px'}} 
              size='large'
              onChange={(e) => handleChange(e.target.value, 'woN', 'textInput')}
            />
            <Input
              placeholder="查詢料號"
              value={setAbcBoardConfig.PN}
              style={{ width: '200px' }} 
              size='large'
              onChange={(e) => handleChange(e.target.value, 'PN', 'textInput')}
            />
          </div>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
            <Select 
              style={{ marginRight: '15px', width: '150px'}} 
              size='large'
              value={abcBoardConfig.light}
              onChange={value => handleChange(value, 'light', 'select')}
            >
              <Option value="">全部狀況</Option>
              <Option value="0">人工解除</Option>
              <Option value="1">系統解除</Option>
              <Option value="2">異常停工</Option>
              <Option value="3">異常提報</Option>
            </Select>
            {/* 全部項目 */}
            <Select 
              style={{ marginRight: '15px', width: '150px'}} 
              size='large' 
              defaultValue="" 
              onChange={ value => handleChange(value, 'rptItem', 'select') }
            >
              {s_options.rptItem_options}
            </Select>
            {/* 全部單位 */}
            <Select 
              defaultValue="" 
              onChange={ value => handleChange(value, 'abnormDri', 'select')}
              style={{ marginRight: '15px', width: '150px'}} 
              size='large'
             >
              {s_options.abnormDri_options}
            </Select>
            {/* 全部站別 */}
            <Select 
              defaultValue="" 
              style={{ marginRight: '15px', width: '150px'}} 
              size='large'
              onChange={ value => handleChange(value, 'wsID', 'select') }
            >
              {s_options.wsID_options}
            </Select>
            {/* 全部線別 */}
            <Select 
              style={{ width: '150px'}} 
              size='large'
              defaultValue="" 
              value={abcBoardConfig.wlID} 
              onChange={
                (value) => handleChange(value, 'wlID', 'select')
              }
            >
              {s_wlID_options}
            </Select>
          </div>
        </div>
      </Title>

      <CustomTable
        scroll={{ x: 'max-content',y: window.innerHeight - 60 - 45 - 220 }}
        bordered={true}
        columns={tableColumns}
        dataSource={tableData && tableData.length > 0 ? (
          tableData.map((item, index) => {
            return { ...item, key: index }
          })
        ) : []}
        loading={tableLoading}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: abcBoardConfig.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setAbcBoardConfig({
              ...abcBoardConfig,
              page: current,
              pageSize: size,
            });
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      <Modal
        title="判責意見"
        centered
        visible={judgeModal}
        destroyOnClose={true}
        onOk={() => {
          call_judge();
        }}
        onCancel={() => {
          setJudgeModal(false);
          setJudgeData({
            abnSN: "",
            abnormDri: "",
            judgeMsg: "",
          });
        }}
      >
        <MySubtitleH4>負責單位：</MySubtitleH4>
        <Select defaultValue="生產" onChange={changeJudgeDataAbnormDri}>
          <Option value="生產">生產</Option>
          <Option value="品管">品管</Option>
        </Select>
        <div>
          <MySubtitleH4>判責意見</MySubtitleH4>
          <TextArea onChange={changeJudgeDataJudgeMsg} />
        </div>
      </Modal>

    </>
  );
}
export default AbcBoard;