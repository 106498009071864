//當前收起狀態
const theme = localStorage.getItem("theme");

const themeReducer = (state = theme ? theme : "light", action) => {
  switch (action.type) {
    case "CHANGE_THEME": {
      return action.payload;
    }
    default:
      return state;
  }
};
export default themeReducer;
