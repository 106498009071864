import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Title from '../../components/Custom/Title';

import { getAllWs, getAllWl } from "../../services/api/publicAPI";

import StandardTable from './Tables/StandardTable/index';
import ItemTable from './Tables/ItemTable/index';

export const DataContext = React.createContext();

const SOP = () => {
  
  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料

  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData}}>
      <div style={{height: '100%'}}>
        <div style={{height: '50%'}}>
          <StandardTable />
        </div>
        <div style={{height: '50%'}}>
          {Object.keys(c_lightData).length > 0 ? <ItemTable /> : null}
        </div>
        
      </div>
    </DataContext.Provider>
  );
};

export default SOP;
