import styled from "styled-components";

import Logo from "../../assets/images/logo.png";

import { Space } from "antd";

import {Title} from "../Text/index" 

const FooterElement = ({className}) => {

  return (
    <div className={className}>
      <img src={Logo} alt="工智聯科技" className="footer-img" />
      <Space size={[16, 0]}>
        {/* <Title level="h3">V2.0.10</Title> */}
        {/* <img src={Lamp} alt='OK' style={lampStyle}/>
        <MyH3>IOT</MyH3>
        <img src={Lamp} alt='OK' style={lampStyle}/>
        <MyH3>ES</MyH3>
        <img src={Lamp} alt='OK' style={lampStyle}/>
        <MyH3>CS</MyH3> */}
      </Space>
    </div>
  );
};

const Footer = styled(FooterElement)`
  background-color: ${({ theme }) => theme.pageBg};
  border-top: 0.1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 50px;
  height: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  .footer-img{
    height: 90%;
  }
`

export default Footer;
