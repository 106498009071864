import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setuserStateReducer } from '../../redux/action';

import { Layout, message } from "antd";
import Routes from '../../routes/index';

import { ThemeProvider } from "styled-components";
import { themeLight, themeDark } from "../../styled/theme";

import Cookies from "universal-cookie";

import { getUserPerm } from '../../services/api/publicAPI';

import Header from "../../components/Header/Header";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer";

// import MaterialTheme from "./MaterialTheme"

const Home = () => {

  const dispatch = useDispatch();
  const r_isNavbar = useSelector((allReducers) => allReducers.navBar);
  const r_isNavbarInline = useSelector(
    (allReducers) => allReducers.navBarInline
  );
  const r_theme = useSelector((allReducers) => allReducers.theme);

  const cookies = new Cookies();

  const [user, setUser] = useState({
    userName: "",
    dept: "",
  });

  const nabar_temporary_margin_width = (isNavbar, isNavbarInline) => {
    //打開狀態
    if (!isNavbar) {
      return 220;
    } else {
      return isNavbarInline ? 0 : 80;
    }
  };

  const layoutStyle = {
    marginTop: "64px",
    marginLeft: nabar_temporary_margin_width(r_isNavbar, r_isNavbarInline),
    padding: "0px 25px",
    transition: ".3s",
    minHeight: "calc(100vh - 110px)",
  };

  const themeChange = () => {
    switch (r_theme) {
      case "light":
        return themeLight;
      case "dark":
        return themeDark;
      default:
        return themeLight;
    }
  };

  const call_initPage = async () => {
    try {
      const res = await getUserPerm();

      if (res.status) {
        // console.log('res.data = ', res.data)
        cookies.set("userName", res.data.user);
        dispatch(setuserStateReducer(res.data))
        setUser({ userName: res.data.peopleNM });
      } else {
        console.log("initPage error !!!")
        console.log(res)
        message.error(res.msg);
      }

      console.log(`
    
                             卍 Uncaught TypeError: Cannot read property 卍
                                                   
                                                 _0_
                                                _o0o_
                                               _oo0oo_                      
                                   佛祖保佑     o888888o      永無BUG       
                                              88" . "88                     
                                              (| -_- |)                     
                                              0\\  =  /0                     
                                            ___'/---\\'\___                   
                                          .' \\\\|     |// '.                 
                                         / \\\\\|||  :  |||// \\                
                                        / -||||| -:- |||||- \\               
                                       |   | \\\\\\  -  /// |   |              
                                       | \_|\_  ''\\---/''  |_/ |              
                                       \\  .-\\__  '-'  ___/-. /              
                                      ___'. .' /--.--\\  \'. .'___            
                                  ."" '<   \'.___\_<|>_/___.' >' "".          
                                 | | :  '- '::::__'__:::: - ' : | |         
                                 \  \ \'_.   \_ __\ \\/___\\/   .-' /  /           
                             =====\'-.____\`.___ \\_____/___.-\`___.-'=====     
                                              '==---=='   
                                              
                                          卍 網頁正常運行中 卍
                                              
    `)
    } catch (e) {
      console.log("call_initPage", e);
    }
  };

  useEffect(() => {
    call_initPage()
  }, [])

  return (
    <ThemeProvider theme={themeChange}>
      {/* <MaterialTheme> */}
        <Layout>
          <NavBar r_isNavbar={r_isNavbar} r_isNavbarInline={r_isNavbarInline} />
          <Header r_isNavBar={r_isNavbar} user={user} />
          <Layout style={layoutStyle}>
            <Routes />
          </Layout>
        </Layout>
        {/* <Footer /> */}
      {/* </MaterialTheme> */}
    </ThemeProvider>
  );
};

export default Home;
