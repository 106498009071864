import { useState, useEffect } from "react";
import CustomTable from "../../../components/antdCustom/CustomTable";
import useAPI from "../../../hooks/useApi";
import {
  getAllRun91,
  getAllRun92,
} from "../../../services/api/BasicProductInfo";
import { message, Space, Drawer, Typography } from "antd";
import Title from "../../../components/Custom/Title";

const { Link } = Typography;

const Detail = ({ s_editData }) => {
  const [s_tableData, set_s_tableData] = useState([]);
  const call_getAllRun92 = useAPI(getAllRun92);

  const tableColumns = [
    { title: "累積採樣數", dataIndex: "recno", width: "200px" },
    {
      title: "驗收型態",
      dataIndex: "insptype",
      width: "200px",
      align: "center",
    },
    {
      title: "檢驗項目",
      dataIndex: "sipitem",
      width: "150px",
      align: "center",
    },
    { title: "良品數", dataIndex: "okC", width: "150px" },
    { title: "不良品數", dataIndex: "ngC", width: "150px" },
    {
      title: "檢測環境",
      dataIndex: "sipcond",
      width: "200px",
      align: "center",
    },
    {
      title: "允收品質水準",
      dataIndex: "aql",
      width: "200px",
      align: "center",
    },
    {
      title: "檢驗時間",
      dataIndex: "checkTstr",
      width: "200px",
    },
  ];

  useEffect(() => {
    call_getAllRun92.request(s_editData);
  }, []);

  useEffect(() => {
    if (call_getAllRun92.status === "suc") {
      set_s_tableData(call_getAllRun92.data.map((x, i) => ({ ...x, key: i })));
      message.success(call_getAllRun92.msg);
    } else if (call_getAllRun92.status === "err") {
      message.error(call_getAllRun92.msg);
    }
  }, [call_getAllRun92.status]);

  return <CustomTable dataSource={s_tableData} columns={tableColumns} />;
};

export default function TB({ c_lightData }) {
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_showDrawer, set_s_showDrawer] = useState(false);
  const call_getAllRun91 = useAPI(getAllRun91);

  const tableColumns = [
    {
      title: "項次",
      dataIndex: "sipno",
      width: "80px",
      align: "center",
    },
    { title: "SIP文號", dataIndex: "sipID", width: "250px" },
    {
      title: "版本",
      dataIndex: "version",
      width: "100px",
      align: "center",
    },
    {
      title: "類別",
      dataIndex: "itemtype",
      width: "100px",
      align: "center",
    },
    {
      title: "驗收型態",
      dataIndex: "insptype",
      width: "100px",
      align: "center",
    },
    {
      title: "檢驗項目",
      dataIndex: "sipitem",
      width: "100px",
      align: "center",
    },
    {
      title: "管制上限",
      dataIndex: "UCL",
      width: "50px",
      align: "center",
    },
    {
      title: "管制下限",
      dataIndex: "LCL",
      width: "50px",
      align: "center",
    },
    {
      title: "檢驗要點(計數屬性)",
      key: "inspnote",
      dataIndex: "inspnote",
      width: "200px",
    },
    {
      title: "監控內容/圖號",
      dataIndex: "drawingno",
      width: "200px",
      align: "center",
    },
    {
      title: "單位",
      dataIndex: "unit",
      width: "100px",
      align: "center",
    },

    {
      title: "樣本大小",
      dataIndex: "samplesize",
      width: "100px",
      align: "center",
    },
    {
      title: "計量值之量測平均",
      dataIndex: "avgvalue",
      width: "200px",
    },
    {
      title: "計量值之標準差",
      dataIndex: "delta",
      width: "200px",
    },
    { title: "全距", dataIndex: "range", width: "100px" },
    {
      title: "完測時間",
      dataIndex: "closeTstr",
      width: "200px",
    },
    { title: "備註", dataIndex: "note", width: "200px" },
    {
      title: "紀錄",
      dataIndex: "action",
      width: "180px",
      align: "center",
      render: (_, rowData) => (
        <Link
          onClick={(e) => {
            set_s_editData(rowData);
            set_s_showDrawer(true);
            console.log(123);
            e.stopPropagation();
          }}
        >
          展開
        </Link>
      ),
    },
  ];

  useEffect(() => {
    call_getAllRun91.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getAllRun91.status === "suc") {
      set_s_tableData(call_getAllRun91.data.map((x, i) => ({ ...x, key: i })));
      message.success(call_getAllRun91.msg);
    } else if (call_getAllRun91.status === "err") {
      message.error(call_getAllRun91.msg);
    }
  }, [call_getAllRun91.status]);

  return (
    <>
      <Title title="品檢記錄查詢" placeholder="請輸入欲查詢的產品料號">
        <Space></Space>
      </Title>
      <CustomTable
        dataSource={s_tableData}
        columns={tableColumns}
        rowClassName={(record) =>
          record.key === s_editData?.key ? "clickRowStyle" : ""
        }
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key
              ? set_s_editData({})
              : set_s_editData(record),
        })}
      />
      <Drawer
        title="品檢記錄查詢"
        placement="right"
        width="50%"
        destroyOnClose
        onClose={() => set_s_showDrawer(false)}
        visible={s_showDrawer}
      >
        <Detail s_editData={s_editData} />
      </Drawer>
    </>
  );
}
