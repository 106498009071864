import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

const Header = ({handleCreate, handleClick, handleClose}) => {

  return (
    <div>
       <Button 
        onClick={handleClick} 
        type="primary" 
        style={{borderRadius: '5px'}} 
        icon={<FullscreenOutlined />} 
        size='large'
      >
        SIP檢驗項目明細編輯
      </Button>
      <Button
        type='danger'
        size='large'
        style={{marginLeft: '10px', marginRight: '10px', borderRadius: '5px'}} 
        icon={<FullscreenExitOutlined />} 
        onClick={handleClose} 
      >
        隱藏明細檔
      </Button>
      <Button 
        onClick={handleCreate} 
        type="primary" 
        style={{borderRadius: '5px'}} 
        icon={<PlusOutlined />} 
        size='large'
      >
        新增
      </Button>
    </div>
  )
};

export default Header;