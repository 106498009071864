import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得全部不良品資料 ----------------------------------------
export const getNgItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getNgItem`, {params: data});
};

//----------------------------------------- 新增不良品資料 ----------------------------------------
export const addNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addNgItem`, data);
};

//----------------------------------------- 修改不良品資料 ----------------------------------------
export const modifyNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyNgItem`, data);
};

//----------------------------------------- 刪除不良品資料 ----------------------------------------
export const deleteNgItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteNgItem`, data);
};