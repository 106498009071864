import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { DataContext } from '../../index';

import { getAllProduct, getAllWs, getAllWl } from '../../../../services/api/publicAPI';

import { addSophd, modifySophd, getAllSoptb } from '../../../../services/api/sopAPI'

import { Form, InputNumber, Button, Row, Col, Input, Select, message, Radio } from 'antd';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const StandardModalContainer = ({s_editData, set_s_editData, set_s_showModal}) => {

  const [form] = Form.useForm();

  const [s_PN, set_s_PN] = useState([]); // 料號

  const { c_lightData } = useContext(DataContext);

  const [s_ctrlspot, set_s_ctrlspot] = useState('') // 控制控點要顯示哪個下拉選單
  const [s_ws, set_s_ws] = useState([]); // 空點選 ws，空點編號的選單
  const [s_wl, set_s_wl] = useState([]); // 空點選 wl，空點編號的選單
  const [ s_oldTb, set_s_oldTb ] = useState([]);  // 點擊據此條目創建時放表參資料的 state

  // 第一次渲染取得料號、ws、wl 下拉選單
  useEffect(() => {
    const call_getAllProduct = async () => {
      try {
        const res = await getAllProduct();
        console.log('全部料號資料 = ', res)
        if(res.status === true) {
          set_s_PN(res.data)
        } else {
          set_s_PN([])
        }
      } catch (e) {
        set_s_PN([]);
        console.log('-------------------- call_getAllProduct error --------------------')
      }
    };
    call_getAllProduct();

    const call_getAllWs = async () => {
      try {
        const res = await getAllWs();
        console.log('全部工站資料 = ', res)
        if(res.status === true) {
          set_s_ws(res.data)
        } else {
          set_s_ws([])
        }
      } catch (e) {
        set_s_ws([]);
        console.log('-------------------- call_getAllWs error --------------------')
      }
    }
    call_getAllWs()

    const call_getAllWl = async () => {
      try {
        const res = await getAllWl();
        console.log('全部工線資料 = ', res)
        if(res.status === true) {
          set_s_wl(res.data)
        } else {
          set_s_wl([])
        }
      } catch (e) {
        set_s_wl([]);
        console.log('-------------------- call_getAllWl error --------------------')
      }
    }
    call_getAllWl()
  }, []);

  // 卸載時清除被編輯的資料
  useEffect(() => {
    
    return () => {
      set_s_editData({})
    }
  }, [])

  // 如果是編輯狀態要計算控點編號要顯示哪個下拉選單
  useEffect(() => {
    console.log('s_editData = ', s_editData)
    if(Object.keys(s_editData).length > 0) {
      set_s_ctrlspot(s_editData.ctrlspot);
    }
  }, [s_editData]);

  // 據此條目創建
  const createNew = () => {

    const template = {...s_editData};
    // 先清空被編輯的資料
    set_s_editData({});

    // 塞資料
    form.setFieldsValue({
      'PN': template.PN,
      'pdtNM': template.pdtNM,
      'isvalid': template.isvalid,
      'soptype': template.soptype,
      'ctrlspot': template.ctrlspot,
      'spotID': null,
      'flagC': null,
      'stepC': null,
      'feedC': null,
      'sopno': null,
      'note': null
    });

    call_getAllSoptb();
  }

  // 取得所有投料項目明細
  const call_getAllSoptb = async () => {
    try {
      //console.log(c_lightData)
      const res = await getAllSoptb({ sopID: c_lightData.sopID, version: c_lightData.version, spotID: c_lightData.spotID });
      console.log('res = ', res)
      if (res.data) {
        console.log('全部投料項目明細 = ', res.data);
        
        set_s_oldTb(res.data)
      }

    } catch (e) {
      console.log('---------- api getAllSoptb error ----------');
      console.log(e)
    }
  } 

  // 點擊確定送出時執行
  const onSubmit = async values => {

    const type = Object.keys(s_editData).length === 0 ? 'create' : 'modify';

    // 只在新增時使用
    const tmpValues = {
      hd: values,
      tb: s_oldTb
    };

    const modifyVal = {...values}

    

    // 建立
    if(type === 'create') { 

      console.log('要傳給後端的資料 = ', tmpValues)
      // return
      const res = await addSophd(tmpValues);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      
      set_s_showModal(false);
    }
    
    // 修改
    if(type === 'modify') { 
      const modifyValues = Object.assign(modifyVal, {sopID: s_editData.sopID, version: s_editData.version})

      console.log('準備送給後端建立的資料 = ', modifyValues);

      const res = await modifySophd(modifyValues);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      //console.log(res)
      set_s_showModal(false);
    }
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <Form.Provider

        onFormChange={(formName, info) => {
          const { changedFields } = info;
          console.log(changedFields)
          // 改變料號要自動帶入品名
          if(changedFields[0].name[0] === 'PN') {
            if(changedFields[0].value === '' || changedFields[0].value === undefined) {
              form.setFieldsValue({
                'pdtNM': ''
              });
            } else {
              form.setFieldsValue({
                'pdtNM': s_PN.length > 0 && s_PN.find(item => item.PN === changedFields[0].value).pdtNM,
              });
            }
          }
          
          // 計算總控程數
          if(changedFields[0].name[0] === 'flagC' || changedFields[0].name[0] === 'stepC' || changedFields[0].name[0] === 'feedC') {
            let flagC = form.getFieldValue("flagC");
            let stepC = form.getFieldValue("stepC");
            let feedC = form.getFieldValue("feedC");
            if(flagC === undefined || stepC === undefined || feedC === undefined) {
              form.setFieldsValue({
                'sopno': ''
              });
            };

            if(flagC && stepC && feedC) {
              form.setFieldsValue({
                'sopno': flagC + stepC + feedC
              });
            }
          }

          // 判斷控點編號要顯示 wl 還是 ws 的下拉選單
          if(changedFields[0].name[0] === 'ctrlspot') {

            // 如果清空控點要重新選擇控點編號
            // if(changedFields[0].value === undefined) {
              form.setFieldsValue({
                'spotID': undefined,
              });
            // }

            if(changedFields[0].value) {
              set_s_ctrlspot(changedFields[0].value)
            }
          }

        }}
        
      >
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            'PN': s_editData ? s_editData.PN : null,
            'pdtNM': s_editData ? s_editData.pdtNM : null,
            'isvalid': s_editData ? s_editData.isvalid : null,
            'soptype': s_editData ? s_editData.soptype : null,
            'ctrlspot': s_editData ? s_editData.ctrlspot : null,
            'spotID': s_editData ? s_editData.spotID : null,
            'flagC': s_editData ? s_editData.flagC : null,
            'stepC': s_editData ? s_editData.stepC : null,
            'feedC': s_editData ? s_editData.feedC : null,
            'sopno': s_editData ? s_editData.sopno : null,
            'note': s_editData ? s_editData.note : null,
          }}
        >
          <Row gutter={[24, 24]}>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>料號</label>}
                name="PN"
                rules={[{ required: true, message: '請選擇料號!' }]}
              >
                <Select 
                  placeholder="選擇料號" 
                  allowClear
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                >
                  {s_PN.length > 0 && s_PN.map((item, index) => {
                    return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>品名</label>}
                name="pdtNM"
              >
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>是否有效</label>}
                name="isvalid"
                rules={[{ required: true, message: '請選擇是否有效!' }]}
              >
                <Radio.Group name="isvalid">
                  <Radio value='1'>是</Radio>
                  <Radio value='0'>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[24, 24]}>

            <Col span={8}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>比對類型</label>}
                name="soptype"
                rules={[{ required: true, message: '請選擇比對類型!' }]}
              >
                <Select placeholder="選擇比對類型" allowClear>
                  <Select.Option value="A">操作次序確認</Select.Option>
                  {/* <Select.Option value="B">數值比對確認</Select.Option> */}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>控點</label>}
                name="ctrlspot"
                rules={[{ required: true, message: '請選擇控點!' }]}
              >
                <Select 
                  placeholder="選擇控點" 
                  allowClear
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                >
                  <Select.Option value="ws">工站比對</Select.Option>
                  <Select.Option value="wl">工線比對</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>控點編號</label>}
                name="spotID"
                rules={[{ required: true, message: '請選擇控點編號!' }]}
              >
                <Select 
                  placeholder="選擇控點編號" 
                  allowClear
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                >
                  
                  {s_ctrlspot === 'ws' ? (
                    s_ws.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>)
                  ) : null}

                  {s_ctrlspot === 'wl' ? (
                    s_wl.map(item => <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>)
                  ) : null}

                </Select>
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[24, 24]}>

            <Col span={8}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>總觸發數</label>}
                name="flagC"
                type="number"
                rules={[
                  { required: true, message: '請輸入總觸發數!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if(value < 0) {
                        return Promise.reject(new Error('請輸入大於0的數字!'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  })
                ]}
              >
                <InputNumber
                  name='flagC' 
                  style={{width: '100%'}} 
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>總工段數</label>}
                name="stepC"
                rules={[
                  { required: true, message: '請輸入總工段數!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if(value < 0) {
                        return Promise.reject(new Error('請輸入大於0的數字!'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  })
                ]}
                type="number"
              >
                <InputNumber 
                  name='stepC' 
                  style={{width: '100%'}} 
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>總投料數</label>}
                name="feedC"
                rules={[
                  { required: true, message: '請輸入總投料數!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if(value < 0) {
                        return Promise.reject(new Error('請輸入大於0的數字!'));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  })
                ]}
                type="number"
              >
                <InputNumber 
                  name='feedC' 
                  style={{width: '100%'}} 
                  disabled={Object.keys(s_editData).length > 0 ? true : false}
                />
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[24, 24]}>
            
            <Col span={8}>
              <Form.Item
                label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>總控程數</label>}
                name="sopno"
              >
                <Input disabled />
              </Form.Item> 
            </Col>

          </Row>

          <Row gutter={[24, 24]}>
            
            <Col span={24}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>備註</label>}
                name="note"
              >
                <Input.TextArea name='note' rows={3} />
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[24, 24]}>
            <Col span={18}>
              {Object.keys(s_editData).length > 0 ? (
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={createNew}>
                  據此條目創建
                </Button>
              ) : null}
            </Col>
            <Col span={6}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </CustomForm>
      </Form.Provider>
    </>
  )
};

export default StandardModalContainer;