import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import CustomTable from '../../../../components/antdCustom/CustomTable';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import { fontSize } from '../../../../styled/publicTag';
import { Form, Button, Row, Col, Input, message, Table, Select } from 'antd'
import { MenuOutlined } from "@ant-design/icons";
import { modifySoptb } from '../../../../services/api/sopAPI';
import { getAllSiptype } from '../../../../services/api/sipAPI';

import './index.css';

import { DataContext } from '../../index';

const innerWidth = window.innerWidth;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#CCC" }} />
));

const StyleTable = styled(Table)`
  
  .border {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }
  
  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  // 勾勾的顏色
  .successIcon {
    color: ${({ theme }) => theme.Color1};
  }

  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 8px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.text};
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-body {
    border-bottom: .5px solid rgb(217, 217, 217);
    border-left: .5px solid rgb(217, 217, 217);
    border-right: .5px solid rgb(217, 217, 217);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .checkIcon {
    text-align: center;
    font-size: 20px;
  }

  /* .checkIcon > * {
    color: ${({ theme }) => theme.Color3};
  } */

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    padding: 8px;
    font-size: ${innerWidth <= 1440 ? '12px' : '16px'};
    white-space: nowrap;
  }

  .center {
    text-align: center;
  }

  .Icon {
    cursor: pointer;
  }

  .hidden {
    display: none
  }

  .fileLink {
    color: ${({ theme }) => theme.Color3};
    cursor: pointer;
  }

  .row-min-width {
    min-width: 100px;
  }
  .clickRowStyle {
    background-color: rgba(253, 255, 203, .5);
  }

  .row-dragging {
    background: red;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  .ant-form-item {
    margin-bottom: 0px!important;
  }
`;



const Wrapper = styled.div`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    color: green
  }

  .row-dragging td {
    padding: 16px;
    color: pink
  }

  .row-dragging .drag-visible {
    visibility: visible;
    color: red
  }
`

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const ItemModalContainer = ({className, s_tableData, set_s_tableData, set_s_showModal}) => {

  const [ dataSource, set_dataSource ] = useState([]);
  const { c_lightData } = useContext(DataContext);

  const [ s_Siptype, set_s_Siptype ] = useState([]);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const tableColumns = c_lightData.soptype === 'A' ? (
    [
      {
        title: "排序",
        dataIndex: "sort",
        align: 'center',
        className: "drag-visible",
        bgColor: "#e7f7e7",
        width: innerWidth <= 1440 ? `${40}px` : `${60}px`,
        render: (text, rowData, index) => {
          // const bol = isEditing(rowData)
          const bol = editingKey !== ''
          return bol ? '' : <DragHandle />
        },
      },
      {
        title: "SOP控程",
        dataIndex: "sopno",
        key: "sopno",
        bgColor: "#e7f7e7",
        className: "drag-visible",
        align: "center",
        editable: true,
        width: innerWidth <= 1440 ? `${70}px` : `${90}px`
      },
      {
        title: "觸發序",
        dataIndex: "flagC",
        key: "flagC",
        align: "center",
        bgColor: "#e7f7e7",
        editable: true,
        width: innerWidth <= 1440 ? `${70}px` : `${70}px`,
      },
      {
        title: "段數序",
        dataIndex: "stepC",
        key: "stepC",
        align: "center",
        bgColor: "#e7f7e7",
        editable: true,
        width: innerWidth <= 1440 ? `${60}px` : `${70}px`
      },
      {
        title: "投料序",
        dataIndex: "feedC",
        key: "feedC",
        align: "center",
        bgColor: "#e7f7e7",
        editable: true,
        width: innerWidth <= 1440 ? `${60}px` : `${70}px`
      },
      {
        title: "核對項目",
        dataIndex: "sopitem",
        key: "sopitem",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${200}px` : `${250}px`
      },
      {
        // title: "投料",
        title: "投料",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck1",
        key: "setcheck1",
        align: "center",
        bgColor: "rgb(214, 222, 239)",
        editable: true,
        width: innerWidth <= 1440 ? `${150}px` : `${150}px`
      },
      {
        // title: "投量",
        title: "投量",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck2",
        key: "setcheck2",
        align: "center",
        editable: true,
        bgColor: "rgb(214, 222, 239)",
        width: innerWidth <= 1440 ? `${60}px` : `${120}px`
      },
      {
        // title: "投量",
        title: "投量上限",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck2UCL",
        key: "setcheck2UCL",
        align: "center",
        editable: true,
        bgColor: "rgb(214, 222, 239)",
        width: innerWidth <= 1440 ? `${70}px` : `${100}px`
      },
      {
        // title: "投量",
        title: "投量下限",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck2LCL",
        key: "setcheck2LCL",
        align: "center",
        editable: true,
        bgColor: "rgb(214, 222, 239)",
        width: innerWidth <= 1440 ? `${70}px` : `${100}px`
      },
      {
        // title: "時距",
        title: "時距(分鐘)",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck3",
        key: "setcheck3",
        align: "center",
        editable: true,
        bgColor: "rgb(226, 214, 239)",
        width: innerWidth <= 1440 ? `${70}px` : `${100}px`
      },
      {
        title: "時距上限",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck3UCL",
        key: "setcheck3UCL",
        align: "center",
        editable: true,
        bgColor: "rgb(226, 214, 239)",
        width: innerWidth <= 1440 ? `${70}px` : `${100}px`
      },
      {
        title: "時距下限",
        colSpan: c_lightData.soptype === 'A' ? 1 : 0,
        dataIndex: "setcheck3LCL",
        key: "setcheck3LCL",
        align: "center",
        editable: true,
        bgColor: "rgb(226, 214, 239)",
        width: innerWidth <= 1440 ? `${70}px` : `${100}px`
      },
      // {
      //   title: "公差",
      //   colSpan: c_lightData.soptype === 'A' ? 1 : 0,
      //   dataIndex: "setcheck2tol",
      //   key: "setcheck2tol",
      //   align: "center",
      //   width: 60,
      //   editable: true,
      //   // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      // },
      {
        title: '操作',
        dataIndex: 'operation',
        width: innerWidth <= 1440 ? `${40}px` : `${60}px`,
        align: 'center',
        render: (_, record) => {
          const isEdit = isEditing(record);
          return isEdit ? (
            <span>
              <Button
                onClick={() => save(record.key)}
                // style={{marginRight: 8,}}
              >
                暫存
              </Button>
            </span>
          ) : (
            <Button disabled={editingKey !== ''} onClick={() => edit(record)}>
              修改
            </Button>
          );
        },
      },
      
    ]
  ) : (
    [
      {
        title: "排序",
        dataIndex: "sort",
        width: 30,
        align: 'center',
        className: "drag-visible",
        render: (text, rowData, index) => {
          // const bol = isEditing(rowData)
          const bol = editingKey !== ''
          return bol ? '' : <DragHandle />
        },
      },
      {
        title: "SOP控程",
        dataIndex: "sopno",
        key: "sopno",
        align: "center",
        className: "drag-visible",
        editable: true,
        // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
      },
      {
        title: "觸發序",
        dataIndex: "flagC",
        key: "flagC",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
      },
      {
        title: "段數序",
        dataIndex: "stepC",
        key: "stepC",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "投料序",
        dataIndex: "feedC",
        key: "feedC",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "核對項目",
        dataIndex: "sopitem",
        key: "sopitem",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "控制標準值",
        colSpan: c_lightData.soptype === 'B' ? 1 : null,
        dataIndex: "value",
        key: "value",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "控制上限",
        colSpan: c_lightData.soptype === 'B' ? 1 : null,
        dataIndex: "UCL",
        key: "UCL",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "控制下限",
        colSpan: c_lightData.soptype === 'B' ? 1 : null,
        dataIndex: "LCL",
        key: "LCL",
        align: "center",
        editable: true,
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: 100,
        align: 'center',
        render: (_, record) => {
          const isEdit = isEditing(record);
          return isEdit ? (
            <span>
              <Button
                onClick={() => save(record.key)}
                // style={{marginRight: 8,}}
              >
                暫存
              </Button>
            </span>
          ) : (
            <Button disabled={editingKey !== ''} onClick={() => edit(record)}>
              修改
            </Button>
          );
        },
      },
      
    ]
  )

  const isEditing = (record) => {
    // console.log(editingKey.includes(record.key))
    return editingKey === record.key;
  };

  // 編輯 rowcord
  const edit = (record) => {
    console.log('record = ', record)
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  // 儲存編輯過後的 rowData 並更新tabledata
  const save = async (key) => {
    const row = await form.validateFields();
    form.setFieldsValue({});
    const new_dataSource = [...dataSource];
    const index = new_dataSource.findIndex((item) => key === item.key);
    const item = new_dataSource[index];
    new_dataSource.splice(index, 1, { ...item, ...row });
    set_dataSource(new_dataSource);
    setEditingKey('');

  }

  // 只能這樣寫，不然會出錯
  useEffect(() => {
    set_dataSource(s_tableData.map((item, index) => {
      return {
        key: index.toString(),
        index: index,
        ...item
      }
    }))
    console.log('s_tableData = ', s_tableData)
  }, []);

  // 取得核對項目的下拉選單
  useEffect(() => {
    const call_getAllSiptype = async () => {
      
      const res = await getAllSiptype();

      res.status === true ? set_s_Siptype(res.data) : set_s_Siptype([]);

      console.log('res.data = ', res.data)

    }

    call_getAllSiptype();

  }, []);

  // 拖曳結束時更新資料順序，並重新排序sopno
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // 更改陣列順序
    function arrayMoveMutable(array, fromIndex, toIndex) {
      const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
     
      if (startIndex >= 0 && startIndex < array.length) {
       const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
     
       const [item] = array.splice(fromIndex, 1);
       array.splice(endIndex, 0, item);
      }
    }
    // 更改陣列順序
    function arrayMoveImmutable(array, fromIndex, toIndex) {
      array = [...array];
      arrayMoveMutable(array, fromIndex, toIndex);
      return array;
    }

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      // console.log("Sorted items: ", newData);
      const newData1 = newData.map((item, index) => {

        return {
          ...item,
          key: index.toString(),
          index: index,
          sopno: index+1,
        }
      })
      console.log('newData1 = ', newData1)
      set_dataSource(newData1);
    }
  };

  // 可被 drag 的區域
  const DraggableContainer = (props) => {
    // console.log('props = ', props)
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  // 被拖曳的row
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return ({
          record,
          // inputType: col.dataIndex === 'age' ? 'number' : 'text',
          // dataIndex: col.dataIndex,
          title: col.title,
          dataKey: col.dataIndex,
          bgColor: col.bgColor,
          editing: isEditing(record).toString(),
        })
      },
    };
  });

  const getInput = (dataKey, record) => {

    if (dataKey !== 'sopno') {
      return (
        <Form.Item 
          name={dataKey}
        >
          <Input />
        </Form.Item>
      )
    } else {
      console.log('record = ', record)
      return record.sopno
    }
    
    // if(dataKey === 'sopitem') {
    //   return (
    //     <Select placeholder="選擇核對項目">
    //       {s_Siptype.length > 0 ? (
    //         s_Siptype.map((item, index) => <Select.Option key={index} value={item.itemID}>{item.tagNM}</Select.Option>)
    //       ) : null}
    //     </Select>
    //   )
    // } 
    // else if (dataKey !== 'sopno') {
    //   return (
    //     <Form.Item 
    //       name={dataKey}
    //     >
    //       <Input />
    //     </Form.Item>
    //   )
    // } else {
    //   console.log('record = ', record)
    //   return record.sopno
    // }
  }

  // 控制編輯時要顯示的東西
  const EditableCell = ({editing, dataIndex, title, inputType, record, index, dataKey, children, bgColor, ...restProps}) => {

    // 直接塞 bgColor 會吃不到 restprops，因此額外寫出來
    const newrestProps = {
      ...restProps,
      style: {
        ...restProps.style,
        backgroundColor: bgColor
      }
    }
    
    // console.log('???? = ', s_Siptype)

    // 如果遇到下拉選單要顯示對應的中文
    // const renderVal = dataKey === 'sopitem' ? s_Siptype.length > 0 && s_Siptype.find(item => item.itemID === children).tagNM : children

    return (
      <td {...newrestProps}>
        {editing === 'true' ? (
          <Form.Item 
            name={dataKey}
          >
            {getInput(dataKey, record)}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  // 確定送出
  const submit = async () => {
    
    const newData = dataSource.map((item, index) => {
      return {
        ...item,
        setcheck2: parseFloat(item.setcheck2),
        setcheck2UCL: parseFloat(item.setcheck2UCL),
        setcheck2LCL: parseFloat(item.setcheck2LCL),

        setcheck3: parseFloat(item.setcheck3),
        setcheck3UCL: parseFloat(item.setcheck3UCL),
        setcheck3LCL: parseFloat(item.setcheck3LCL),
        
        LCL: parseFloat(item.LCL),
        UCL: parseFloat(item.UCL),

        stepC: parseInt(item.stepC),
        flagC: parseInt(item.flagC),
        feedC: parseInt(item.feedC),
        value: parseInt(item.value),
        setcheck2tol: parseFloat(item.setcheck2tol),
      }
    })
    console.log('要給後端的資料 = ', newData)
    const res = await modifySoptb(newData);
    console.log('res = ', res)
    res.status ? message.success(res.msg) : message.error(res.msg);
    set_s_showModal(false)
  }

  return (
    <Wrapper className={className}>
      <Form form={form} component={false}>
        <Row>
          <Col span={24}>
            <StyleTable
              pagination={false}
              dataSource={dataSource}
              columns={mergedColumns}
              rowKey="index"
              rowClassName="editable-row"
              scroll={{ x: 'max-content', y: 400 }}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                  cell: EditableCell,
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                <Button disabled={editingKey !== ''} style={{borderRadius: '5px'}} size='large' type="primary" onClick={submit}>
                  確定修改
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      
    </Wrapper>
  )
};

export default ItemModalContainer;