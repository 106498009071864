import React, { useState, useEffect, useContext, useRef } from 'react';

import { message } from 'antd';

import { MySubtitleH4 } from '../../../../styled/publicTag';

import Title from '../../../../components/Custom/Title';
import CustomTable from '../../../../components/antdCustom/CustomTable';
import CustomModal from '../../../../components/antdCustom/CustomModal';

import Header from './Header';
import CreateSIPItem from './CreateSIPItem';

import{ getAllSiptb } from "../../../../services/api/sipAPI";
import { DataContext } from '../../index';
import ItemModalContainer from './ItemModalContainer';

message.config({
  duration: 1.5
});

const ItemTable = () => {

  const innerWidth = window.innerWidth;
  let countRef = useRef(null);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);            // 是否開啟 dialog
  const [s_showItemModal, set_s_showItemModal] = useState(false);    // 是否開啟 dialog

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const [scrollY, setScrollY] = useState("");

  const { c_lightData, set_c_lightData, c_drawingno } = useContext(DataContext);

  // 取得所有檢驗項目明細
  const call_getAllSiptb = async () => {
    try {
      //console.log(c_lightData)
      const res = await getAllSiptb({sipID: c_lightData.sipID, version: c_lightData.version, spotID: c_lightData.spotID});
      console.log('res = ', res)
      if(res.data) {
        console.log('全部檢驗項目明細 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      }
      
    } catch (e) {
      console.log('---------- api getAllSiptb error ----------');
      console.log(e)
    }
  }

  // 建立新的 sip 資料
  const handleCreate = () => {
    set_s_showModal(true)
  }

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  }

  // 計算 Table 的高度
  const getTableScroll = ({ extraHeight, ref }={}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 74
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let height = `calc(100vh - ${tHeaderBottom + extraHeight + 60}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    return height
  }

  // 更新 c_lightData 重新抓資料
  useEffect(()=>{
    if (Object.keys(c_lightData).length > 0){
      call_getAllSiptb()
      setScrollY(getTableScroll({ref: countRef}))
    }else{
      set_s_tableData([])
      setScrollY(getTableScroll({ref: countRef}))
    }
  },[c_lightData, s_showModal, s_showItemModal]);

  const handleClick = () => {
    set_s_showItemModal(true)
  }

  const tableColumns = [
    // {
    //   title: "sip編號",
    //   dataIndex: "sipID",
    //   key: "sipID",
    //   align: "center",
    //   // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    // },
    {
      title: "SIP項次",
      dataIndex: "sipno",
      key: "sipno",
      align: "center",
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
    },
    
    {
      title: "類別",
      dataIndex: "itemtype",
      key: "itemtype",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: "型態",
      dataIndex: "insptype",
      key: "insptype",
      align: "center",
      width: innerWidth <= 1440 ? `${70}px` : `${70}px`
    },
    {
      title: "檢驗項目",
      dataIndex: "sipitem",
      key: "sipitem",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: "檢驗要點",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "inspnote",
      key: "inspnote",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: "監控參數",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "drawingno",
      key: "drawingno",
      align: "center",
      render: (text, rowData, index) => {
        const item = c_drawingno.find((item) => item.itemID === rowData.drawingno);
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: item ? item.tagNM : rowData.drawingno
        };
      },
      // render: (text, rowData, index) => {
      //   const item = c_drawingno.find((item) => item.itemID === rowData.drawingno);
      //   return item ? item.tagNM : rowData.drawingno
      // },
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: "標準值",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "value",
      key: "value",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "規格上限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "USL",
      key: "USL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "規格下限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "LSL",
      key: "LSL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "管制上限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "UCL",
      key: "UCL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "管制下限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "LCL",
      key: "LCL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "AQL",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "AQL",
      key: "AQL",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: "單位",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`
    },
    {
      title: "檢測環境",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "sipcond",
      key: "sipcond",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    }
  ] 

  const title = `${Object.keys(c_lightData).length > 0 ? `檢驗項目明細檔__${c_lightData.sipID}` : ''}`;
  return (
    <div>
      
      <Title title={title} >
        <Header handleCreate={handleCreate} handleClick={handleClick} handleClose={handleClose} />
      </Title>
      
      <div ref={countRef}>
        <CustomTable 
          scroll={{x: 'max-content', y: scrollY }}
          columns={tableColumns}
          size={innerWidth <= 1440 ? '14px' : null} // 表頭字體大小
          dataSource={s_tableData.map((item, index) => {
            return {
              key: index,
              ...item
            }
          })}
          pagination={{
            defaultCurrent: 1,
            total: pageInfo.totalItems,
            // current: abcBoardConfig.page,
            showSizeChanger: true,
            pageSize: pageInfo.pageSize,
            pageSizeOptions: ["10", "20", "30"],
            onChange: (current, size) => {
              setPageInfo({
                ...pageInfo,
                pageSize: size
              })
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />
      </div>

      {s_showModal ? (
        <CustomModal 
          width='60%' 
          title={(
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>新增SIP檢驗項目</span>
              <div>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>SIP文件編號:  {c_lightData.sipID}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>版碼：{c_lightData.version}</span>
              </div>
            </div>
          )}
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          forceRender
          footer={null}
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          pagination={null}
        >
          <CreateSIPItem 
            s_tableData={s_tableData}
            set_s_tableData={set_s_tableData}
            set_s_showModal={set_s_showModal} 
          />
        </CustomModal>
      ) : null}

      {s_showItemModal ? (
        <CustomModal
          width='100%' 
          title={(
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>SIP全局檢驗項目編輯</span>
              <div>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>SIP文件編號:  {c_lightData.sipID}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>版碼：{c_lightData.version}</span>
              </div>
            </div>
          )}
          visible={s_showItemModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showItemModal(false)}
        >
          <ItemModalContainer s_tableData={s_tableData}
            set_s_tableData={set_s_tableData} set_s_showItemModal={set_s_showItemModal} />
        </CustomModal>
      ) : null}

    </div>
  )
};

export default ItemTable;