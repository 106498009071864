import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得所有工線資料 ----------------------------------------
export const getAllLine = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllLine`);
};

//----------------------------------------- 搜尋工線資料 ----------------------------------------
export const getLine = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getLine?wlID=${id}`);
};

//----------------------------------------- 新增工線資料 ----------------------------------------
export const addLine = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addLine`, data);
};

//----------------------------------------- 刪除工線資料 ----------------------------------------
export const deleteLine = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteLine`, data);
};

//----------------------------------------- 修改工線資料 ----------------------------------------
export const modifyLine = (id, data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyLine?wlID=${id}`, data);
};