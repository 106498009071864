import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//-----------------------------------------搜尋員工基本資料----------------------------------------
export const getStaff = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStaff?staffID=${id}`);
};

//-----------------------------------------新建員工基本資料----------------------------------------
export const postUser = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStaff`, data);
};

//-----------------------------------------修改員工基本資料----------------------------------------
export const modifyUser = (id, data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStaff?staffID=${id}`, data);
};

//-----------------------------------------刪除員工基本資料----------------------------------------
export const deleteUser = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStaff?staffID=${data}`);
};

//-----------------------------------------取得全部員工資料----------------------------------------
export const getAllStaff = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStaff`);
};

//----------------------------------------- 取得組織功能的 Options ----------------------------------------
export const getFunctions = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getFunctions`);
};

//----------------------------------------- 取得推播層級的 Options ----------------------------------------
export const getPushlvl = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPushlvl`);
};

//----------------------------------------- 凍結員工資料 ----------------------------------------
export const freezeStaff = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/freezeStaff`, data);
};