import { useEffect } from "react";
import useTheme from "../../hooks/useTheme";
import { IconMoon, IconSun } from "../Icon";
const ThemeButton = ({ color, className }) => {
  //主題
  // eslint-disable-next-line no-use-before-define
  const [theme, setTheme] = useTheme();

  useEffect(() => {
    switch (theme) {
      case "light":
        setTheme("light");
        break;
      case "dark":
        setTheme("dark");
        break;
      default:
        setTheme("light");
    }
    // eslint-disable-next-line
  }, [color]);

  const changeTheme = () => {
    if (theme === "light"){
      setTheme("dark");
    }else{
      setTheme("light")
    }
  }

  const themeIcon = () => {
    switch (theme) {
      case "light":
        return <IconSun className="icon" />;
      case "dark":
        return <IconMoon className="icon" />;
      default:
        return <IconSun className="icon" />;
    }
  };

  return (
    <div className={className} alt="換色" onClick={changeTheme}>
      {themeIcon()}
    </div>
  );
};
export default ThemeButton;
