import { useState, useEffect } from "react";
import Title from "../../../components/Custom/Title";
import CustomTable from "../../../components/antdCustom/CustomTable";
import checkUndefined from "../../../utility/checkUndefined";
import { message, Space, Grid, Input, Button } from "antd";
import useAPI from "../../../hooks/useApi";
import { getAllRun90 } from "../../../services/api/BasicProductInfo";
export default function HD({ c_lightData, set_c_lightData }) {
  const [scrollY, setScrollY] = useState(0);
  const screens = Grid.useBreakpoint();

  const [s_tableData, set_s_tableData] = useState([]);
  const call_getAllRun90 = useAPI(getAllRun90);
  const [s_searchVale, set_s_searchVale] = useState({
    woN: undefined,
  });

  const tableColumns = [
    {
      title: "工單號",
      dataIndex: "woN",
      width: "140px",
      align: "center",
    },
    {
      title: "檢驗單號",
      dataIndex: "inspN",
      width: "300px",
      render: (item) => checkUndefined(item),
    },
    {
      title: "SIP文編",
      dataIndex: "sipID",
      width: "250px",
      render: (item) => checkUndefined(item),
    },
    {
      title: "版碼",
      dataIndex: "version",
      width: "100px",
      align: "center",
      render: (item) => checkUndefined(item),
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      width: "100px",
      align: "center",
      render: (item) => (item === "wl" ? "工線比對" : "工站比對"),
    },

    {
      title: "控點編號",
      dataIndex: "spotID",
      width: "100px",
      align: "center",
    },
    {
      title: "創建時間",
      dataIndex: "createTstr",
      width: "100px",
      align: "center",
      render: (item) => {
        const value = item.split(" ");
        return item ? (
          <span>
            {value[0]}
            <br />
            {value[1]}
          </span>
        ) : (
          checkUndefined(item)
        );
      },
    },
    {
      title: "抽樣人員",
      //key: "sampleID",
      dataIndex: "sampleID",
      width: "100px",
      align: "center",
    },
    {
      title: "採樣時間",
      dataIndex: "sampleTstr",
      width: "100px",
      align: "center",
      render: (item) => {
        const value = item.split(" ");
        return item ? (
          <span>
            {value[0]}
            <br />
            {value[1]}
          </span>
        ) : (
          checkUndefined(item)
        );
      },
    },
    {
      title: "完測時間",
      //key: "closeTstr",
      dataIndex: "closeTstr",
      width: "100px",
      align: "center",
      render: (item) => {
        const value = item.split(" ");
        return item ? (
          <span>
            {value[0]}
            <br />
            {value[1]}
          </span>
        ) : (
          checkUndefined(item)
        );
      },
    },
    {
      title: "檢測時間",
      //key: "timeDiff",
      dataIndex: "timeDiff",
      width: "200px",
      align: "center",
      render: (item) => `${item[0]}天${item[1]}時${item[2]}分${item[3]}秒`,
    },
  ];

  const fiterData = () =>
    set_s_tableData(
      call_getAllRun90.data
        .filter((x) => {
          if (!s_searchVale.woN || s_searchVale.woN === "") return true;
          console.log(x.woN.indexOf(s_searchVale.woN));
          return x.woN.indexOf(s_searchVale.woN) > 0;
        })
        .map((x) => ({ ...x, key: x.inspN }))
    );

  useEffect(() => {
    call_getAllRun90.request(s_searchVale);
  }, []);

  useEffect(() => {
    const rowHeight = 16 * 1.5 + 13;
    // const isTbMode = Object.keys(tbMode).length > 0;
    // 一般螢幕尺寸
    if (screens.xxl) {
      // 表身資料
      !Object.keys(c_lightData).length > 0
        ? setScrollY(rowHeight * 14)
        : setScrollY(rowHeight * 6);
    } else if (screens.xl) {
      // macbook 尺寸
      !Object.keys(c_lightData).length > 0
        ? setScrollY(rowHeight * 12)
        : setScrollY(rowHeight * 6);
    } else {
      setScrollY(rowHeight * 14);
    }
  }, [s_tableData, c_lightData]);

  useEffect(() => {
    if (call_getAllRun90.status === "suc") {
      set_s_tableData(
        call_getAllRun90.data.map((x) => ({ ...x, key: x.inspN }))
      );
      message.success(call_getAllRun90.msg);
    } else if (call_getAllRun90.status === "err") {
      message.error(call_getAllRun90.msg);
    }
  }, [call_getAllRun90.status]);

  useEffect(() => {
    console.log(scrollY);
  }, [scrollY]);

  return (
    <>
      <Title title="品檢記錄查詢" placeholder="請輸入欲查詢的產品料號">
        <Space>
          <Input
            size="large"
            placeholder="請輸入工單號"
            value={s_searchVale.woN}
            onChange={(e) =>
              set_s_searchVale((prev) => ({ ...prev, woN: e.target.value }))
            }
          />
          <Button
            size="large"
            type="primary"
            onClick={() => {
              set_c_lightData({});
              fiterData();
            }}
          >
            查詢
          </Button>
        </Space>
      </Title>
      <CustomTable
        dataSource={s_tableData}
        columns={tableColumns}
        loading={call_getAllRun90.status === "load"}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key
              ? set_c_lightData({})
              : set_c_lightData(record),
        })}
        rowClassName={(record) =>
          record.key === c_lightData?.key ? "clickRowStyle" : ""
        }
        scroll={{ y: scrollY }}
      />
    </>
  );
}
