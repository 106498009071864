import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import ChartContainer from './ChartContainer';

import { Row } from 'antd';

import { DataContext } from '../../index';

const Wrapper = styled.div`
width: 100%;
  height: 100%;
  padding: 10px;
  /* background-color: red; */
  width: 75%;
  margin: 0px 5px 5px 0px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 42%);
  display: flex;
  flex-wrap: wrap;
  /* align-items: flex-end; */
  align-content: flex-start;
  overflow: auto;
  /* justify-content: flex-start; */
`

const LeftContainer = () => {

  // const [testArray, settestArray ] = useState([1]);

  // 要顯示的趨勢指標
  const { c_param } = useContext(DataContext);

  useEffect(() => {
    console.log('c_param.show = ', c_param.show)
  }, [c_param]);

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        {c_param.show.length > 0 && c_param.show.map((item, index) => {
          return <ChartContainer key={index} paramName={item} />
        })}
      </Row>

    </Wrapper>
  )
};

export default LeftContainer;