import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { DataContext } from '../index';

import { Button, DatePicker, Select, Input, Tooltip } from 'antd';
import { FileSyncOutlined } from '@ant-design/icons';

import Workbook from 'react-excel-workbook';

import moment from 'moment';

import { getAllWl } from '../../../services/api/publicAPI';

import { IconExcel } from '../../../components/Icon/index';

import CustomModal from '../../../components/antdCustom/CustomModal';
import ModalContainer from './ModalContainer';

import { mpExport } from '../../../services/api/trend';

const Wrapper = styled.div`
  display: flex;
  
  // 圓角設定
  .ant-btn {
    border-radius: 5px!important;
  }

  .mr_15px {
    margin-right: 15px;
  }

  .w_100px {
    width: 100px
  }

  .w_150px {
    width: 150px
  }
`

const TitleContainer = ({ c_searchData, set_c_searchData }) => {

  const [ s_wl, set_s_wl ] = useState([]);                                   // 全部線別資料
  const [ is_openParamsModal, set_is_openParamsModal ] = useState(false);    // 是否開啟參數設定的 Modal

  const [ s_Excel, set_s_Excel ] = useState([]);

  const {Col_num, set_Col_num} = useContext(DataContext);

  const ExcelColumns = [
    {
      title: '時間',
      key: 'timestamp'
    },
    {
      title: '氣壓',
      key: 'airtube'
    },
    {
      title: '冰水',
      key: 'cwpipe'
    },
    {
      title: '冷卻水',
      key: 'wpipe'
    },
    {
      title: '氮氣',
      key: 'n2tube'
    },
    {
      title: '氧氣',
      key: 'o2tube'
    },
    {
      title: '目前存量_溶劑A',
      key: 'wa'
    },
    {
      title: '預設扣重_溶劑A',
      key: 'wa0'
    },
    {
      title: '最大載重_溶劑A',
      key: 'wamax'
    },
    {
      title: '目前存量_溶劑B',
      key: 'wb'
    },
    {
      title: '預設扣重_溶劑B',
      key: 'wb0'
    },
    {
      title: '最大載重_溶劑B',
      key: 'wbmax'
    },
    {
      title: '收集槽重量',
      key: 'catchW'
    },
    {
      title: '氮氣流量_槽1',
      key: 'flowN_1'
    },
    {
      title: '氧氣流量_槽1',
      key: 'flowO_1'
    },
    {
      title: '電阻_槽1',
      key: 'current_1'
    },
    {
      title: '轉速_槽1',
      key: 'frequency_1'
    },
    {
      title: '段數_槽1',
      key: 'step_1'
    },
    {
      title: '扭力_槽1',
      key: 'torque_1'
    },
    {
      title: '重量_槽1',
      key: 'weight_1'
    },
    {
      title: '計時器_槽1',
      key: 'timer_1'
    },
    {
      title: '槽內溫度_槽1',
      key: 'inTemp_1'
    },
    {
      title: '煤油溫度(槽外溫度)_槽1',
      key: 'outTemp_1'
    },
    {
      title: '設定溫度_槽1',
      key: 'setTemp_1'
    },
    {
      title: '閥_1_槽1',
      key: 'wgTran_1_1'
    },
    {
      title: '閥_2_槽1',
      key: 'wgTran_2_1'
    },
    {
      title: '閥_3_槽1',
      key: 'wgTran_3_1'
    },
    {
      title: '控程_槽1',
      key: 'sopcount_1'
    },
    {
      title: 'SOP項次_槽1',
      key: 'sopno_1'
    },
    {
      title: '滿料指示_槽1',
      key: 'levelon_1'
    },
    {
      title: '狀態燈_槽1',
      key: 'lighton_1'
    },
    {
      title: '熱煤設定_槽1',
      key: 'setkeroTemp_1'
    },
    {
      title: '熱煤溫度_槽1',
      key: 'keroTemp_1'
    },
    {
      title: '氮氣流量_槽2A',
      key: 'flowN_2A'
    },
    {
      title: '氧氣流量_槽2A',
      key: 'flowO_2A'
    },
    {
      title: '電流_槽2A',
      key: 'current_2A'
    },
    {
      title: '轉速_槽2A',
      key: 'frequency_2A'
    },
    {
      title: '段數_槽2A',
      key: 'step_2A'
    },
    {
      title: '扭力_槽2A',
      key: 'torque_2A'
    },
    {
      title: '重量_槽2A',
      key: 'weight_2A'
    },
    {
      title: '計時器_槽2A',
      key: 'timer_2A'
    },
    {
      title: '槽內溫度_槽2A',
      key: 'inTemp_2A'
    },
    {
      title: '煤油溫度(槽外溫度)_槽2A',
      key: 'outTemp_2A'
    },
    {
      title: '設定溫度_槽2A',
      key: 'setTemp_2A'
    },
    {
      title: '閥_1_槽2A',
      key: 'wgTran_1_2A'
    },
    {
      title: '閥_2_槽2A',
      key: 'wgTran_2_2A'
    },
    {
      title: '閥_3_槽2A',
      key: 'wgTran_3_2A'
    },
    {
      title: '控程_槽2A',
      key: 'sopcount_2A'
    },
    {
      title: 'SOP項次_槽2A',
      key: 'sopno_2A'
    },
    {
      title: '滿料指示_槽2A',
      key: 'levelon_2A'
    },
    {
      title: '狀態燈_槽2A',
      key: 'lighton_2A'
    },
    {
      title: '熱煤設定_槽2A',
      key: 'setkeroTemp_2A'
    },
    {
      title: '熱煤溫度_槽2A',
      key: 'keroTemp_2A'
    },
    {
      title: '氮氣流量_槽2A',
      key: 'flowN_2A'
    },
    {
      title: '氧氣流量_槽2A',
      key: 'flowO_2A'
    },
    {
      title: '電流_槽2A',
      key: 'current_2A'
    },
    {
      title: '轉速_槽2A',
      key: 'frequency_2A'
    },
    {
      title: '段數_槽2A',
      key: 'step_2A'
    },
    {
      title: '扭力_槽2A',
      key: 'torque_2A'
    },
    {
      title: '重量_槽2A',
      key: 'weight_2A'
    },
    {
      title: '計時器_槽2A',
      key: 'timer_2A'
    },
    {
      title: '槽內溫度_槽2A',
      key: 'inTemp_2A'
    },
    {
      title: '煤油溫度(槽外溫度)_槽2A',
      key: 'outTemp_2A'
    },
    {
      title: '設定溫度_槽2A',
      key: 'setTemp_2A'
    },
    {
      title: '閥_1_槽2A',
      key: 'wgTran_1_2A'
    },
    {
      title: '閥_2_槽2A',
      key: 'wgTran_2_2A'
    },
    {
      title: '閥_3_槽2A',
      key: 'wgTran_3_2A'
    },
    {
      title: '控程_槽2A',
      key: 'sopcount_2A'
    },
    {
      title: 'SOP項次_槽2A',
      key: 'sopno_2A'
    },
    {
      title: '滿料指示_槽2A',
      key: 'levelon_2A'
    },
    {
      title: '狀態燈_槽2A',
      key: 'lighton_2A'
    },
    {
      title: '熱煤設定_槽2A',
      key: 'setkeroTemp_2A'
    },
    {
      title: '熱煤溫度_槽2A',
      key: 'keroTemp_2A'
    },
    {
      title: '氮氣流量_槽2B',
      key: 'flowN_2B'
    },
    {
      title: '氧氣流量_槽2B',
      key: 'flowO_2B'
    },
    {
      title: '電流_槽2B',
      key: 'current_2B'
    },
    {
      title: '轉速_槽2B',
      key: 'frequency_2B'
    },
    {
      title: '段數_槽2B',
      key: 'step_2B'
    },
    {
      title: '扭力_槽2B',
      key: 'torque_2B'
    },
    {
      title: '重量_槽2B',
      key: 'weight_2B'
    },
    {
      title: '計時器_槽2B',
      key: 'timer_2B'
    },
    {
      title: '槽內溫度_槽2B',
      key: 'inTemp_2B'
    },
    {
      title: '煤油溫度(槽外溫度)_槽2B',
      key: 'outTemp_2B'
    },
    {
      title: '設定溫度_槽2B',
      key: 'setTemp_2B'
    },
    {
      title: '閥_1_槽2B',
      key: 'wgTran_1_2B'
    },
    {
      title: '閥_2_槽2B',
      key: 'wgTran_2_2B'
    },
    {
      title: '閥_3_槽2B',
      key: 'wgTran_3_2B'
    },
    {
      title: '控程_槽2B',
      key: 'sopcount_2B'
    },
    {
      title: 'SOP項次_槽2B',
      key: 'sopno_2B'
    },
    {
      title: '滿料指示_槽2B',
      key: 'levelon_2B'
    },
    {
      title: '狀態燈_槽2B',
      key: 'lighton_2B'
    },
    {
      title: '熱煤設定_槽2B',
      key: 'setkeroTemp_2B'
    },
    {
      title: '熱煤溫度_槽2B',
      key: 'keroTemp_2B'
    },
    {
      title: '電流_槽3',
      key: 'current_3'
    },
    {
      title: '轉速_槽3',
      key: 'frequency_3'
    },
    {
      title: '段數_槽3',
      key: 'step_3'
    },
    {
      title: '扭力_槽3',
      key: 'torque_3'
    },
    {
      title: '重量_槽3',
      key: 'weight_3'
    },
    {
      title: '計時器_槽3',
      key: 'timer_3'
    },
    {
      title: '槽內溫度_槽3',
      key: 'inTemp_3'
    },
    {
      title: '設定溫度_槽3',
      key: 'setTemp_3'
    },
    {
      title: '閥_1_槽3',
      key: 'wgTran_1_3'
    }
  ];

  // 更改搜尋項目
  const handleChange = (type, value) => {
    
    let tmp = {}

    console.log('type = ', type)
    console.log('value = ', value)

    if(type === 'time') {
      console.log('value = ', value)
      tmp = { ...c_searchData, 'start': value[0], 'end': value[1] };
    } else {
      tmp = { ...c_searchData, [type]: value };
    }
    console.log('tmp = ', tmp)
    set_c_searchData(tmp);
  }

  // 開啟設定參數的 Modal
  const setParams = () => {
    set_is_openParamsModal(true);
  }

  // 第一次渲染取得所有線別資料, 並預設 searchData 的 wl 為 api 回來的第一個值
  useEffect(() => {

    const call_getAllWl = async () => {
      try {
        const res = await getAllWl();
        console.log('全部線別資料 = ', res)
        if(res.status === true) {
          set_s_wl(res.data);
          console.log('res.data[0].wlID = ', res.data[0].wlID)
          handleChange('wlID', res.data[0].wlID)
        } else {
          set_s_wl([])
        }
      } catch (e) {
        set_s_wl([]);
        console.log('-------------------- call_getAllWl error --------------------')
      }
    }

    call_getAllWl();

  }, []);

  useEffect(() => {

    const call_Excel = async () => {

      try {
        const res = await mpExport({
          start: c_searchData.start,
          end: c_searchData.end
        });

        if(res.status === false) return;

        const tmpData = res.data.map(item => {

          const tmpObject = Object.entries(item).reduce((prev, [key, value]) => {
            prev[key] = value.toString();
            return prev;
          }, {});

          return tmpObject;
        })

        console.log('tmpData = ', tmpData)

        res.status ? set_s_Excel(tmpData) : set_s_Excel([])
      } catch (e) {
        console.log(e)
        console.log('================== call_Excel error ==================')
      }
    }

    call_Excel();

  }, [c_searchData]);

  const changeColNum = () => {
    
    const tmpNum = Col_num === 12 ? 24 : 12
    set_Col_num(tmpNum)
  }

  return (
    <Wrapper>
       <Tooltip placement="bottom" title='切換顯示列數'>
        <FileSyncOutlined 
          onClick={changeColNum}
          style={{
            display: 'flex', 
            alignItems: 'center',
            fontSize: '36px',
            cursor: 'pointer',
            marginRight: '10px'
          }} 
        />
       </Tooltip>
      {/* 參數設定-按鈕 */}
      <Button 
        ghost
        type="primary" 
        size='large' 
        className='mr_15px'
        onClick={setParams}
      >
        顯示設定
      </Button>

      <Input
        style={{width: '200px', marginRight: '15px'}}
        size='large' 
        placeholder='請輸入批號' 
        value={c_searchData.pdtlot}
        onChange={e => handleChange('pdtlot', e.target.value)} 
      />

      {/* 日期起、迄選擇 */}
      <DatePicker.RangePicker 
        size='large'
        className='mr_15px'
        allowClear={false}
        value={[moment(c_searchData.start), moment(c_searchData.end)]}
        onChange={(dates, dateStrings) => {
          console.log('dateStrings = ', dateStrings)
          handleChange('time', dateStrings)
        }}
      />

      

      {/* 頻率選項 */}
      <Select 
        size='large'
        className='mr_15px w_150px'
        value={c_searchData.freq}
        onChange={value => handleChange('freq', value)}
      >
        <Select.Option value={5}>5分鐘每組</Select.Option>
        <Select.Option value={10}>10分鐘每組</Select.Option>
        <Select.Option value={15}>15分鐘每組</Select.Option>
        <Select.Option value={20}>20分鐘每組</Select.Option>
        <Select.Option value={30}>30分鐘每組</Select.Option>
        <Select.Option value={60}>60分鐘每組</Select.Option>
        <Select.Option value={720}>720分鐘每組</Select.Option>
        <Select.Option value={1440}>1440分鐘每組</Select.Option>
      </Select>

      {/* 線別選項 */}
      <Select 
        size='large'
        className='w_150px'
        value={c_searchData.wlID}
        onChange={value => handleChange('wlID', value)}
      >
        {s_wl.length > 0 ? (
          s_wl.map(item => <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>)
        ) : null}
      </Select>

      {/* <IconExcel 
        style={{width: '30px', height: '38px', marginLeft: '10px'}} 

      /> */}
      
      <span style={{height: '38px', cursor: 'pointer'}}>
        <Workbook 
          filename={`製程趨勢分析_${c_searchData.start}-${c_searchData.end}.xlsx`} 
          element={
            // <Button 
            //   size='large' 
            //   icon={
                
            //   } 
            //   style={{ marginRight: '10px', borderRadius: '5px', color: 'green', border: '1px solid green'}}
            // >
            //   匯出Excel
            // </Button>
            <IconExcel 
              style={{width: '30px', height: '38px', marginLeft: '10px', marginRight: '5px'}} 
            />
          }>
            <Workbook.Sheet data={s_Excel} name="Sheet A">
              {ExcelColumns.map((item, index) => {
                return(
                  <Workbook.Column key={index} label={item.title} value={item.key} />
                )
              })}
            </Workbook.Sheet>
          </Workbook>
        </span>

      {is_openParamsModal ? (
        <CustomModal
          width='50%' 
          title="顯示設定"
          visible={is_openParamsModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_openParamsModal(false)}
        >
          <ModalContainer set_is_openParamsModal={set_is_openParamsModal} />
        </CustomModal>
      ) : null}

    </Wrapper>
  )
};

export default TitleContainer;