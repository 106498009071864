/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import useAPI from '../../../../hooks/useApi';
import { Form, Button, Row, Col, Input, Select, message, Radio } from 'antd';

import { getAllProduct, getAllWs, getAllWl } from '../../../../services/api/publicAPI';
import { addSiphd, modifySiphd, getAllSiptb } from '../../../../services/api/sipAPI';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

  .Ariean .ant-form-item-label {
    padding-bottom: 0px;
  }

  .Ariean .ant-form-item {
    margin-bottom: 16px;
  }
`

const ModalContainer = ({ s_editData, set_s_editData, set_is_showModal, call_getAllSiphd }) => {

  const call_getAllProduct = useAPI(getAllProduct);
  const call_getAllSiptb = useAPI(getAllSiptb);
  const call_addSiphd = useAPI(addSiphd);
  const call_modifySiphd = useAPI(modifySiphd);
  const call_getAllWs = useAPI(getAllWs);
  const call_getAllWl = useAPI(getAllWl);

  const [form] = Form.useForm();
  const [ s_oldTb, set_s_oldTb ] = useState([]);  // 點擊據此條目創建時放表參資料的 state

  const [s_ctrlspot, set_s_ctrlspot] = useState('') // 控制控點要顯示哪個下拉選單

  const [s_PN, set_s_PN] = useState([]); // 料號
  const [s_ws, set_s_ws] = useState([]); // 控點選 ws，控點編號的選單
  const [s_wl, set_s_wl] = useState([]); // 控點選 wl，控點編號的選單

  // 第一次渲染取得API資料
  useEffect(() => {
    call_getAllProduct.request();
    call_getAllWs.request();
    call_getAllWl.request();
  }, []);

  // 料號API
  useEffect(() => {
    if(call_getAllProduct.status === 'suc') {
      set_s_PN(call_getAllProduct.data);
    }
    else if (call_getAllProduct.status === 'err') {
      message.error(call_getAllProduct.msg);
    }
  }, [call_getAllProduct.status]);

  // 工站API
  useEffect(() => {
    if(call_getAllWs.status === 'suc') {
      set_s_ws(call_getAllWs.data);
    }
    else if (call_getAllWs.status === 'err') {
      message.error(call_getAllWs.msg);
    }
  }, [call_getAllWs.status]);

  // 工線API
  useEffect(() => {
    if(call_getAllWl.status === 'suc') {
      set_s_wl(call_getAllWl.data);
    }
    else if (call_getAllWl.status === 'err') {
      message.error(call_getAllWl.msg);
    }
  }, [call_getAllWl.status]);

  //『據此條目創建』時取得所有檢驗項目(sip)明細
  useEffect(() => {
    if(call_getAllSiptb.status === 'suc') {
      set_s_oldTb(call_getAllSiptb.data);
    }
    else if(call_getAllSiptb.status === 'err') {
      message.error(call_getAllSiptb.msg);
    }
  }, [call_getAllSiptb.status]);

  // call 新建的API
  useEffect(() => {
    if(call_addSiphd.status === 'suc') {
      message.success(call_addSiphd.msg);
      set_is_showModal(false);
      call_getAllSiphd.request();
    };
    if(call_addSiphd.status === 'err') {
      message.error(call_addSiphd.msg);
      set_is_showModal(false);
    };
  }, [call_addSiphd.status]);

  // call 修改的API
  useEffect(() => {
    if(call_modifySiphd.status === 'suc') {
      message.success(call_modifySiphd.msg);
      set_is_showModal(false);
      call_getAllSiphd.request();
    };
    if(call_modifySiphd.status === 'err') {
      message.error(call_modifySiphd.msg);
      set_is_showModal(false);
    };
  }, [call_modifySiphd.status]);

  // 卸載時清除被編輯的資料
  useEffect(() => {
    
    return () => {
      set_s_editData({});
    }
  }, []);

  // 點擊確定送出時執行
  const onSubmit = async values => {
    const type = Object.keys(s_editData).length === 0 ? 'create' : 'modify';

    // 建立
    if(type === 'create') { 
      const tmpValues = {
        hd: values,
        tb: s_oldTb
      };
      console.log('準備送給後端建立的資料 = ', tmpValues);
      call_addSiphd.request(tmpValues);
    };

    // 修改
    if(type === 'modify') {
      // 不可改 siptype
      console.log('準備送給後端建立的資料 = ', {
        ...values,
        sipID: s_editData.sipID, 
        version: s_editData.version,
        siptype: s_editData.siptype
      });
      call_modifySiphd.request({
        ...values,
        sipID: s_editData.sipID, 
        version: s_editData.version,
        siptype: s_editData.siptype
      });
    };
  };

  const onCancel = () => {
    set_is_showModal(false);
  }

  // 據此條目創建
  const createNew = () => {

    const template = {...s_editData};
    // 先清空被編輯的資料
    set_s_editData({});

    // 根據每個表單不同塞資料
    form.setFieldsValue({
      'PN': template.PN,
      'pdtNM': template.pdtNM,
      'isvalid': template.isvalid,
      'soptype': template.soptype,
      'ctrlspot': template.ctrlspot,
      'spotID': null,
      'note': null
    });
    const obj = {
      sipID: s_editData.sipID,
      version: s_editData.version,
      spotID: s_editData.spotID
    }
    
    // 取得據此條目創建的表身
    call_getAllSiptb.request(obj);
  };

  return (
    <Form.Provider
      onFormChange={(formName, info) => {
        const { changedFields } = info;
        const fieldName = changedFields[0].name[0];
        const fieldValue = changedFields[0].value
        // 改變料號要自動帶入品名
        if(fieldName === 'PN') {
          if(fieldValue === '' || fieldValue === undefined) {
            form.setFieldsValue({
              'pdtNM': ''
            });
          } else {
            form.setFieldsValue({
              'pdtNM': s_PN.length > 0 && s_PN.find(item => item.PN === fieldValue).pdtNM,
            });
          }
        }
        // 判斷控點編號要顯示 wl 還是 ws 的下拉選單
        if(fieldName === 'ctrlspot') {
          // 如果清空控點要重新選擇控點編號
          // if(changedFields[0].value === undefined) {
            form.setFieldsValue({
              'spotID': undefined,
            });
          // }

          if(fieldValue) {
            set_s_ctrlspot(changedFields[0].value)
          }
        }
      }}
    >
      <CustomForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          'PN': Object.keys(s_editData).length > 0 ? s_editData.PN : null,
          'pdtNM': Object.keys(s_editData).length > 0 ? s_editData.pdtNM : null,
          'isvalid': Object.keys(s_editData).length > 0 ? s_editData.isvalid : '1',
          'siptype': Object.keys(s_editData).length > 0 ? s_editData.siptype : null,
          'ctrlspot': Object.keys(s_editData).length > 0 ? s_editData.ctrlspot : null,
          'spotID': Object.keys(s_editData).length > 0 ? s_editData.spotID : null,
          'note': Object.keys(s_editData).length > 0 ? s_editData.note : null,
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>料號</label>}
              name="PN"
              rules={[{ required: true, message: '請選擇料號!' }]}
            >
              <Select 
                placeholder="選擇料號" 
                allowClear
                disabled={Object.keys(s_editData).length > 0 ? true : false}
              >
                {s_PN?.map((item, index) => {
                  return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label style={{color: 'rgba(0, 0, 0, 0.25)'}}>品名</label>}
              name="pdtNM"
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>是否有效</label>}
              name="isvalid"
              rules={[{ required: true, message: '請選擇是否有效!' }]}
            >
              <Radio.Group name="isvalid">
                <Radio value='1'>是</Radio>
                <Radio value='0'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Form.Item
              label={<label style={{color: '#6C6C6C'}}>品檢類型</label>}
              name="siptype"
              rules={[{ required: true, message: '請選擇品檢類型!' }]}
            >
              <Select placeholder="請選擇品檢類型" allowClear>
                <Select.Option value='1'>lQC進料檢驗</Select.Option>
                <Select.Option value='2'>lPQC製中檢驗</Select.Option>
                <Select.Option value='3'>AlPQC自動製中檢驗</Select.Option>
                <Select.Option value='4'>SQC過站檢驗</Select.Option>
                <Select.Option value='5'>FQC終品檢驗</Select.Option>
                <Select.Option value='6'>OQC出貨檢驗</Select.Option>
                <Select.Option value='7'>FAl首件檢驗</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<label style={{color: '#6C6C6C'}}>控點</label>}
              name="ctrlspot"
              rules={[{ required: true, message: '請選擇控點!' }]}
            >
              <Select 
                placeholder="選擇控點" 
                allowClear
                disabled={Object.keys(s_editData).length > 0 ? true : false}
              >
                <Select.Option value="ws">工站比對</Select.Option>
                <Select.Option value="wl">工線比對</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<label style={{color: '#6C6C6C'}}>控點編號</label>}
              name="spotID"
              rules={[{ required: true, message: '請選擇控點編號!' }]}
            >
              <Select 
                placeholder="選擇控點編號" 
                allowClear
                disabled={Object.keys(s_editData).length > 0 ? true : false}
              >
                
                {s_ctrlspot === 'ws' ? (
                  s_ws.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>)
                ) : null}

                {s_ctrlspot === 'wl' ? (
                  s_wl.map(item => <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>)
                ) : null}

              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item
              label={<label style={{color: '#6C6C6C'}}>備註</label>}
              name="note"
            >
              <Input.TextArea name='note' rows={3} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          <Col span={18}>
            {Object.keys(s_editData).length > 0 ? (
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={createNew}>
                據此條目創建
              </Button>
            ) : null}
          </Col>
          <Col span={6}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={onCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </CustomForm>
    </Form.Provider>
  )
};

export default ModalContainer;