import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得全部良品資料 ----------------------------------------
export const getOkItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOkItem`, {params: data});
};

//----------------------------------------- 新增良品資料 ----------------------------------------
export const addOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOkItem`, data);
};

//----------------------------------------- 修改良品資料 ----------------------------------------
export const modifyOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOkItem`, data);
};

//----------------------------------------- 刪除良品資料 ----------------------------------------
export const deleteOkItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOkItem`, data);
};