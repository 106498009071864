import React, { useState, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components';

import { DataContext } from '../../index';

import CustomModal from '../../../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';
import Chart from './Chart';

import { fontSize } from '../../../../styled/publicTag';

import { PlusOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  height: 100%;
  width: 25%;
  margin: 0px 5px 5px 30px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 42%);
  position: relative;
  /* overflow: hidden; */

  .Title {
    color: ${({ theme }) => theme.mainColor};
    font-size: ${fontSize.h3};
    margin-bottom: 10px;
    height: 30px;
  }

  .Container {
    /* height: 100%; */
    /* background-color: red; */
    /* width: 100%; */
    position: absolute;
    top: 50px;
    bottom: 0px;
    left: 15px;
    right: 15px;
    overflow: auto;
    padding-bottom: 10px;
  }

  .addBox {
    width: 100%;
    height: 250px;
    border: 2px dashed #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #f8f6f6;
    }
  }
`

const RightContainer = () => {

  const { c_searchData } = useContext(DataContext);

  const [is_showModal, set_is_showModal] = useState(false);

  const theme = useTheme();

  // 用來存每張『迴歸分析圖』的詳細資料
  const [s_plotsKey, set_s_plotsKey] = useState([]);

  // 用來存放搜尋『迴歸分析圖』的 X、Y 軸資料
  const [s_keys, set_s_keys] = useState([]);

  // 開啟建立線性回歸圖的 Modal
  const openModal = () => {
    set_is_showModal(true)
  }

  const dataAll = [
    [
      [10.0, 8.04],
      [8.0, 6.95],
      [13.0, 7.58],
      [9.0, 8.81],
      [11.0, 8.33],
      [14.0, 9.96],
      [6.0, 7.24],
      [4.0, 4.26],
      [12.0, 10.84],
      [7.0, 4.82],
      [5.0, 5.68]
    ],
  ];
  const markLineOpt = {
    animation: false,
    label: {
      formatter: 'y = 0.5 * x + 3',
      align: 'right'
    },
    lineStyle: {
      type: 'solid'
    },
    tooltip: {
      formatter: 'y = 0.5 * x + 3'
    },
    data: [
      [
        {
          coord: [0, 3],
          symbol: 'none'
        },
        {
          coord: [20, 13],
          symbol: 'none'
        }
      ]
    ]
  };

  const options = {
    color: '#749f83',
    grid: {
      left: '20px',
      right: '10px',
      top: '10px',
      bottom: '50px',
      z: 99
    },
    xAxis: {
      min: 0,
      max: 20,
      axisLabel: { textStyle: { color: '#bdbdbd' } },
      axisLine: { lineStyle: { color: '#bdbdbd' } },
    },
    yAxis: {
      min: 0,
      max: 15,
      axisLabel: { textStyle: { color: '#bdbdbd' } },
      axisLine: { lineStyle: { color: '#bdbdbd' } },
    },
    series: {
      name: 'I',
      type: 'scatter',
      color: `${theme.mainColor}`,
      xAxisIndex: 0,
      yAxisIndex: 0,
      data: dataAll[0],
      markLine: markLineOpt
    },
  }

  const { c_param } = useContext(DataContext);

  // 取得迴歸分析圖的資料
  useEffect(() => {

    let moment = true;

    if (s_keys.length === 0) return;

    // 組出等的要輪流 call api 陣列
    const searchArray = s_keys.map(item => {
      return {
        freq: c_searchData.freq,
        pdtlot: c_searchData.pdtlot,
        wlID: c_searchData.wlID,
        start: c_searchData.start,
        end: c_searchData.end,
        X: item.xField,
        Y: item.yField
      };
    });

    if (moment) {
      set_s_plotsKey(searchArray)
    }

    return () => moment = false;

  }, [s_keys, c_searchData]);

  useEffect(() => {
    console.log('c_param.show = ', c_param.show)
    set_s_plotsKey([])
  }, [c_param]);

  console.log('s_plotsKey = ', s_plotsKey)

  return (
    <Wrapper>
      <div className='Title'>相關與回歸分析</div>

      <div className='Container'>

        {/* 有詳細資料就渲染出圖表 */}
        {s_plotsKey.length > 0 ? (
          s_plotsKey.map((item, num) => <Chart key={num} plotsData={item} />)
        ) : null}

        {/* 新增圖表的按鈕 */}
        <div className='addBox' onClick={openModal}>
          <PlusOutlined style={{ fontSize: '46px', color: '#bdbdbd' }} />
        </div>

      </div>

      {is_showModal ? (
        <CustomModal
          width='25%'
          style={{ top: '30%' }}
          title="新增散點圖"
          visible={is_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_is_showModal(false)}
        >
          <ModalContainer set_is_showModal={set_is_showModal} s_keys={s_keys} set_s_keys={set_s_keys} />
        </CustomModal>
      ) : null}

    </Wrapper>
  )
};

export default RightContainer;