import React, { useState, useEffect } from 'react';

import { Button, Input, message } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import Title from '../../components/Custom/Title';

import CustomModal from '../../components/antdCustom/CustomModal';
import CustomTabs from '../../components/antdCustom/CustomTabs';

// 停工項目相關
import { getStopItem } from '../../services/api/StopProject';
import StopProject from './StopProject';
import StopProjectModal from './StopProject/ModalContainer';

// 良品相關
import { getOkItem } from '../../services/api/GoodProject';
import GoodProject from './GoodProject';
import GoodProjectModal from './GoodProject/ModalContainer';

// 不良品相關
import { getNgItem } from '../../services/api/BadProject';
import BadProject from './BadProject';
import BabProjectModal from './BadProject/ModalContainer';

message.config({
  duration: 1.5
});

export const DataContext = React.createContext();

const FunctionProject = () => {

  const [ c_searchValue, set_c_searchValue ] = useState('');
  const [ c_editData, set_c_editData ] = useState({});

  const [ c_tableData, set_c_tableData ] = useState([]);

  const [ c_showModal, set_c_showModal ] = useState(false);  // 是否開啟 dialog
  const [ s_TabKey, set_s_TabKey ] = useState('StopProject');     // 目前是在哪個Tab上
  const [ s_placeholder, set_s_placeholder ] = useState('');

  const TabsData = [
    {
      'key': 'StopProject',
      'tabName': '停工項目管理'
    },
    {
      'key': 'GoodProject',
      'tabName': '良品項目管理'
    },
    {
      'key': 'BadProject',
      'tabName': '不良項目管理'
    },
  ];

  const placeholder_Map = {
    'StopProject': '停工項目',
    'GoodProject': '良品項目',
    'BadProject': '不良項目',
  };

  // 更改 Tab 時更新 placeholder、Modal title
  useEffect(() => {
    const placeholder = placeholder_Map[s_TabKey];
    set_s_placeholder(placeholder)
  
  }, [s_TabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  // 更改 Tab 時清空搜尋資料
  useEffect(() => {
    set_c_searchValue('');
  }, [s_TabKey]);

  // 更改 Tab 時重新取得 table 資料
  useEffect(() => {
    
    if(s_TabKey === 'StopProject') {
      call_getAllStopProject();
    };

    if(s_TabKey === 'GoodProject') {
      call_getAllGoodProject();
    };

    if(s_TabKey === 'BadProject') {
      call_getAllBadProject();
    }

  }, [s_TabKey, c_showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // 取得所有停工項目資料
  const call_getAllStopProject = async () => {
    try {
      const res = c_searchValue === '' ? await getStopItem() : await getStopItem({'item':c_searchValue});
      
      console.log('全部停工資料 res = ', res);
      
      if(res.data) {

        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_c_tableData(tmpData)
      } else {
        set_c_tableData([])
      }
      
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  };

  // 取得所有良品項目資料
  const call_getAllGoodProject = async () => {
    try {
      const res = c_searchValue === '' ? await getOkItem() : await getOkItem({item: c_searchValue});
      console.log('res = ', res)
      if(res.data) {
        console.log('全部良品資料 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_c_tableData(tmpData)
      } else {
        set_c_tableData([])
      }
      
    } catch (e) {
      console.log('---------- api getNgItem error ----------');
      console.log(e)
    }
  };

  // 取得所有不良項目資料
  const call_getAllBadProject = async () => {
    try {
      const res = c_searchValue === '' ? await getNgItem() : await getNgItem({item: c_searchValue});
      console.log('res = ', res)
      if(res.data) {
        console.log('全部不良品資料 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_c_tableData(tmpData)
      } else {
        set_c_tableData([])
      }
      
    } catch (e) {
      console.log('---------- api getNgItem error ----------');
      console.log(e)
    }
  };

  // 搜尋
  const onSearch = () => {
    
    if(s_TabKey === 'StopProject') {
      call_getAllStopProject()
    };

    if(s_TabKey === 'GoodProject') {
      call_getAllGoodProject()
    };

    if(s_TabKey === 'BadProject') {
      call_getAllBadProject()
    }
  };

  // 更改搜尋資料
  const handleChange = async event => {
    const value = event.target.value;
    set_c_searchValue(value);

    if(value === '') {
      if(s_TabKey === 'StopProject') {
        try {
          const res = await getStopItem();
          
          console.log('全部停工資料 res = ', res);
          
          if(res.data) {
    
            const tmpData = res.data.map((item, index) => {
              return {
                ...item,
                key: index
              }
            });
            set_c_tableData(tmpData)
          } else {
            set_c_tableData([])
          }
          
        } catch (e) {
          console.log('---------- api getAllStaffPerm error ----------');
          console.log(e)
        }
      };
  
      if(s_TabKey === 'GoodProject') {
        try {
          const res = await getOkItem();
          console.log('res = ', res)
          if(res.data) {
            console.log('全部良品資料 = ', res.data);
            const tmpData = res.data.map((item, index) => {
              return {
                ...item,
                key: index
              }
            });
            set_c_tableData(tmpData)
          } else {
            set_c_tableData([])
          }
          
        } catch (e) {
          console.log('---------- api getNgItem error ----------');
          console.log(e)
        }
      };
  
      if(s_TabKey === 'BadProject') {
        try {
          const res = await getNgItem();
          console.log('res = ', res)
          if(res.data) {
            console.log('全部不良品資料 = ', res.data);
            const tmpData = res.data.map((item, index) => {
              return {
                ...item,
                key: index
              }
            });
            set_c_tableData(tmpData)
          } else {
            set_c_tableData([])
          }
          
        } catch (e) {
          console.log('---------- api getNgItem error ----------');
          console.log(e)
        }
      }
    }
  };

  // 建立
  const Create = () => {
    set_c_showModal(true);
  };

  return (
    <>
      <DataContext.Provider 
        value={{
          c_searchValue,
          set_c_searchValue,
          c_editData,
          set_c_editData,
          c_showModal,
          set_c_showModal,
          c_tableData,
          call_getAllStopProject,
          call_getAllGoodProject,
          call_getAllBadProject
        }}
      >
        <Title title='功能字串管理'>
          <div style={{display: 'flex'}}>
            <Input.Search 
              className='Input'
              size='large' 
              enterButton
              onChange={handleChange}
              value={c_searchValue}
              placeholder={`請輸入欲查詢的${s_placeholder}資料`}
              onSearch={onSearch}
            />
            <Button 
              onClick={Create} 
              type="primary" 
              size='large'
              style={{borderRadius: '5px'}} 
              icon={<PlusOutlined />} 
            >
              新增
            </Button>
          </div>
        </Title>

        {/* 決定目前要顯示哪個page */}
        <CustomTabs 
          onChange={activeKey => set_s_TabKey(activeKey)}
          activeKey={s_TabKey}
          TabsData={TabsData}
        />

        {/* 根據 Tab 顯示 page */}
        {s_TabKey === 'StopProject' ? (
          <StopProject />
        ) : s_TabKey === 'GoodProject' ? (
          <GoodProject />
        ) : s_TabKey === 'BadProject' ? (
          <BadProject />
        ) : null}

        {c_showModal ? ( 
          <CustomModal 
            width='60%' 
            title={Object.keys(c_editData).length > 0 ? `修改${s_placeholder}資料` : `新建${s_placeholder}資料`} 
            visible={c_showModal} 
            maskClosable={false}
            destroyOnClose
            footer={null}
            forceRender
            getContainer={false}
            onCancel={() => set_c_showModal(false)}
            afterClose={() => {
            }}
          >
            {/* 根據 Tab 顯示 ModalContainer */}
            {s_TabKey === 'StopProject' ? (
              <StopProjectModal />
            ) : s_TabKey === 'GoodProject' ? (
              <GoodProjectModal />
            ) : s_TabKey === 'BadProject' ? (
              <BabProjectModal />
            ) : null}
          </CustomModal>
        ) : null}

      </DataContext.Provider>
    </>
  )

};

export default FunctionProject;