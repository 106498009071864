import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Space, Divider } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { changeMenuStatus } from "../../redux/action";
import ThemeButton from "../ThemeButton/ThemeButton";
import styled from "styled-components";
import moment from "moment";
import { Title } from "../Text/index";

const HeaderElement = ({ className, r_isNavBar, user }) => {
  const { Header } = Layout;

  const [s_time, setTime] = useState();

  const dispatch = useDispatch();

  const r_userState = useSelector(allReducers => allReducers.userState);

  // console.log('r_userState = ', r_userState)

  useEffect(() => {
    setTime(moment().format("YYYY-MM-DD HH:mm"));
    const timer = setInterval(() => {
      setTime(moment().format("YYYY-MM-DD HH:mm"));
    }, 60000);
    //畫面Rendering 完之後塞到dom
    return () => clearInterval(timer);
    //只執行一次
  }, []);

  const is_navbar = (e) => {
    // 使用 react 的 e.stopPropagation 不能阻止冒泡，需要使用 e.nativeEvent.stopImmediatePropagation
    stop_propagation(e);
    dispatch(changeMenuStatus(r_isNavBar));
  };
  //冒泡
  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  //當navbar展開往右擠navbar的寬度
  return (
    <Header className={className}>
      <Row>
        <Col span={12}>
          <Space className="active-navbar-open-width">
            {r_isNavBar ? (
              <MenuFoldOutlined
                onClick={is_navbar}
                className="navbar-button-size"
              />
            ) : (
              <MenuUnfoldOutlined
                onClick={is_navbar}
                className="navbar-button-size"
              />
            )}
            <Title level="h2" type="main" style={{color: 'rgb(49, 90, 226)'}}>
              律勝生產戰情系統
            </Title>
          </Space>
        </Col>
        <Col span={12}>
          <Space
            split={<Divider type="vertical" className="divider-style" />}
            className="header-right-space-style"
            align="baseline"
          >
            {window.innerWidth > 820 ? <Title type="main" level="h2">{user.userName ? user.userName : 'Wayne'}</Title> : null}
            {window.innerWidth > 820 ? (
              <Title level="h2" type="main">
                {s_time}
              </Title>
            ) : null}
            <Title type="main" level="h2">南科廠</Title>
            <ThemeButton className="flex-center" />
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

const Header = styled(HeaderElement)`
  z-index: 2;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  min-width: 100%;
  transition: all 0.3s;
  top: 0;
  padding: 0 25px;
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divider-style {
    border-width: 1;
    border-color: rgba(0, 0, 0, 0.12);
    height: 35px;
  }
  .active-navbar-open-width {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: ${({ r_isNavBar }) => {
      return r_isNavBar ? null : "220px";
    }};
    transition: 0.3s;
  }
  .header-right-space-style {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .anticon {
    vertical-align: middle;
    font-size: 26px;
  }
`;

export default Header;
