import React, { useEffect, useContext } from 'react';
import { DataContext } from '../index';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, message, Divider, Radio } from 'antd';
import { addStation, modifyStation } from '../../../services/api/Station';

message.config({
  duration: 1.5
})

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = () => {

  const [form] = Form.useForm();

  const { set_c_showModal, c_editData, set_c_editData } = useContext(DataContext);

  useEffect(() => {
    form.resetFields();
    console.log('要被編輯的工站資料 = ', c_editData)
  }, [form, c_editData]);

  useEffect(() => {

    return () => set_c_editData({});

  }, []);

  const onSubmit = async values => {

    const type = Object.keys(c_editData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      const res = await addStation(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_c_showModal(false);
    };

    if(type === 'modify') {
      const res = await modifyStation(values.wsID, values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_c_showModal(false);
    };
    
  }

  const handleCancel = () => {
    set_c_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          wsID: Object.keys(c_editData).length > 0 ? c_editData.wsID : '',
          wsNM: Object.keys(c_editData).length > 0 ? c_editData.wsNM : '', 
          set0wpc: Object.keys(c_editData).length > 0 ? c_editData.set0wpc : 'N',          // 設定短單
          set1gpc: Object.keys(c_editData).length > 0 ? c_editData.set1gpc : 'N',          // 設定工段
          set2mold: Object.keys(c_editData).length > 0 ? c_editData.set2mold : 'N',        //設定模具同步
          set3whm: Object.keys(c_editData).length > 0 ? c_editData.set3whm : 'N',          //設定工時
          set4feedlot: Object.keys(c_editData).length > 0 ? c_editData.set4feedlot : 'N',  // 設定原料投料
          set5pid: Object.keys(c_editData).length > 0 ? c_editData.set5pid : 'N',        // 設定條碼管理
          set6alm: Object.keys(c_editData).length > 0 ? c_editData.set6alm : 'N',          // 設定輔線
          set7netwt: Object.keys(c_editData).length > 0 ? c_editData.set7netwt : 'N',      //設定淨重劑量
          set8sop: Object.keys(c_editData).length > 0 ? c_editData.set8sop : 'N',    // 設定SOP監控
          set9sip: Object.keys(c_editData).length > 0 ? c_editData.set9sip : 'N',          // 設定SIP監控
          setarpm: Object.keys(c_editData).length > 0 ? c_editData.setarpm : 'N',          // 設定配方
          setline: Object.keys(c_editData).length > 0 ? c_editData.setline : 'N',          // 是否設線
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item 
              name="wsID"
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>工站ID</label>}
              rules={Object.keys(c_editData).length === 0 ? [{ required: true, message: '請填寫工站ID!' }] : null}
            >
                <Input disabled={Object.keys(c_editData).length === 0 ? false : true} name='wsID' placeholder="請填寫工站ID" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name="wsNM"
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>工站名稱</label>}
              rules={[{ required: true, message: '請填寫工站名稱!' }]}
            >
              <Input name='wsNM' placeholder="請填寫工站名稱" />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px 6px 24px 6px'}} />

        <Row>
          <Col span={8}>
            <Form.Item 
              name='set0wpc'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定短單:</label>} 
            >
              <Radio.Group className="center" name="set0wpc">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='set1gpc'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定工段:</label>} 
            >
              <Radio.Group className="center" name="set1gpc">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='set2mold'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定模具同步管理:</label>} 
            >
              <Radio.Group className="center" name="set2mold">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              name='set3whm'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定工時管理:</label>} 
            >
              <Radio.Group className="center" name="set3whm">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='set4feedlot'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定原料投料:</label>} 
            >
              <Radio.Group className="center" name="set4feedlot">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='set5pid'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定條碼管理:</label>} 
            >
              <Radio.Group className="center" name="set5pid">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              name='set6alm'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定輔線:</label>} 
            >
              <Radio.Group className="center" name="set6alm">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='set7netwt'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定淨重劑量:</label>} 
            >
              <Radio.Group className="center" name="set7netwt">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='setarpm'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定淨配方管理:</label>} 
            >
              <Radio.Group className="center" name="setarpm">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          
          <Col span={8}>
            <Form.Item 
              name='set8sop'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定SOP監控管理:</label>} 
            >
              <Radio.Group className="center" name="set8sop">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='set9sip'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>設定SIP監控管理:</label>} 
            >
              <Radio.Group className="center" name="set9sip">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              name='setline'
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>是否設線:</label>} 
            >
              <Radio.Group className="center" name="setline">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px 6px 24px 6px'}} />

        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer