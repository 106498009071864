import React, { useState, useContext } from 'react';
import { Modal } from 'antd';

import { DataContext } from '../index';

import { MySubtitleH4 } from '../../../styled/publicTag';
import { message } from 'antd';

import { ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { deleteLine } from '../../../services/api/Line';

import {
  IconTrash,
  IconPen
} from '../../../components/Icon';

import CustomTable from '../../../components/antdCustom/CustomTable';

message.config({
  duration: 1.5
})

const Station = () => {

  const innerWidth = window.innerWidth;

  const { call_getAllLine, set_c_editData, set_c_showModal, c_tableData } = useContext(DataContext);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  //編輯工線資料
  const editWl = rowData => {
    set_c_showModal(true);
    set_c_editData(rowData);
  }

  // 刪除工線資料
  const removeWl = rowData => {
    Modal.confirm({
      title: '工線刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的工線無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteLine(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllLine();
      },
      cancelText: '取消',
    });
  }

  const tableColumns = [
    {
      title: '工線ID',
      dataIndex: 'wlID',
      key: 'wlID',
      align: 'center',
      // width: 100
    },
    {
      title: '工線名稱',
      dataIndex: 'wlNM',
      key: 'wlNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${200}px`
    },
    {
      title: '工站',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    {
      title: '製程',
      dataIndex: 'mpNM',
      key: 'mpNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    // {
    //   title: '品牌',
    //   dataIndex: 'brand',
    //   key: 'brand',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${250}px` : `${300}px`
    // },
    // {
    //   title: '型號',
    //   dataIndex: 'model',
    //   key: 'modle',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${250}px` : `${300}px`
    // },
    
    {
      title: '反應',
      dataIndex: 'reaction',
      key: 'reaction',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    {
      title: '設備圖照',
      dataIndex: 'wlpic',
      key: 'wlpic',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    // {
    //   title: '一對一機台IP',
    //   dataIndex: 'singleIP',
    //   key: 'singleIP',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    // },
    // {
    //   title: '一對多機台IP',
    //   dataIndex: 'mutiIP',
    //   key: 'mutiIP',
    //   align: 'center',
    //   width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    // },
    {
      title: '車間',
      dataIndex: 'psNM',
      key: 'psNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`
    },
    {
      // title: '設定工段',
      title: '設採集',
      dataIndex: 'setget',
      key: 'setget',
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.setget;
        return {
          // props: {
          //   style: { backgroundColor: '#d2f8d2' }
          // },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <div style={{display: 'flex'}}>
            <IconPen className='icon' onClick={() => editWl(rowData)} />
            <IconTrash className='icon' onClick={() => removeWl(rowData)} />
          </div>
        </div>
      )
    },
  ]

  return (
    <CustomTable 
      scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
      columns={tableColumns}
      dataSource={c_tableData.map((item, index) => {
        return {
          key: index,
          ...item
        }
      })}
      pagination={{
        defaultCurrent: 1,
        total: pageInfo.totalItems,
        // current: abcBoardConfig.page,
        showSizeChanger: true,
        pageSize: pageInfo.pageSize,
        pageSizeOptions: ["10", "20", "30"],
        onChange: (current, size) => {
          setPageInfo({
            ...pageInfo,
            pageSize: size
          })
        },
        showTotal: (total, range) => (
          <MySubtitleH4>
            共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
          </MySubtitleH4>
        ),
      }}
    />
  )
}
export default Station;