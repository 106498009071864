import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得全部報工作業權限 ----------------------------------------
export const getAllWosPerm = (id, data) => {
  //console.log(`${conn}://${url}/api/getAllWosPerm`)
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWosPerm`);
};

//----------------------------------------- 搜尋報工作業權限 ----------------------------------------
export const getWosID = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWosID?wosID=${id}`);
};

//----------------------------------------- 新增報工作業權限 ----------------------------------------
export const addWosPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWosPerm`, data);
};

//----------------------------------------- 刪除報工作業權限 ----------------------------------------
export const deleteWosPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWosPerm`, data);
};

//----------------------------------------- 修改報工作業權限 ----------------------------------------
export const modifyWosPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWosPerm`, data);
};