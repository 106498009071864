import React from 'react';
import styled from 'styled-components';

import LeftContainer from './LeftContainer/index';
import RightContainer from './RightContainer/index';

const Wrapper = styled.div`
  height: calc(100vh - 64px - 75px - 48px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ChartContainer = () => {

  return (
    <Wrapper>
      <LeftContainer />
      <RightContainer />
    </Wrapper>
  )
};

export default ChartContainer;