import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { DataContext } from '../index';
import { Form, Button, Row, Col, Input, message, Select, Radio } from 'antd';
import { addLine, modifyLine } from '../../../services/api/Line';
import { getAllStation } from '../../../services/api/Station';
import { getAllPS } from '../../../services/api/PSData'

message.config({
  duration: 1.5
})

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = () => {

  const { set_c_showModal, c_editData, set_c_editData } = useContext(DataContext);

  const [form] = Form.useForm();

  const [s_AllStation, set_s_AllStation] = useState([]);  // 所有工站資訊
  const [s_AllPS, set_s_AllPS] = useState([]);            // 所有車間資訊

  useEffect(() => {
    const call_getAllStation = async () => {
      const res = await getAllStation();
      if(res.status) {
        set_s_AllStation(res.data)
      }
    };
    const call_getAllPS = async () => {
      const res = await getAllPS();
      if(res.status) {
        set_s_AllPS(res.data)
      }
    };
    call_getAllStation()
    call_getAllPS()
  },[]);

  useEffect(() => {
    form.resetFields()
  }, [form, c_editData]);

  useEffect(() => {

    return () => set_c_editData({});

  }, []);

  const onSubmit = async values => {

    const type = Object.keys(c_editData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增工線資料
      const res = await addLine(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_c_showModal(false);
    };

    if(type === 'modify') {
      // 修改工線資料
      const res = await modifyLine(values.wlID, values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_c_showModal(false);
    };
  }

  const handleCancel = () => {
    set_c_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          wsID: Object.keys(c_editData).length > 0 ? c_editData.wsID : null,      // 站別
          psID: Object.keys(c_editData).length > 0 ? c_editData.psID : null,      // 車間
          wlID: Object.keys(c_editData).length > 0 ? c_editData.wlID : '',        // 線別
          wlNM: Object.keys(c_editData).length > 0 ? c_editData.wlNM : '',        // 工線名稱
          // brand: Object.keys(c_editData).length > 0 ? c_editData.brand : '',      // 品牌
          // model: Object.keys(c_editData).length > 0 ? c_editData.model : '',      // 型號
          
          mpNM: Object.keys(c_editData).length > 0 ? c_editData.mpNM : null,        // 製程名稱
          // reaction: Object.keys(c_editData).length > 0 ? c_editData.reaction : '',  // 反應名稱
          setget: Object.keys(c_editData).length > 0 ? c_editData.setget : '',      // 設採集
          wlpic: Object.keys(c_editData).length > 0 ? c_editData.wlpic : '',        // 設備圖照
          singleIP: Object.keys(c_editData).length > 0 ? c_editData.singleIP : '',  // 一對一機台IP
          mutiIP: Object.keys(c_editData).length > 0 ? c_editData.mutiIP : '',      // 一對多幾台IP
        }}
      >
        <Row gutter={[24,12]}>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>工線ID</label>}
              name="wlID"
              rules={[{ required: true, message: '請填寫工線ID!' }]}
            >
                <Input disabled={Object.keys(c_editData).length === 0 ? false : true} name='wlID' placeholder="請填寫工線ID" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>工線名稱</label>}
              name="wlNM"
              rules={[{ required: true, message: '請填寫工線名稱!' }]}
            >
              <Input name='wlNM' placeholder="請填寫工線名稱" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>工站名稱</label>}
              name="wsID"
              rules={[{ required: true, message: '請選擇工站!' }]}
            >
              <Select placeholder="請選擇工站" allowClear >
                {s_AllStation.length > 0 && s_AllStation.map(item => {
                  return <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>
              })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24,12]}>
          {/* <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>品牌</label>}
              name="brand"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請填寫品牌名稱!' }]}
            >
              <Input name='brand' placeholder="請填寫品牌名稱" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>型號</label>}
              name="model"
              wrapperCol={{span:20}}
              rules={[{ required: true, message: '請填寫型號名稱!' }]}
            >
              <Input name='model' placeholder="請填寫型號名稱" />
            </Form.Item>
          </Col> */}
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>車間名稱</label>}
              name="psID"
            >
              <Select placeholder="請選擇車間" allowClear >
                {s_AllPS.length > 0 && s_AllPS.map(item => {
                  return <Select.Option key={item.psID} value={item.psID}>{item.psNM}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>製程名稱</label>}
              name="mpNM"
            >
              {/* <Input name='wlNM' placeholder="請填寫製程名稱" /> */}
              <Select placeholder="請選擇製程名稱" allowClear >
                <Select.Option value='第一道反應'>第一道反應</Select.Option>
                <Select.Option value='第二道反應'>第二道反應</Select.Option>
                <Select.Option value='第三道反應'>第三道反應</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>是否設採集</label>}
              name="setget"
            >
               <Radio.Group className="center" name="setget">
                <Radio value='Y'>是</Radio>
                <Radio value='N'>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {/* <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>反應名稱</label>}
              name="reaction"
            >
              <Input name='reaction' placeholder="請填寫反應名稱" />
            </Form.Item>
          </Col> */}
          
        </Row>

        <Row gutter={[24, 12]}>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>設備圖照</label>}
              name="wlpic"
            >
              <Input name='wlpic' placeholder="請填寫設備圖照" />
            </Form.Item>
          </Col>
          {/* <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>一對一機台IP</label>}
              name="singleIP"
            >
              <Input name='singleIP' placeholder="請填寫一對一機台IP" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label={<label style={{color: '#6C6C6C'}}>一對多機台IP</label>}
              name="mutiIP"
            >
              <Input name='mutiIP' placeholder="請填寫一對多機台IP" />
            </Form.Item>
          </Col> */}
          
        </Row>

        <Row gutter={[24, 12]}>
         
        </Row>
        
        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer