import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

// 取得所有SIP表頭
export const getAllSiphd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllSiphd`, { params: _params });
};

// 新增SOP表頭
export const addSiphd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addSiphd`, data);
};

// 編輯SOP表頭
export const modifySiphd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifySiphd`, data);
};

// 刪除SOP表頭
export const deleteSiphd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteSiphd`, data);
};

// 取得所有SIP表身
export const getAllSiptb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllSiptb`, { params: _params });
};

// 新增SIP表身
export const addSiptb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addSiptb`, data);
};

// 取得圖號
export const getAllSiptype = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllSiptype`);
}

// 編輯SIP表身
export const modifySiptb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifySiptb`, data);
};