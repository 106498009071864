import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeMenuStatus, changeNavBarInlineStatus } from "../../redux/action";

import { fontSize } from "../../styled/publicTag";

import { logout } from "../../services/api/publicAPI";

import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, Switch } from "antd";

import { BarsOutlined, ExportOutlined } from "@ant-design/icons";
import {
  IconWon,
  IconDashboard,
  IconAbcboard,
  IconOverview,
  IconLinesOverview,
  IconLineDetails,
  IconInformation,
  IconWorkOrders,
  IconBuilding,
  IconSystemData,
  IconUsers,
  IconSystemLogin,
  IconPermissions,
  IconReportSetting,
  IconBasicData,
  IconBasicProduct,
  IconProductWpc,
  IconPsData,
  IconStation,
  IconLine,
  IconMould,
  IconWsReport,
  IconWsResume,
  IconWsgo,
  IconCard,
  IconInproduction,
  IconApsManager,
  IconPSPIInformation,
} from "../../components/Icon";
import styled from "styled-components";

import Logo from "../../assets/images/logo.png";

const NavBarElenment = ({ className, r_isNavbar, r_isNavbarInline }) => {
  const { Sider } = Layout;

  const { SubMenu } = Menu;

  const dispatch = useDispatch();

  const r_userState = useSelector((allReducers) => allReducers.userState);

  //get path name
  let location = useLocation();

  const call_logout = async () => {
    const res = await logout();
  };

  useEffect(() => {
    const handleMenuToggle = () => {
      if (r_isNavbar === false) {
        dispatch(changeMenuStatus(r_isNavbar));
      }
    };
    // 在 document 上绑定click事件，隐藏弹出層
    // console.log('menu打開')
    window.addEventListener("click", handleMenuToggle);
    return () => {
      window.removeEventListener("click", handleMenuToggle);
    };
    // eslint-disable-next-line
  }, [r_isNavbar]);

  const stop_propagation = (e) => {
    e.nativeEvent.stopImmediatePropagation();
  };

  const is_sider_state = (e) => {
    stop_propagation(e);
  };

  const is_change_navbar_inline = () => {
    dispatch(changeNavBarInlineStatus(r_isNavbarInline));
  };

  const colseNavbar = () => {
    dispatch(changeMenuStatus(false));
  };

  return (
    <Sider
      className={className}
      trigger={null}
      collapsed={r_isNavbar}
      collapsedWidth={r_isNavbarInline ? 0 : 70}
      onClick={is_sider_state}
      width={230}
    >
      <div className="main-area">
        <div className="logo-box">
          <img src={Logo} alt="工智聯科技" className="logo" />
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          className="menu-area"
          subMenuCloseDelay={20}
        >
          <Menu.Item
            key="Scada"
            icon={<IconDashboard />}
            onClick={colseNavbar}
            style={{ height: "40px" }}
          >
            <NavLink to="/Scada">設備生產狀況</NavLink>
          </Menu.Item>

          <Menu.Item
            key="abcboard"
            icon={<IconAbcboard />}
            onClick={colseNavbar}
            disabled
            style={{ height: "40px" }}
          >
            <NavLink to="/abcboard">異常管理看板</NavLink>
          </Menu.Item>

          <SubMenu
            key="sub5"
            title="製程開發管理"
            icon={<IconOverview />}
            style={{ flexGrow: "1" }}
          >
            <Menu.Item
              key="/Trends"
              icon={<IconBasicProduct />}
              onClick={colseNavbar}
              style={{ height: "35px" }}
            >
              <NavLink to="/Trends">製程趨勢分析</NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Divider
            style={{ height: "1px", backgroundColor: "rgba(0, 0, 0, 0.06)" }}
          />

          <SubMenu
            key="sub1"
            title="生產資訊查詢"
            icon={<IconInformation />}
            style={{ flexGrow: "1" }}
          >
            <Menu.Item
              key="/BatchQuery"
              onClick={colseNavbar}
              style={{ height: "35px" }}
            >
              <NavLink to="/BatchQuery">生產批號查詢</NavLink>
            </Menu.Item>
            <Menu.Item
              key="/quality-record-search"
              onClick={colseNavbar}
              style={{ height: "35px" }}
            >
              <NavLink to="/quality-record-search">品檢紀錄查詢</NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Divider
            style={{ height: "1px", backgroundColor: "rgba(0, 0, 0, 0.06)" }}
          />

          {r_userState && r_userState.setBDM === "1" ? (
            <SubMenu
              key="sub2"
              title="基本資料維護"
              icon={<IconBasicData />}
              style={{ flexGrow: "1" }}
            >
              <Menu.Item
                key="/BasicProductInfo"
                icon={<IconBasicProduct />}
                onClick={colseNavbar}
                style={{ height: "35px" }}
              >
                <NavLink to="/BasicProductInfo">產品基本資料</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/ProductWpc"
                icon={<IconProductWpc />}
                onClick={colseNavbar}
                style={{ height: "35px" }}
              >
                <NavLink to="/ProductWpc">產品途程資料</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/sop"
                icon={<IconLinesOverview />}
                style={{ height: "35px" }}
              >
                <NavLink to="/sop">製程標準管理</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/sip"
                icon={<IconLineDetails />}
                style={{ height: "35px" }}
              >
                <NavLink to="/sip">檢驗標準管理</NavLink>
              </Menu.Item>
            </SubMenu>
          ) : null}

          {r_userState && r_userState.setSDM === "1" ? (
            <SubMenu
              key="sub3"
              title="系統資料維護"
              icon={<IconSystemData />}
              style={{ flexGrow: "1" }}
            >
              <Menu.Item
                key="/Users"
                icon={<IconUsers />}
                onClick={colseNavbar}
              >
                <NavLink to="/Users">員工基本資料</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/SystemLogin"
                icon={<IconSystemLogin />}
                onClick={colseNavbar}
                style={{ height: "35px" }}
              >
                <NavLink to="/SystemLogin">戰情登錄權限</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/WosPermissions"
                icon={<IconPermissions />}
                onClick={colseNavbar}
                style={{ height: "35px" }}
              >
                <NavLink to="/WosPermissions">報工作業權限</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/FunctionString"
                icon={<IconWsResume />}
                onClick={colseNavbar}
                style={{ height: "35px" }}
              >
                <NavLink to="/FunctionString">功能字串管理</NavLink>
              </Menu.Item>

              {/* <Menu.Item
                key="/StopProject"
                icon={<IconPermissions />}
                onClick={colseNavbar}
                style={{height: '35px'}}
              >
                <NavLink to="/StopProject">停工項目管理</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/BadProject"
                icon={<IconPermissions />}
                onClick={colseNavbar}
                style={{height: '35px'}}
              >
                <NavLink to="/BadProject">不良項目管理</NavLink>
              </Menu.Item>

              <Menu.Item
                key="/GoodProject"
                icon={<IconPermissions />}
                onClick={colseNavbar}
                style={{height: '35px'}}
              >
                <NavLink to="/GoodProject">良品項目管理</NavLink>
              </Menu.Item> */}
            </SubMenu>
          ) : null}

          {r_userState && r_userState.pdsID === "superman" ? (
            <SubMenu
              key="sub4"
              title="技術資料維護"
              icon={<IconLine />}
              style={{ flexGrow: "1" }}
            >
              <Menu.Item
                key="/FunctionProject"
                icon={<IconLine />}
                onClick={colseNavbar}
                style={{ height: "40px" }}
              >
                <NavLink to="/FunctionProject">廠區資料管理</NavLink>
              </Menu.Item>
            </SubMenu>
          ) : null}
        </Menu>
        <Menu mode="inline" className="bottom-area">
          <Menu.Item
            icon={<BarsOutlined />}
            key="changeNavbar"
            title=""
            className="ml-0 mr-0"
          >
            {/* <Switch
              defaultChecked={r_isNavbarInline ? false : true}
              onChange={is_change_navbar_inline}
            />
            <span style={{marginLeft: '20px'}}>V2.0.11</span> */}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Switch
                style={{ marginLeft: "0px" }}
                onChange={is_change_navbar_inline}
              />
              <span
                style={{
                  fontSize: "20px",
                  marginRight: "10px",
                  marginLeft: "15px",
                }}
              >
                V2.1.0
              </span>
            </div>
          </Menu.Item>

          <Menu.Item
            icon={<ExportOutlined />}
            key="logout"
            onClick={call_logout}
          >
            <span>登出</span>
          </Menu.Item>
        </Menu>
      </div>
    </Sider>
  );
};

const NavBar = styled(NavBarElenment)`
  z-index: ${({ r_isNavbar }) => (r_isNavbar ? 1 : 3)};
  box-shadow: 5px 0px 3px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  position: fixed;
  height: 100vh;
  left: 0;
  svg {
    width: 33px;
  }
  .ant-menu .ant-menu-item {
    margin: 0px;
  }

  .ant-menu-submenu-title {
    margin: 0px;
  }

  .ant-menu-submenu-title > span,
  .ant-menu-item > span {
    margin-right: 15px;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .main-area {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .menu-area {
    flex-grow: 1;
    border-right: 1px solid rgba(0, 0, 0, 0);
    font-size: 18px;
  }
  .bottom-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-right: 1px solid rgba(0, 0, 0, 0);
  }
  .logo-box {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .logo {
    height: 100%;
    width: 100%;
    padding: 15px 30px;
    object-fit: contain;
  }

  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  .ml-0 > .ant-menu-title-content {
    margin-left: 0px;
  }

  .mr-0 > .ant-menu-title-content {
    margin-left: 0px;
  }
`;

export default NavBar;
