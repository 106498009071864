import React, { useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import { fontSize } from '../../../styled/publicTag';

import { DataContext } from '../index';

const Wrapper = styled.div`
  padding:5px;
  margin-top: 10px;
  border: 1.5px solid #BEBEBE;
  /* background-color: ${({isDragging}) => isDragging ? 'red' : '#FFFFFF'}; */
  
  border-radius: 5px;
  font-size: ${fontSize.p};
  text-align: center;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 1%);

  &:hover {
    background-color: #F5F5F5;
  };
  ${({isDragging}) => isDragging && css`
    background-color: #c4c4c4;
  `}
`

const DragBox = ({droppableId, draggingOver, isDragging, itemID, itemNM}) => {

  const { set_c_drag, c_param, set_c_param } = useContext(DataContext);

  // 控制箭頭要顯示哪個方向
  useEffect(() => {

    if(draggingOver !== null) {
      set_c_drag(draggingOver)
    }
    
  }, [draggingOver]);

  // 雙擊時可代替拖曳功能
  const DoubleClick = () => {

    let show = [...c_param.show];
    let display = [...c_param.display];

    if(droppableId === 'show') {
      show = show.filter(item => item !== itemID)
      display.push(itemID)
    }

    if(droppableId === 'display') {
      display = display.filter(item => item !== itemID)
      show.push(itemID)
    }

    const newData = { show, display }

    set_c_param(newData)
  }

  return (
    <Wrapper
      onDoubleClick={DoubleClick}
      isDragging={isDragging}
    >
      {itemNM}
    </Wrapper>
  )
};

export default DragBox;