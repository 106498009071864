import React, { useState } from "react";

import HD from "./HD";
import TB from "./TB";

const QualityRecordSearch = () => {
  const [c_lightData, set_c_lightData] = useState({}); // 要亮起來的資料

  return (
    <>
      <HD c_lightData={c_lightData} set_c_lightData={set_c_lightData} />
      {Object.keys(c_lightData).length > 0 && <TB c_lightData={c_lightData} />}
    </>
  );
};

export default QualityRecordSearch;
