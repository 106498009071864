import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { getAllStaff } from '../../../services/api/Users';
import { addStaffPerm, modifyStaffPerm } from '../../../services/api/SystemLogin';


message.config({
  duration: 1.5
})

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`

  // 客製化輸入框寬度設定100
  .w-100 {
    width: 100%;
  }
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }
  
`;

const ModalContainer = ({set_s_showModal, s_staffData, set_s_staffData}) => {

  const [s_AllStaff, set_s_AllStaff] = useState([]);
  const [form] = Form.useForm()

  const onSubmit = async (values) => {
    const type = Object.keys(s_staffData).length === 0 ? 'create' : 'modify';
    const tmpValues = {...values};
    //Object.entries(values).forEach(([key, value]) => (value && key !== 'staffpwd') ? value.toString() : '2')
    // Object.entries(values).forEach(([key, value]) => {
    //   if(value) {
    //     tmpValues[key] = value.toString()
    //   }
    // })

    console.log('準備送給後端建立的資料 = ', tmpValues)

    //return

    if(type === 'create') { // 建立權限
      const res = await addStaffPerm(tmpValues);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      
      set_s_showModal(false);
    }
    if(type === 'modify') { // 修改權限
      //console.log(tmpValues)
      const res = await modifyStaffPerm(tmpValues);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      //console.log(res)
      set_s_showModal(false);
    }
  }
  
  const handleCancel = () => {
    set_s_showModal(false)
  }

  // 取得全部員工基本資料(做下拉選單用)
  useEffect(() => {
    const call_getAllStaff = async () => {
      try {
        //取得所有員工資料
        const res = await getAllStaff();
        if(res.status && res.data.length > 0) {
          // 擋掉凍結人員
          const newData = res.data.filter(item => {
            return item.quitTString === ''
          })
          set_s_AllStaff(newData)
        }
        
      } catch (e) {
        console.log("全部員工基本資料-資料獲取失敗", e);
      } 
    };
    call_getAllStaff(); //------------------ API 先關閉 ------------------
    const call_fake_getAllStaff = () => {
      const fakedata = [
        {
            "staffID": "aaa",
            "peopleNM": "Jason",
            "cardID": "08000AAA0597",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "2021-07-19T09:28:09+08:00",
            "quitT": "2021-07-19T09:28:24+08:00",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "bbb",
            "peopleNM": "Wayne",
            "cardID": "08000BBB34B6",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "ccc",
            "peopleNM": "Amy",
            "cardID": "08000CCCA351",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "ddd",
            "peopleNM": "Jeff",
            "cardID": "08000DDD9270",
            "function": "PM",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "eee",
            "peopleNM": "Derren",
            "cardID": "08000EEEC113",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "fff",
            "peopleNM": "Sam",
            "cardID": "08000FFFF032",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "ggg",
            "peopleNM": "Penny",
            "cardID": "08001AAA06E4",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        },
        {
            "staffID": "hhh",
            "peopleNM": "Rosa",
            "cardID": "08001BBB37C5",
            "function": "",
            "level": 0,
            "personalID": "",
            "tel": "",
            "mailaddr": "",
            "proxyNM": "",
            "proxytel": "",
            "enterT": "0001-01-01T00:00:00Z",
            "quitT": "0001-01-01T00:00:00Z",
            "updT": "0001-01-01T00:00:00Z"
        }
      ];
      set_s_AllStaff(fakedata)
    }
    //console.log('s_staffData = ', s_staffData)
    //call_fake_getAllStaff()
  }, []);

  useEffect(() => {
    // form.resetFields()
  }, [form, s_staffData]);
  
  // 據此條目創建
  const createNew = () => {

    const template = {...s_staffData};
    // 先清空被編輯的資料
    set_s_staffData({});

    // 根據表單不同塞資料，不要的都塞null
    form.setFieldsValue({
      'peopleNM': null,
      'account': null,
      'pdsID': null,
      'staffpwd': null,
    })
  }

  return (
    <>
      <Form.Provider
        
        onFormChange={(formName, info) => {
          const { changedFields } = info;
          // 如果切換員工姓名, 要切換員工工號(帳號)
          if( changedFields.length > 0 && changedFields[0].name[0] === 'peopleNM') {
            const obj = s_AllStaff.find(item => item.peopleNM === changedFields[0].value);
            //console.log('obj = ', obj)
            form.setFieldsValue({
              'account': obj ? obj.staffID : null,
              'pdsID': obj ? obj.staffID : null,
            });
          }
        }}
      >
        <StyledForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            peopleNM: s_staffData ? s_staffData.peopleNM : null,
            account: s_staffData ? s_staffData.account ? s_staffData.account : s_staffData.pdsID  : '', 
            pdsID: s_staffData ? s_staffData.pdsID : null, // key 不可修改
            //staffpwd: Object.keys(s_staffData).length > 0 ? s_staffData.staffpwd : null,
            setAPP: Object.keys(s_staffData).length > 0 ? s_staffData.setAPP : '0',
            setautorpt: Object.keys(s_staffData).length > 0 ? s_staffData.setautorpt : '0',
            setDBS: Object.keys(s_staffData).length > 0 ? s_staffData.setDBS : '0',
            setBDM: Object.keys(s_staffData).length > 0 ? s_staffData.setBDM : '0',
            setSDM: Object.keys(s_staffData).length > 0 ? s_staffData.setSDM : '0',
            setABNQ: Object.keys(s_staffData).length> 0 ? s_staffData.setABNQ : '0',
            setAPS: Object.keys(s_staffData).length > 0 ? s_staffData.setAPS : '0',

            setWMS: Object.keys(s_staffData).length > 0 ? s_staffData.setWMS : '2',//
            setOPS: Object.keys(s_staffData).length > 0 ? s_staffData.setOPS : '2',
            setMNS: Object.keys(s_staffData).length > 0 ? s_staffData.setMNS : '2',
            
            setFMS: Object.keys(s_staffData).length > 0 ? s_staffData.setFMS : '2',
            setGMS: Object.keys(s_staffData).length > 0 ? s_staffData.setGMS : '2',
          }}
        >

          <Row style={{marginBottom: '12px', display: 'none'}}>
            <Col span={8}>
              <Row>
                <Col offset={2}>
                  <Button disabled size='large' style={{borderRadius: '5px'}} onClick={() => alert('施工中')}>
                    <LeftOutlined />
                    上一筆
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col offset={8} span={8}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button size='large' style={{borderRadius: '5px', marginRight: '30px'}} onClick={() => alert('施工中')}>
                  下一筆
                  <RightOutlined />
                </Button>
              </div>

            </Col>
          </Row>

          <Row>
            <Col span={8} >
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>員工姓名</label>}
                name="peopleNM"
                rules={[{ required: true, message: '請選擇員工!' }]}
              >
                <Select 
                  disabled={Object.keys(s_staffData).length === 0 ? false : true}
                  placeholder="選擇員工姓名" 
                  allowClear
                  name="peopleNM"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {s_AllStaff.length > 0 && s_AllStaff.map(item => {
                    return <Select.Option key={item.staffID} value={item.peopleNM}>{item.peopleNM}</Select.Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              {/* <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20}}
                label={<label style={{color: '#6C6C6C'}}>帳號</label>}
                name="account"
              >
                <MyAntdInput
                  type='filled'
                  defaultValue={s_staffData && s_staffData.account}
                  label='帳號'
                  placeholder='請填寫帳號'
                  className='w-100'
                />
              </Form.Item> */}
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>帳號</label>}
                name="account"
              >
                <Input name='account' placeholder="請填寫員工帳號" />
              </Form.Item>
              <Form.Item  // 影藏欄位，用來讓後端當 uuid
                style={{display: 'none'}}
                labelCol={{span:4}} 
                wrapperCol={{span:16}} 
                label={<label>帳號</label>}
                name="pdsID"
              >
                <Input style={{display: 'none'}} name='pdsID' />
              </Form.Item>
            </Col>
            <Col span={8}>
              {/* <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>{Object.keys(s_staffData).length === 0 ? '密碼' : '新密碼'}</label>}
                name="staffpwd" 
                rules={Object.keys(s_staffData).length === 0 ? [{ required: true, message: '請輸入密碼!' }] : null}
              >
                <MyAntdInput
                  type='outlined'
                  label='密碼'
                  placeholder='請輸入密碼'
                  className='w-100'
                />
              </Form.Item> */}
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>{Object.keys(s_staffData).length === 0 ? '密碼' : '新密碼'}</label>}
                name="staffpwd" 
                rules={Object.keys(s_staffData).length === 0 ? [{ required: true, message: '請輸入密碼!' }] : null}
              >
                <Input name='staffpwd' placeholder={Object.keys(s_staffData).length === 0 ? "請輸入密碼" : "請輸入新密碼"} />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '12px 0px 12px 0px', borderTop: '1.5px solid rgba(0,0,0,0.06)'}} />

          <Row>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>使用行動APP :</label>} 
                name='setAPP'
                // className="center"
              >
                <Radio.Group name="setAPP">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>接收自動報表:</label>} 
                name='setautorpt'
              >
                {/* <Divider style={{margin: '0px', backgroundColor: '#6C6C6C'}} /> */}
                <Radio.Group className="center" name="setautorpt">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>戰情系統</label>} 
                name='setDBS'
              >
                <Radio.Group className="center" name="setDBS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          
          <Divider style={{margin: '0px 0px 12px 0px'}} />

          <Row>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>基本資料維護</label>} 
                name='setBDM'
              >
                <Radio.Group className="center" name="setBDM">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>系統資料維護</label>}
                name='setSDM'
              >
                <Radio.Group className="center" name="setSDM">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{color: '#6C6C6C'}}>品保異常判責</label>}
                name='setABNQ'
              >
                <Radio.Group className="center" name="setABNQ">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{margin: '0px 0px 12px 0px'}} />

          <Row>
            
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}} 
                label={<label style={{ color: "#6C6C6C" }}>生管派工</label>} 
                name='setAPS'
              >
                <Radio.Group className="center" name="setAPS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>訂單管理</label>}
                name='setOPS'
              >
                <Radio.Group disabled name="setOPS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>管理推播</label>} 
                name='setMNS'
              >
                <Radio.Group disabled className="center" name="setMNS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            
          </Row>

          <Divider style={{margin: '0px 0px 12px 0px'}} />

          <Row>
          <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>庫儲管理</label>}
                name='setWMS'
              >
                <Radio.Group disabled className="center" name="setWMS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>廠務管理</label>} 
                name='setFMS'
              >
                <Radio.Group disabled className="center" name="setFMS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                labelCol={{offset:2}} 
                wrapperCol={{span:20, offset:2}}
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>總務管理</label>} 
                name='setGMS'
              >
                <Radio.Group disabled className="center" name="setGMS">
                  <Radio value='1'>啟用</Radio>
                  <Radio value='0'>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          
          <Divider style={{margin: '0px 0px 12px 0px', borderTop: '2.5px solid rgba(0,0,0,0.06)'}} />

          <Row>
            <Col span={18}>
              {Object.keys(s_staffData).length > 0 ? (
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={createNew}>
                  據此創建新條目
                </Button>
              ) : null}
            </Col>
            <Col span={6}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px', borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
          
        </StyledForm>
      </Form.Provider>
    </>
  )
}

export default ModalContainer;

/*
<Row>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}}  
                label={<label>使用行動APP</label>}
                name='setAPP'
              >
                <Radio.Group className="center" name="setAPP">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label>接收自動報表</label>}
                name='setautorpt'
              >
                <Radio.Group className="center" name="setautorpt">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label>戰情系統</label>}
                name='setDBS'
              >
                <Radio.Group className="center" name="setDBS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label>基本資料維護</label>}
                name='setBDM'
              >
                <Radio.Group className="center" name="setBDM">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label>系統資料維護</label>}
                name='setSDM'
              >
                <Radio.Group className="center" name="setSDM">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label>品保異常判責</label>}
                name='setABNQ'
              >
                <Radio.Group className="center" name="setABNQ">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>庫儲管理</label>}
                name='setWMS'
              >
                <Radio.Group disabled className="center" name="setWMS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>訂單管理</label>}
                name='setOPS'
              >
                <Radio.Group disabled name="setOPS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>管理推播</label>} 
                name='setMNS'
              >
                <Radio.Group disabled className="center" name="setMNS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>生產排程</label>} 
                name='setAPS'
              >
                <Radio.Group disabled className="center" name="setAPS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>廠務管理</label>} 
                name='setFMS'
              >
                <Radio.Group disabled className="center" name="setFMS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                labelCol={{span:8}} 
                wrapperCol={{span:16}} 
                label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>總務管理</label>} 
                name='setGMS'
              >
                <Radio.Group disabled className="center" name="setGMS">
                  <Radio value={1}>啟用</Radio>
                  <Radio value={0}>關閉</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="flexEnd" offset={17} span={6}>
              <Button onClick={handleCancel} style={{marginRight: '15px'}}>取消</Button>
              <Form.Item>
                <Button htmlType="submit" type="primary" style={{marginRight: '15px'}}>保存</Button>
              </Form.Item>
            </Col>
          </Row>
.center {
    margin-top: -10px;
  }

  .flexEnd {
    display: flex;
    justify-content: flex-end;
  }

  .mb12 > div {
    margin-bottom: 12px
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-form-item-control-input_xx {
    margin-top: -5px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
*/