import React from 'react';
import { useTheme } from 'styled-components'
import SVGcomponent from './SVGcomponent';
import { fontSize } from '../../styled/publicTag';
//import scada from './scada.svg'
//import { ReactComponent as MySvg } from './scada.svg'

const TestSVG = () => {

  const theme = useTheme();

  const title = {
    width: '250px',
    fontWeight: 'bold',
    padding: '5px',
    color: 'rgba(255, 255, 255, 0.8)',
    backgroundColor: theme.mainColor,
    fontSize: fontSize.h3,
    textAlign: 'center',
    borderRadius: '5px',
    marginTop: '20px',
    marginLeft: '40px'
  }

  return (
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
      <div style={title}>
        PSPI01車間
      </div>
      <SVGcomponent test="Ariean" />
    </div>
  )
}

export default TestSVG