import React, { useState, useEffect, useContext } from 'react';
import ReactECharts from 'echarts-for-react';
import styled, { useTheme } from 'styled-components';
import { DataContext } from '../../index';

import { analysis } from '../../../../services/api/analysis';
import { getCoordinateSystemDimensions } from 'echarts';

const Chart = ({ plotsData }) => {

  console.log('plotsData = ', plotsData)
  const { c_searchData, c_param, c_all_param } = useContext(DataContext);

  const options = c_param.show.length > 0 && c_param.show.map(item => {
    return { id: item, title: c_all_param[item].slice(3) }
  })
  // const options = [
  //   { id: 'inTemp', title: '槽內溫度' },
  //   { id: 'weight', title: '重量' },
  //   { id: 'flowN', title: '氮氣流量' },
  //   { id: 'torque', title: '扭力' },
  //   { id: 'frequency', title: '攪拌頻率' },
  // ]

  const theme = useTheme();

  const [s_data, set_s_data] = useState({});

  const [s_showData, set_s_showData] = useState({});

  // 取得圖表 api 資料
  useEffect(() => {
    const call_analysis = async () => {
      let moment = true;

      // if(Object.keys(plotsData))
      console.log('plotsData = ', plotsData)

      const res = await analysis(plotsData);

      console.log('plotsData = ', plotsData)

      if (res.status === true) {
        console.log('===== call_analysis =====');
        console.log('call analysis 的參數 => ', plotsData)
        // console.table(res.data)
      }

      moment === true && res.status === true ? set_s_data(res.data) : set_s_data({});

      return () => moment = false;
    };

    call_analysis();
  }, [plotsData]);

  useEffect(() => {
    if (Object.keys(s_data).length === 0) return;

    console.log('s_data = ', s_data)

    const slope = s_data.slope;
    const intercept = s_data.intercept;

    // for function
    const x_max = Math.max(...s_data.xdata);
    const y_max = Math.max(...s_data.ydata);

    // for coordinate
    const xCoor_max = Math.ceil(x_max)
    const yCoor_max = Math.ceil(y_max)
    // console.log('s_data = ', s_data)
    // console.log('x_max = ', x_max)
    // console.log('y_max = ', y_max)
    // console.log('(slope*x_max)+intercept = ', (slope*x_max)+intercept)

    // 判斷左側邊界條件
    const getCoord = () => {
      if (intercept < 0) {
        return [-intercept / slope, 0]
      } else {
        return [0, intercept]
      }
    }

    // 判斷右側邊界條件
    const getsecCoord = () => {
      if (slope * x_max + intercept < y_max) {
        return [x_max, slope * x_max + intercept]
      } else {
        return [(y_max - intercept) / slope, y_max]
      }
    }

    const markLineOpt = slope === 50984878 && intercept === 50984878 ? (
      {
        animation: false,
        label: {
          formatter: `x = ${s_data.xdata[0].toFixed(4)}`,
          align: 'right',
        },
        lineStyle: {
          type: 'solid'
        },
        tooltip: {
          formatter: `x = ${s_data.xdata[0].toFixed(4)}`,
        },
        data: [
          [
            {
              coord: [s_data.xdata[0], 0],
              symbol: 'none'
            },
            {
              coord: [s_data.xdata[s_data.xdata.length - 1], y_max],
              symbol: 'none'
            }
          ]
        ]
      }
    ) : (
      {
        animation: false,
        label: {
          formatter: `y = ${slope.toFixed(4)} * x + ${intercept.toFixed(4)}`,
          align: 'right'
        },
        lineStyle: {
          type: 'solid'
        },
        tooltip: {
          formatter: `y = ${slope.toFixed(4)} * x + ${intercept.toFixed(4)}`,
        },
        data: [
          [
            {
              coord: getCoord(),
              // coord: [2, 2],
              //coord: [Math.ceil(Math.min(...s_data.xdata)), Math.ceil(Math.min(...s_data.ydata))],
              symbol: 'none'
            },
            {
              coord: getsecCoord(),
              symbol: 'none'
            }
          ]
        ]
      }
    )

    const ponitArray = s_data.xdata.map((item, index) => {
      return [s_data.xdata[index], s_data.ydata[index]];
    });


    const options = {
      color: '#749f83',
      grid: {
        left: '30px',
        right: '10px',
        top: '10px',
        bottom: '50px',
        z: 99
      },
      xAxis: {
        min: 0,
        max: xCoor_max, // 取x軸陣列的最大值且無條件進位
        axisLabel: { textStyle: { color: '#bdbdbd' } },
        axisLine: { lineStyle: { color: '#bdbdbd' } },
      },
      yAxis: {
        min: 0,
        max: yCoor_max, // 取y軸陣列的最大值且無條件進位
        axisLabel: { textStyle: { color: '#bdbdbd' } },
        axisLine: { lineStyle: { color: '#bdbdbd' } },
      },
      series: {
        name: 'I',
        type: 'scatter',
        color: `${theme.Color3}`,
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: ponitArray,
        markLine: markLineOpt
      },
    };

    set_s_showData(options);

  }, [s_data]);

  console.log('options.find(item => item.id === plotsData.X) = ', options.find(item => item.id === plotsData.X))

  const X = options.find(item => item.id === plotsData.X);
  const Y = options.find(item => item.id === plotsData.Y);

  return (
    Object.keys(s_data).length > 0 ? (
      <div>
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <div
            style={{ color: theme.Color3, fontSize: '16px', fontWeight: '600' }}
          >
            {`${X ? X.title : ''}-${Y ? Y.title : ''}`}
          </div>
          <div
            style={{ margin: '0px 5px', color: 'grey', fontSize: '14px', fontWeight: '200' }}
          >
            {`相關係數: ${s_data.r.toFixed(2)}`}
          </div>
        </div>
        <ReactECharts option={s_showData} opts={{ renderer: 'svg' }} />
      </div>
    ) : null)
};

export default Chart;