import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';
import { getAllProduct, getAllStation, addWpc, modifyWpc } from '../../../services/api/ProductWpc';

message.config({
  duration: 1.5
})

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({set_s_showModal, s_WpcData, set_s_WpcData}) => {

  const [form] = Form.useForm();

  const [s_PNOptions, set_s_PNOptions] = useState([]);           // 料號的下拉選單
  const [s_StationOptions, set_s_StationOptions] = useState([])  // 工站的下拉選單

  // 卸載時執行
  useEffect(() => {
    
    return () => {
      set_s_WpcData({})
      //alert(123)
    }
  }, [])

  // 取得料號、工站下拉選單的資料
  useEffect(() => {
    const call_getAllProduct = async () => {
      const res = await getAllProduct();
      console.log('getAllProduct res = ', res)
      if(res.status === true && res.data.length > 0) {
        set_s_PNOptions(res.data);
      }
    }
    const call_getAllStation = async () => {
      const res = await getAllStation();
      console.log('getAllStation res = ', res)
      if(res.status === true && res.data.length > 0) {
        set_s_StationOptions(res.data)
      }

    }
    call_getAllProduct();
    call_getAllStation();
  }, []);

  useEffect(() => {
    //console.table(s_WpcData)
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [form, s_WpcData]);

  const onSubmit = values => {

    const type = Object.keys(s_WpcData).length === 0 ? 'create' : 'modify';

    const tmpValue = {
      ...values,
      'bfnetwt': parseFloat(values.bfnetwt),
      'afnetwt': parseFloat(values.afnetwt),
      'cycletimewh': parseFloat(values.cycletimewh),
      'alertyield': parseFloat(values.alertyield),
      'alertstop': parseFloat(values.alertstop),
      'alertspeed': parseFloat(values.alertspeed),
      'planhead': parseFloat(values.planhead),
      'cycletimemh': parseFloat(values.cycletimemh),
    }

    if(type === 'create') {
      // 新增產品途程資料
      const call_addWpc = async () => {
        const res = await addWpc(tmpValue);
        res.status ? message.success(res.msg) : message.error(res.msg);
      };
      call_addWpc();
    };

    if(type === 'modify') {
      // 修改產品途程資料
      const call_modifyWpc = async () => {
        console.log('傳給後端的資料 = ', tmpValue)
        // wsID 要傳修改前的
        const res = await modifyWpc(tmpValue);
        res.status ? message.success(res.msg) : message.error(res.msg);
      };
      call_modifyWpc();
    };
    
    set_s_showModal(false);
    set_s_WpcData({});
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          PN: Object.keys(s_WpcData).length > 0 ? s_WpcData.PN : null,      // 料號
          wpcset: Object.keys(s_WpcData).length > 0 ? s_WpcData.wpcset : '',  // 途程組 
          wsID: Object.keys(s_WpcData).length > 0 ? s_WpcData.wsID : null,      // 站別
          set0wpc:Object.keys(s_WpcData).length > 0 ? s_WpcData.set0wpc : '',
          bfnetwt:Object.keys(s_WpcData).length > 0 ? s_WpcData.bfnetwt : '',// 產前標準單重
          afnetwt:Object.keys(s_WpcData).length > 0 ? s_WpcData.afnetwt : '',// 產後標準單重
          cycletimewh:Object.keys(s_WpcData).length > 0 ? s_WpcData.cycletimewh : '',  // 標準工時
          cycletimemh:Object.keys(s_WpcData).length > 0 ? s_WpcData.cycletimemh : '',   // 標準機時
          alertyield:Object.keys(s_WpcData).length > 0 ? s_WpcData.alertyield : '',   // 良率報警
          planhead:Object.keys(s_WpcData).length > 0 ? s_WpcData.planhead : '',        // 計劃在崗人數
          alertspeed:Object.keys(s_WpcData).length > 0 ? s_WpcData.alertspeed : '',  // 產速報警  
          alertstop:Object.keys(s_WpcData).length > 0 ? s_WpcData.alertstop : '',         // 逾停報警
          sop:Object.keys(s_WpcData).length > 0 ? s_WpcData.sop : '',                // SOP 檔名
          sip:Object.keys(s_WpcData).length > 0 ? s_WpcData.sip : '',                // SIP 檔名
          wpcSN:Object.keys(s_WpcData).length > 0 ? s_WpcData.wpcSN : '',            // 途程號
          nextSN:Object.keys(s_WpcData).length > 0 ? s_WpcData.nextSN : '',         // 次途程號 X
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2,span: 20}}
              label={<label style={{color: '#6C6C6C'}}>料號</label>}
              name="PN"
              rules={[{ required: true, message: '請選擇料號!' }]}
            >
              <Select 
                disabled={Object.keys(s_WpcData).length > 0 ? true : false}
                placeholder="請選擇料號" 
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {s_PNOptions.map((item, index) => {
                  return <Select.Option key={index} value={item.PN}>{item.PN}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>途程組</label>}
              name="wpcset"
              rules={[{ required: true, message: '請選擇途程組!' }]}
            >
              <Input 
                name='wpcset' 
                // disabled={Object.keys(s_WpcData).length > 0 ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2,span: 20}}
              label={<label style={{color: '#6C6C6C'}}>途程號</label>}
              rules={[{ required: true, message: '請選擇途程號!' }]}
              name="wpcSN"
            >
              <Input 
                name='wpcSN'
                disabled={Object.keys(s_WpcData).length > 0 ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2,span: 20}}
              label={<label style={{color: '#6C6C6C'}}>次途程號</label>}
              rules={[{ required: true, message: '請選擇次途程號!' }]}
              name="nextSN"
            >
              <Input 
                name='nextSN' 
                disabled={Object.keys(s_WpcData).length > 0 ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2,span: 20}}
              label={<label style={{color: '#6C6C6C'}}>站別</label>}
              rules={Object.keys(s_WpcData).length === 0 ? [{ required: true, message: '請選擇站別!' }] : null}
              name="wsID"
            >
              {/* <Input name='wsID' /> */}
              <Select value='wsID' placeholder="請選擇站別" disabled={Object.keys(s_WpcData).length > 0 ? true : false}>
                {s_StationOptions.map((item, index) => {
                  return <Select.Option key={index} value={item.wsID}>{item.wsNM}</Select.Option>
                })}
                {/* <Select.Option value='WS01'>WS01</Select.Option>
                <Select.Option value='WS02'>WS02</Select.Option>
                <Select.Option value='WS03'>WS03</Select.Option>
                <Select.Option value='WS04'>WS04</Select.Option>
                <Select.Option value='WS05'>WS05</Select.Option> */}
              </Select>
            </Form.Item>
          </Col>
          
        </Row>

        <Divider style={{margin: '6px'}} />

        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>產前標準單重</label>}
              name="bfnetwt"
            >
              <Input name='bfnetwt' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>產後標準單重</label>}
              name="afnetwt"
            >
              <Input name='afnetwt' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset:2 ,span: 20}}
              label={<label style={{color: '#6C6C6C'}}>標準工時</label>}
              name="cycletimewh"
            >
                <Input name='cycletimewh' />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col align='right' span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset:2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>標準機時</label>}
              name="cycletimemh"
            >
                <Input name='cycletimemh' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset:2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>計畫在崗人數</label>}
              name="planhead"
            >
              <Input name='planhead' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset:2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>良率報警</label>}
              name="alertyield"
            >
              <Input name='alertyield' />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset: 2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>產速警報</label>}
              name="alertspeed"
            >
                <Input name='alertspeed' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset: 2}}
              wrapperCol={{offset:2, span: 20}}
              label={<label style={{color: '#6C6C6C'}}>逾停警報</label>}
              name="alertstop"
            >
                <Input name='alertstop' />
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{margin: '6px'}} />

        <Row>
          <Col offset={16} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px', marginRight: '40px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer