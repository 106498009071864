import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得所有工站資料 ----------------------------------------
export const getAllStation = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStation`);
};

//----------------------------------------- 新增工站資料 ----------------------------------------
export const addStation = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStation`, data);
};

//----------------------------------------- 修改工站資料 ----------------------------------------
export const modifyStation = (id, data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStation?wsID=${id}`, data);
};

//----------------------------------------- 刪除工站資料 ----------------------------------------
export const deleteStation = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStation`, data);
};

//----------------------------------------- 搜尋工站資料 ----------------------------------------
export const getStation = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStation?wsID=${id}`);
};