import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select, Input, DatePicker } from 'antd';
import Workbook from 'react-excel-workbook';
// import { SearchOutlined } from '@ant-design/icons';
import { IconExcel } from '../../components/Icon/index';
import moment from 'moment';

import { getAllWl } from '../../services/api/publicAPI';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .Select_time {
    /* width: 300px; */
  }

  .pdtlot {
    width: 200px;
  }

  .mr_15 {
    margin-right: 15px;
  }
`

const Header = ({s_searchData, set_s_searchData}) => {

  const [ s_wl, set_s_wl ] = useState([]);                                   // 全部線別資料
  const [ s_Excel, set_s_Excel ] = useState([]);

  const ExcelColumns = [
    {
      title: '時間',
      key: 'timestamp'
    },
    {
      title: '氣壓',
      key: 'airtube'
    },
    {
      title: '冰水',
      key: 'cwpipe'
    },
    {
      title: '冷卻水',
      key: 'wpipe'
    },
    {
      title: '氮氣',
      key: 'n2tube'
    },
    {
      title: '氧氣',
      key: 'o2tube'
    },
  ];

  const handleChange = (type, value) => {
    
    let tmp = {}

    console.log('type = ', type)
    console.log('value = ', value)

    if(type === 'time') {
      console.log('value = ', value)
      tmp = { ...s_searchData, 'start': value[0], 'end': value[1] };
    } else {
      tmp = { ...s_searchData, [type]: value };
    }
    console.log('tmp = ', tmp)
    set_s_searchData(tmp);
  }

  // 第一次渲染取得所有線別資料
  useEffect(() => {

    const call_getAllWl = async () => {
      try {
        const res = await getAllWl();
        // console.log('全部線別資料 = ', res)
        if(res.status === true) {
          set_s_wl(res.data);
          // console.log('res.data[0].wlID = ', res.data[0].wlID)
          handleChange('wlID', res.data[0].wlID)
        } else {
          set_s_wl([])
        }
      } catch (e) {
        set_s_wl([]);
        console.log('-------------------- call_getAllWl error --------------------')
      }
    };

    call_getAllWl();

  }, []);

  useEffect(() => {

    const call_Excel = async () => {

      try {
        // alert('wait Excel api')
        // const res = await mpExport({
        //   start: s_searchData.start,
        //   end: s_searchData.end
        // });

        // if(res.status === false) return;

        // const tmpData = res.data.map(item => {

        //   const tmpObject = Object.entries(item).reduce((prev, [key, value]) => {
        //     prev[key] = value.toString();
        //     return prev;
        //   }, {});

        //   return tmpObject;
        // })

        // res.status ? set_s_Excel(tmpData) : set_s_Excel([])
      } catch (e) {
        console.log(e)
        console.log('================== call_Excel error ==================')
      }
    }

    call_Excel();

  }, [s_searchData]);

  return (
    <Wrapper>
      {/* 日期起、迄選擇 */}
      {/* <DatePicker.RangePicker 
        size='large'
        className='Select_time mr_15'
        allowClear={false}
        value={[moment(s_searchData.start), moment(s_searchData.end)]}
        onChange={(dates, dateStrings) => {
          console.log('dateStrings = ', dateStrings)
          handleChange('time', dateStrings)
        }}
      /> */}

      {/* 批號 */}
      <Input
        size='large'
        className='pdtlot'
        placeholder='請輸入批號'
        value={s_searchData.pdtlot}
        onChange={e => handleChange('pdtlot', e.target.value)}
      />

      {/* 線別選項 */}
      {/* <Select 
        size='large'
        className='Select_wl'
        value={s_searchData.wlID}
        onChange={value => handleChange('wlID', value)}
      >
        {s_wl.length > 0 ? (
          s_wl.map(item => <Select.Option key={item.wlID} value={item.wlID}>{item.wlNM}</Select.Option>)
        ) : null}
      </Select> */}

      {/* <span style={{height: '38px', cursor: 'pointer'}}>
        <Workbook 
          filename={`生產批號查詢_${s_searchData.start}-${s_searchData.end}.xlsx`} 
          element={
            <IconExcel 
              style={{width: '30px', height: '38px', marginLeft: '10px', marginRight: '5px'}} 
            />
          }>
            <Workbook.Sheet data={s_Excel} name="Sheet A">
              {ExcelColumns.map((item, index) => {
                return(
                  <Workbook.Column key={index} label={item.title} value={item.key} />
                )
              })}
            </Workbook.Sheet>
          </Workbook>
        </span> */}

    </Wrapper>
  )
};

export default Header;