import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得所有途程資料 ----------------------------------------
export const getAllWpc = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWpc`);
};

//----------------------------------------- 新增途程資料 ----------------------------------------
export const addWpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWpc`, data);
};

//----------------------------------------- 修改指定途程資料 ----------------------------------------
export const modifyWpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWpc`, data);
};

//----------------------------------------- 刪除產品途程資料 ----------------------------------------
export const deleteWpc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWpc`, data);
};

//----------------------------------------- 取得單一產品的全部途層 ----------------------------------------
export const getWpc = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpc?PN=${id}`);
};

//----------------------------------------- 取得全部產基本資料 ----------------------------------------
export const getAllProduct = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllProduct`);
};

//----------------------------------------- 取得所有工站資料 ----------------------------------------
export const getAllStation = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStation`);
};

//----------------------------------------- 產品途程資料 - 上傳檔案 ----------------------------------------
export const wpcUpload = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/wpcUpload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};