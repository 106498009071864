import { useState, useEffect } from "react";
import {
  getAllRun81,
  checkRun81,
  feedRun81,
} from "../../../services/api/Scada";
import Title from "../../../components/Text";

import { Row, Col, Button, message } from "antd";

import SingleScada from "../SingleScada";

import CustomTable from "../../../components/antdCustom/CustomTable";

import CustomModal from "../../../components/antdCustom/CustomModal";

import CheckFeedC from "./CheckFeedC";

import Light from "../../../components/Light";


const getLight = (light) => {
  switch (light) {
    case "B":
      return <Light size="20px" type="main" />;
    case "G":
      return <Light size="20px" type="success" />;
    case "Y":
      return <Light size="20px" type="warning" />;
    case "W":
      return <Light size="20px" disabled />;
    case "R":
      return <Light size="20px" type="error" />;
    default:
      return <Light size="20px" disabled />;
  }
};

const ModalContainer = ({ s_showData, s_sink }) => {
  const scadaCol = [
    {
      title: "燈號",
      key: "ispass",
      dataIndex: "ispass",
      width: "100px",
      align: "center",
      render: (item) => getLight(item),
    },
    {
      title: "控程",
      key: "sopno",
      dataIndex: "sopno",
      width: "100px",
      align: "center",
    },
    {
      title: "觸發序",
      key: "flagC",
      dataIndex: "flagC",
      width: "80px",
      align: "center",
    },
    {
      title: "段數",
      key: "stepC",
      dataIndex: "stepC",
      width: "80px",
      align: "center",
    },
    {
      title: "投料序",
      key: "feedC",
      dataIndex: "feedC",
      width: "80px",
      align: "center",
    },
    {
      title: "時標",
      key: "updtTstr",
      dataIndex: "updtTstr",
      width: "150px",
      align: "center",
    },
    {
      title: "投料項目",
      key: "sopitem",
      dataIndex: "sopitem",
      width: "150px",
      align: "center",
    },

    {
      title: "查核",
      key: "check",
      dataIndex: "check",
      width: "100px",
      align: "center",
      render: (item, items, index) => checkFun(item, items, index),
    },
    {
      title: "投料",
      key: "action",
      dataIndex: "action",
      width: "100px",
      align: "center",
      render: (item, items, index) => actionFun(item, items, index),
    },
  ];

  const [run81Data, setRun81Data] = useState([]);

  const [isFeedCModal, setIsFeedCModal] = useState(false);

  const [thisData, setThisData] = useState({});

  const [isRun81DataLodading, setIsRun81DataLodading] = useState(false);

  useEffect(()=>{
    if (!isFeedCModal){
      call_getAllRun81()
    }
  },[isFeedCModal])

  const call_getAllRun81 = async () => {
    setIsRun81DataLodading(true);
    const res = await getAllRun81(s_showData);
    if (res.status) {
      message.success(res.msg)
      setRun81Data(
        res.data.map((x) => {
          return { ...x, key: x.operN + x.sopno };
        })
      );
    } else {
      message.error(res.msg);
    }
    setIsRun81DataLodading(false);
  };

  const call_feedRun81 = async (row) => {
    const res = await feedRun81(row);
    if (res.status) {
      call_getAllRun81();
      message.success(res.msg);
    } else {
      message.error(res.msg);
    }
  };

  const clickRow = (record) => {
    return {
      onClick: () => {
        setThisData({ ...record, sopno: parseInt(record.sopno) });
      },
    };
  };

  const rowClassName = (record, index) => {
    const getIndex = getlastStepCIndex();
    if (getIndex === index){
      return "thisRowStyle";
    }else if (record.realstep !== -1){
      return "updatedRowStyle"
    }
  };

  const checkFun = (item, items, index) => {
    const getStepCIndex = getlastStepCIndex();
    const getRealStepIndex = getlastRealStepIndex()
    return items.check ? (
      <Title size="h5" vice >已查核</Title>
    ) : items.feedC !== 0  && index === getRealStepIndex? (
      <Title size="h5" vice >未查核</Title>
    ) : null;
  };

  const actionFun = (item, items, index) => {
    const getStepCIndex = getlastStepCIndex();
    const getRealStepIndex = getlastRealStepIndex()
    return items.realstep === -1 && items.check&& index === getRealStepIndex? (
      <Title size="h5" vice>未投料</Title>
    ) : items.realstep !== -1 && items.check  ? (
      <Title size="h5" vice>已投料</Title>
    ) : null;
  };

  const getlastStepCIndex = () => {
    // 找到最後一個realstep 來判斷 目前 段數位置
    for (let i = run81Data.length - 1; i > -1; i--) {
      if (run81Data[i].realstep > 0) {
        return i;
      }
    }
    return -1;
  };

  const getlastRealStepIndex = () =>{
    const index = run81Data.map(x=>{
      return x.feedC > 0 && x.realstep
    }).indexOf(-1)
    return index
  }

  useEffect(() => {
    call_getAllRun81();
  }, []);

  return (
    
    <Row>
      <Col span={7}>
        <SingleScada s_showData={s_showData} s_sink={s_sink} />
      </Col>
      <Col span={17} style={{ marginTop: "9px",textAlign:"right" }}>
        <Button
          style={{marginBottom: '5px'}}
          type='primary'
          size='large'
          onClick={call_getAllRun81}
        >
          重新整理
        </Button>
        <CustomTable
          loading={isRun81DataLodading}
          dataSource={run81Data}
          columns={scadaCol}
          pagination={false}
          onRow={clickRow}
          rowClassName={rowClassName}
          scroll={{ x: "max-content", y: 525 }}
        />
      </Col>
      {isFeedCModal && (
        <CustomModal
          centered
          width="50%"
          title="查核點確認"
          visible={isFeedCModal}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => setIsFeedCModal(false)}
        >
          <CheckFeedC
            scadaData={s_showData}
            checkFeedCData={thisData}
            setIsShowModal={setIsFeedCModal}
          />
        </CustomModal>
      )}
    </Row>
  );
};

export default ModalContainer;