import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得全部戰情登錄權限資料 ----------------------------------------
export const getAllStaffPerm = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllStaffPerm`);
};

//----------------------------------------- 新建戰情登錄權限 ----------------------------------------
export const addStaffPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStaffPerm`, data);
};

//----------------------------------------- 搜尋戰情登錄權限 ----------------------------------------
export const getPdsID = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPdsID?pdsID=${id}`);
};

//----------------------------------------- 修改戰情登錄權限 ----------------------------------------
export const modifyStaffPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStaffPerm`, data);
};

//----------------------------------------- 刪除戰情登錄權限 ----------------------------------------
export const deleteStaffPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStaffPerm`, data);
};