import React, { useState, useEffect } from 'react';
import {  MySubtitleH4 } from '../../styled/publicTag.js';
import { getAllWosPerm, getWosID, deleteWosPerm } from '../../services/api/WosPermissions';
import ModalContainer from './ModalContainer';
import { Modal, message } from 'antd';
import { CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';
import {
  IconTrash,
  IconPen
} from '../../components/Icon'

const WosPermissions = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]); // 全部現場操作權限
  
  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_wosData, set_s_wosData] = useState({}); // 要被修改的現場操作權限

  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const call_getAllWosPerm = async () => {
    try {
      setTableLoading(true);
      //取得所有權限資料
      const res = s_searchValue === '' ? await getAllWosPerm() : await getWosID(s_searchValue);
      if(res.status && res.data) {
        console.log('所有權限資料 res.data = ', res.data)
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        set_s_tableData(tmpData)
      } else {
        set_s_tableData([])
      }
      
      setTableLoading(false);
    } catch (e) {
      console.log("全部現場操作權限資料-資料獲取失敗", e);
    }
    
  };

  //取得全部現場操作權限資料，每次關閉 dialog 都重抓
  useEffect(() => {
    
    call_getAllWosPerm(); //------------------ API 先關閉 ------------------
    if(s_showModal === false) {
      set_s_wosData({})
    }
  }, [s_showModal]);

  const createWos = () => {
    set_s_showModal(true);
  }
  const editWos = (data) => {
    set_s_showModal(true);
    set_s_wosData(data);
  }

  // 刪除報工作業權限
  const removeWos = rowData => {
    Modal.confirm({
      title: '報工作業權限刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的報工作業權限無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const tmpObj = {
          'wosID': rowData.wosID
        }
        const res = await deleteWosPerm(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllWosPerm();
      },
      cancelText: '取消',
    });
  }

  const handleChange = async value => {
    set_s_searchValue(value);
    if(value === '') {
      try {
        setTableLoading(true);
        //取得所有權限資料
        const res = await getAllWosPerm();
        if(res.status && res.data) {
          console.log('所有權限資料 res.data = ', res.data)
          const tmpData = res.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          set_s_tableData(tmpData)
        }
        
        setTableLoading(false);
      } catch (e) {
        console.log("全部現場操作權限資料-資料獲取失敗", e);
      }
    }
  }

  // 搜尋員工資料
  const onSearch = (value, event) => {
    
    const call_getWosID = async (value) => {
      
      if(s_searchValue === '') return;
      // api 有問題，待串接
      //return
      const res = await getWosID(s_searchValue);
      
      if(res.status) {
        console.log('onSearch res.data = ',res.data)
        set_s_tableData(res.data);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }
    call_getWosID(s_searchValue);
  }

  const tableColumns = [
    {
      title: '員工帳號',
      dataIndex: 'account',
      key: 'account',
      align: 'center',
      // width: 160
    },
    {
      title: '姓名',
      dataIndex: 'peopleNM',
      key: 'peopleNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${200}px`
    },
    {
      title: '報工作業',
      dataIndex: 'wocWo',
      key: 'wocWo',
      width: innerWidth <= 1440 ? `${110}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocWo;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '報工系統',
      dataIndex: 'wocSys',
      key: 'wocSys',
      //width: innerWidth <= 1440 ? 160 : 120,
      width: innerWidth <= 1440 ? `${110}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocSys;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '工站作業',
      dataIndex: 'sfcWo',
      key: 'sfcWo',
      //width: innerWidth <= 1440 ? 160 : 120,
      width: innerWidth <= 1440 ? `${110}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcWo;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      //title: () => innerWidth <= 1440 ? <span>過站-<br/>物控作業權限</span> : '過站-物控作業權限',
      title: '物控作業',
      dataIndex: 'sfcMc',
      key: 'sfcMc',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: innerWidth <= 1440 ? `${110}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcMc;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      //title: () => innerWidth <= 1440 ? <span>過站-<br />質控作業權限</span> : '過站-質控作業權限',
      title: '質控作業',
      dataIndex: 'sfcQc',
      key: 'sfcQc',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: innerWidth <= 1440 ? `${110}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcQc;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '隔離處置',
      //title: () => innerWidth <= 1440 ? <span>過站-<br />隔離處置權限</span> : '過站-隔離處置權限',
      dataIndex: 'sfcNg',
      key: 'sfcNg',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcNg;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: 'MRB處置',
      //title: () => innerWidth <= 1440 ? <span>過站-<br />MRB處置權限</span> : '過站-MRB處置權限',
      dataIndex: 'sfcMrb',
      key: 'sfcMrb',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcMrb;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '隔離處置',
      dataIndex: 'wocNgJudge',
      key: 'wocNgJudge',
      //width: innerWidth <= 1440 ? 160 : 120,
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocNgJudge;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '異常提報',
      dataIndex: 'wocAbnRpt',
      key: 'wocAbnRpt',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: innerWidth <= 1440 ? `${100}px` : `${140}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocAbnRpt;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '操作',
      dataIndex: 'jjj',
      key: 'jjj',
      width: innerWidth <= 1440 ? `${40}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{cursor: 'pointer'}}>
            <IconPen className='icon' width={30} onClick={() => editWos(rowData)} />
          </div>
          <div style={{cursor: 'pointer'}}>
            <IconTrash className='icon' width={30} onClick={() => removeWos(rowData)} />
          </div>
        </div>
      )
    }
  ]

  return (
    <>
      <Title 
        title='報工作業權限'
        placeholder='請輸入欲查詢員工資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createWos}
      />
      {/* <Title>
        <span>報工作業權限</span>
        <div className="flex-r">
          <Input.Search className='Input' size='large' placeholder="請輸入欲查詢員工資料" onChange={e => handleChange(e.target.value)} onSearch={onSearch} enterButton />
          <Button onClick={createWos}  type="primary" style={{borderRadius: '5px'}} icon={<PlusOutlined />} size='large'>
              新增
          </Button>
        </div>
      </Title> */}
      <CustomTable
        scroll={{ x: 'max-content',y: window.innerHeight - 60 - 45 - 220 }}
        columns={tableColumns}
        loading={tableLoading}
        dataSource={s_tableData.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
            // setAbcBoardConfig({
            //   ...abcBoardConfig,
            //   page: current,
            //   pageSize: size,
            // });
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal 
          width='50%' 
          title={ Object.keys(s_wosData).length === 0 ? "新增報工作業權限" : "修改報工作業權限" } 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          //afterClose={() => set_s_wosData({})}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_wosData={s_wosData} set_s_wosData={set_s_wosData} />
        </CustomModal>
      ) : null}
    </>
  )
}

export default WosPermissions;

/*
const tableColumns = [
    {
      title: '工號',
      dataIndex: 'account',
      key: 'account',
      width: 160
    },
    {
      title: '姓名',
      dataIndex: 'peopleNM',
      key: 'peopleNM',
      width: 160
    },
    {
      title: '報工作業',
      dataIndex: 'wocWo',
      key: 'wocWo',
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocWo;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '報工系統',
      dataIndex: 'wocSys',
      key: 'wocSys',
      //width: innerWidth <= 1440 ? 160 : 120,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocSys;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '工站作業',
      dataIndex: 'sfcWo',
      key: 'sfcWo',
      //width: innerWidth <= 1440 ? 160 : 120,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcWo;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      //title: () => innerWidth <= 1440 ? <span>過站-<br/>物控作業權限</span> : '過站-物控作業權限',
      title: '物控作業',
      dataIndex: 'sfcMc',
      key: 'sfcMc',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcMc;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      //title: () => innerWidth <= 1440 ? <span>過站-<br />質控作業權限</span> : '過站-質控作業權限',
      title: '質控作業',
      dataIndex: 'sfcQc',
      key: 'sfcQc',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcQc;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '隔離處置',
      //title: () => innerWidth <= 1440 ? <span>過站-<br />隔離處置權限</span> : '過站-隔離處置權限',
      dataIndex: 'sfcNg',
      key: 'sfcNg',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcNg;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: 'MRB處置',
      //title: () => innerWidth <= 1440 ? <span>過站-<br />MRB處置權限</span> : '過站-MRB處置權限',
      dataIndex: 'sfcMrb',
      key: 'sfcMrb',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.sfcMrb;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '隔離處置',
      dataIndex: 'wocNgJudge',
      key: 'wocNgJudge',
      //width: innerWidth <= 1440 ? 160 : 120,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocNgJudge;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '異常提報',
      dataIndex: 'wocAbnRpt',
      key: 'wocAbnRpt',
      //width: innerWidth <= 1440 ? 200 : 160,
      width: 160,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.wocAbnRpt;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '操作',
      dataIndex: 'jjj',
      key: 'jjj',
      width: 50,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{display: 'flex'}}>
          <PenSvg className='icon' width={30} onClick={() => editWos(rowData)} />
          <TrashSvg className='icon' width={30} onClick={() => removeWos(rowData)} />
        </div>
      )
    }
  ]

*/