import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';

import DragContainer from './DragContainer';
import Pointer from './Pointer';

import { DataContext } from '../index';

const Wrapper = styled.div`
  padding-bottom: 24px;
  display: flex;
  height: 500px;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: #EEEEEE;
  border-radius: 5px;
  padding: 10px;
  /* flex-grow: 1; */
`

const ModalContainer = () => {

  const queryAttr = "data-rbd-drag-handle-draggable-id";
  const { c_param, set_c_param, set_c_drag } = useContext(DataContext);

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;

    set_c_drag('')

    if (!destination) return;

    // console.log('source.droppableId = ', source.droppableId)
    // console.log('destination.droppableId = ', destination.droppableId)
    // console.log('source.index = ', source.index)
    // console.log('destination.index = ', destination.index)
    // console.log('result = ', result)
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startArray = c_param[source.droppableId];
    const finishArray = c_param[destination.droppableId]

    
    // console.log('draggableId = ', draggableId)
    // console.log('startArray = ', startArray)
    // console.log('finishArray = ', finishArray)

  if(source.droppableId === destination.droppableId) {
    const destinationName = source.droppableId === 'show' ? 'display' : 'show';
    startArray.splice(source.index, 1);

    startArray.splice(destination.index, 0, draggableId);
    console.log('startArray = ', startArray)
    const newState = {
      [source.droppableId]: startArray,
      [destinationName]: c_param[destinationName]
    }
    // console.log('newState = ', newState)
    set_c_param(newState);
  } else {
    startArray.splice(source.index, 1);
    finishArray.splice(destination.index, 0, draggableId);
    const newState = {
      [source.droppableId]: startArray,
      [destination.droppableId]: finishArray
    }
    // console.log('newState = ', newState)
    set_c_param(newState);
    
  }

   

    // console.log('result = ', result)
    // console.log('startArray = ', startArray);
    // console.log('finishArray = ', finishArray)
  }

  return (
    <Wrapper>
      <Container>
        <DragDropContext onDragEnd={onDragEnd}>
          <DragContainer data={c_param.display} droppableId='display' Header='隱藏指標' />
          <Pointer />
          <DragContainer data={c_param.show} droppableId='show' Header='顯示指標' />
        </DragDropContext>
      </Container>
    </Wrapper>
  )
};

export default ModalContainer;