import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import CustomTable from './CustomTable';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Form, Button, Row, Col, Input, message, Select } from 'antd'
import { MenuOutlined } from "@ant-design/icons";
import { DataContext } from '../../index';
import './index.css';
import { modifySiptb } from '../../../../services/api/sipAPI';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#CCC" }} />
));

// 拖曳用到的 function
function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
 
  if (startIndex >= 0 && startIndex < array.length) {
   const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
 
   const [item] = array.splice(fromIndex, 1);
   array.splice(endIndex, 0, item);
  }
}

// 拖曳用到的 function
function arrayMoveImmutable(array, fromIndex, toIndex) {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
}

const innerWidth = window.innerWidth;

const Wrapper = styled.div`

  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    color: green
  }

  .row-dragging td {
    padding: 16px;
    color: pink
  }

  .row-dragging .drag-visible {
    visibility: visible;
    color: red
  }
`
// 拖曳用到的 function
const SortableItem = sortableElement((props) => <tr {...props} />);
// 拖曳用到的 function
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const ItemModalContainer = ({className, s_tableData, set_s_tableData, set_s_showItemModal}) => {
  const [ data, setdata ] = useState([]);

  const { c_lightData, c_drawingno } = useContext(DataContext);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  // const [ s_drawingno, set_s_drawingno ] = useState([]);
  const [s_insptype, set_s_insptype] = useState('');

  const tableColumns = [
    {
      title: "排序",
      dataIndex: "sort",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      className: "drag-visible",
      render: (text, rowData, index) => {
        const bol = editingKey !== ''
        return bol ? '' : <DragHandle />
      },
    },
    {
      title: "SIP項次",
      dataIndex: "sipno",
      key: "sipno",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
    },
    
    {
      title: "類別",
      dataIndex: "itemtype",
      key: "itemtype",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: "型態",
      dataIndex: "insptype",
      key: "insptype",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${70}px` : `${70}px`
    },
    {
      title: "檢驗項目",
      dataIndex: "sipitem",
      key: "sipitem",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: "檢驗要點",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "inspnote",
      key: "inspnote",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: "監控參數",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "drawingno",
      key: "drawingno",
      align: "center",
      editable: true,
      render: (text, rowData, index) => {
        const item = c_drawingno.find((item) => item.itemID === rowData.drawingno);
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: item ? item.tagNM : rowData.drawingno
        };
      },
      // render: (text, rowData, index) => {
      //   const item = c_drawingno.find((item) => item.itemID === rowData.drawingno);
      //   return item ? item.tagNM : rowData.drawingno
      // },
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: "標準值",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "value",
      key: "value",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "規格上限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "USL",
      key: "USL",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "規格下限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "LSL",
      key: "LSL",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "管制上限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "UCL",
      key: "UCL",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "管制下限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "LCL",
      key: "LCL",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "AQL",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "AQL",
      key: "AQL",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: "單位",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "unit",
      key: "unit",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`
    },
    {
      title: "檢測環境",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "sipcond",
      key: "sipcond",
      align: "center",
      editable: true,
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      editable: true,
      // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (_, record) => {
        const isEdit = isEditing(record);
        return isEdit ? (
          <span>
            <Button
              onClick={() => save(record.key)}
              // style={{marginRight: 8,}}
            >
              暫存
            </Button>
          </span>
        ) : (
          <Button disabled={editingKey !== ''} onClick={() => edit(record)}>
            修改
          </Button>
        );
      },
    },
  ] 

  const isEditing = (record) => {
    // console.log(editingKey.includes(record.key))
    return editingKey === record.key;
  };

  // 編輯
  const edit = (record) => {
    console.log('record = ', record)
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.key);
  };

  // 儲存
  const save = async (key) => {
    const row = await form.validateFields();
    form.setFieldsValue({});
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setdata(newData);
    setEditingKey('');

  }

  // 將資料塞進 status 直接操作props會出問題
  useEffect(() => {
    setdata(s_tableData.map((item, index) => {
      return {
        key: index.toString(),
        index: index,
        ...item
      }
    }))
    // console.table(s_tableData)
  }, []);

  // 拖曳結束時更改陣列順序
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      // console.log("Sorted items: ", newData);
      const newData1 = newData.map((item, index) => {

        return {
          ...item,
          key: index.toString(),
          index: index,
          sipno: index+1,
        }
      })
      console.log('newData1 = ', newData1)
      setdata(newData1);
    }
  };

  const DraggableContainer = (props) => {
    // console.log('props = ', props)
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  // 告訴程式要傳哪些東西給每個 row
  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return ({
          record,
          // inputType: col.dataIndex === 'age' ? 'number' : 'text',
          // dataIndex: col.dataIndex,
          title: col.title,
          dataKey: col.dataIndex,
          editing: isEditing(record).toString(),
        })
      },
    };
  });

  // 判斷要不要 disabled
  const isDisabled = (dataKey) => {
    const siptype = c_lightData.siptype === '3';
    
    const name = 
      dataKey === 'itemtype' ||  // 類別
      dataKey === 'insptype'   // 檢驗要點

    return siptype && name
  }

  // 改變驗收型態時執行
  const changeInsptype = (value) => {
    // set_s_insptype(value.value);
    form.setFieldsValue({
      drawingno: null,
      insptype: value.value
    });
    set_s_insptype(value.value);
  }

  // 取得欄位使用的 input
  const getInput = (dataKey, record) => {
    
    if(dataKey === 'insptype') {
      return (
        <Select placeholder="選擇驗收型態" onChange={(e, value) => changeInsptype(value)} disabled={isDisabled(dataKey)}>
          <Select.Option value='計數型'>計數型</Select.Option>
          <Select.Option value='計量型'>計量型</Select.Option>
        </Select>
      )
    } 
    else if (dataKey === 'drawingno') {
      // if(s_insptype === '計量型') {
      if(form.getFieldValue('insptype') === '計量型') {
        // alert(form.getFieldValue('insptype'))
        return (
          <Select placeholder="選擇途號" disabled={isDisabled(dataKey)}>
            {c_drawingno.length > 0 && c_drawingno.map((item, index) => {
              return <Select.Option key={index} value={item.itemID}>{item.tagNM}</Select.Option>
            })}
          </Select>
        )
      } else {
        return <Input disabled={isDisabled(dataKey)} />
      }
    } 
    else if (dataKey === 'itemtype') {
      return (
        <Select placeholder="選擇類別" disabled={isDisabled(dataKey)}>
          <Select.Option value='一般項目'>一般項目</Select.Option>
          <Select.Option value='重要項目'>重要項目</Select.Option>
          <Select.Option value='監控項目'>監控項目</Select.Option>
        </Select>
      )
    } else {
      return <Input disabled={isDisabled(dataKey)} />
    }
  }

  // 決定編輯模式跟唯讀模式顯示的東西
  const EditableCell = ({editing, dataIndex, title, inputType, record, index, dataKey, children, ...restProps
  }) => {
    
    // 閱讀模式時顯示的東西
    const showValue = () => {
      try {
        if(dataKey === 'drawingno') {
          if(record.insptype === "計量型") {
            return c_drawingno.find(item => item.itemID === record.drawingno).tagNM
          }
        } else {
          return children
        }
      } catch (e) {
        console.log('error ===============', e)
        return children
      }
    };

    const getRule = () => {
      return [{required: true,message: `Please Input ${title}!`,}]
    }

    return (
      <td {...restProps}>
        {editing === 'true' ? (
          <Form.Item 
            name={dataKey}
            //rules={getRule()}
            // rules={[{required: true,message: `Please Input ${title}!`,},]}
          >
            {getInput(dataKey, record)}
          </Form.Item>
        ) : (
          showValue()
        )}
      </td>
    );
  };

  const submit = async () => {
    
    const newData = data.map((item, index) => {
      return {
        ...item,
        sipID: c_lightData.sipID,
        spotID: c_lightData.spotID,
        version: c_lightData.version,
        value: parseFloat(item.value),
        USL: parseFloat(item.USL),
        UCL: parseFloat(item.UCL),
        LSL: parseFloat(item.LSL),
        LCL: parseFloat(item.LCL),
      }
    })
    console.log('要給後端的資料 = ', newData)

    const res = await modifySiptb(newData);
    console.log('res = ', res)
    res.status ? message.success(res.msg) : message.error(res.msg);
    set_s_showItemModal(false)
  }
  
  return (
    <Wrapper className={className}>
      <Form form={form} component={false}>
        <Row>
          <Col span={24}>
            <CustomTable
              pagination={false}
              scroll={{ x: 'max-content',y: 400 }}
              dataSource={data}
              columns={mergedColumns}
              rowKey="index"
              rowClassName="editable-row"
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                  cell: EditableCell,
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                <Button disabled={editingKey !== ''} style={{borderRadius: '5px'}} size='large' type="primary" onClick={submit}>
                  確定修改
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      
    </Wrapper>
  )
};

export default ItemModalContainer;