import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { Form, Button, Row, Col, Input, InputNumber, message, Select, Divider } from 'antd'
import { MenuOutlined } from "@ant-design/icons";

import { getAllSiptype, addSiptb } from '../../../../services/api/sipAPI';

import { DataContext } from '../../index';

const CustomForm = styled(Form)`
  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
  .flexEnd {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .mb-0 {
    margin-bottom: 0px;
  }

  .ml30 {
    margin-left: 30px;
  }

  .wpcWrapper {
    max-height: 140px;
    overflow: auto
  }

`

const CreateSIPItem = ({set_s_showModal}) => {

  const [form] = Form.useForm();

  const [ s_drawingno, set_s_drawingno ] = useState([]);  // 監控參數

  const [ s_isEditUCL, set_s_isEditUCL ] = useState(true);  // 是否可以編輯『管制上限』
  const [ s_isEditLCL, set_s_isEditLCL ] = useState(true);  // 是否可以編輯『管制下限』
  const [ s_insptype, set_s_insptype ] = useState('')

  const { c_lightData } = useContext(DataContext);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  // 確認送出
  const onSubmit = values => {
    // 新增
    const call_addSiptb = async values => {
      // 新增時要給版碼跟sipID
      let tmpValues = {
        ...values,
        spotID: c_lightData.spotID,
        sipID: c_lightData.sipID,
        version: c_lightData.version,
        value: parseFloat(values.value),
        USL: parseFloat(values.USL),
        LSL: parseFloat(values.LSL),
        UCL: parseFloat(values.UCL),
        LCL: parseFloat(values.LCL),
      }
      console.log('要傳給後端的資料 = ', tmpValues)

      const res = await addSiptb(tmpValues);
      res.status === true ? message.success(res.msg) : message.error(res.msg);
      set_s_showModal(false)
    }

    call_addSiptb(values)
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  // 取得途號下拉選單
  useEffect(() => {
    const call_getAllSiptype = async () => {
      try {
        const res = await getAllSiptype();
        console.log('全部監控參數 = ', res)
        if(res.status === true) {
          set_s_drawingno(res.data);
        } else {
          set_s_drawingno([]);
        }
      } catch (e) {
        console.log('-------- call_getAllSiptype --------');
        set_s_drawingno([]);
      }
    }
    call_getAllSiptype();
  }, []);

  console.log('c_lightData = ', c_lightData)

  const textArea = {
    resize: 'none',
    fontSize: '35px',
    fontWeight: 'bold',
    textAlign: 'center',
    height: '120px',
    lineHeight: '110px'
  }

  return (
    <>
      <Form.Provider
        onFormChange={(formName, info) => {
          const { changedFields } = info;
          console.log(changedFields)
          
          // 有輸入規格上限才可編輯管制上限
          // if(changedFields[0] && changedFields[0].name[0] === 'USL') {
            
          //   if(changedFields[0].value) {
          //     set_s_isEditUCL(false);
          //     form.setFieldsValue({
          //       'UCL': changedFields[0].value
          //     });
          //   } else {
          //     set_s_isEditUCL(true);
          //     form.setFieldsValue({
          //       'UCL': null
          //     });
          //   }
          // }
          
          // 有輸入規格下限才可編輯管制下限
          // if(changedFields[0] && changedFields[0].name[0] === 'LSL') {
            
          //   if(changedFields[0].value) {
          //     set_s_isEditLCL(false);
          //     form.setFieldsValue({
          //       'LCL': changedFields[0].value
          //     });
          //   } else {
          //     set_s_isEditLCL(true);
          //     form.setFieldsValue({
          //       'LCL': null
          //     });
          //   }
          // }

          // 判斷圖號要顯示下拉選單還是文字輸入框 
          if(changedFields[0] && changedFields[0].name[0] === 'insptype') {

            // 切換檢驗要點時要清空途號
            form.setFieldsValue({
              'drawingno': null
            });

            if(changedFields[0].value === '計量型') {
              set_s_insptype('計量型');
            } else if (changedFields[0].value === '計數型') {
              set_s_insptype('計數型');
            } else {
              set_s_insptype(changedFields[0].value);
            }
          }

          // 切換檢驗要點時清空資料
          if(changedFields[0] && changedFields[0].name[0] === 'insptype') {
            form.setFieldsValue({
              'USL': null,
              'UCL': null,
              'LSL': null,
              'LCL': null,
            });
          }
          
        }}
      >
        <CustomForm
          onFinish={onSubmit}
          name="basic"
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            'sipitem': c_lightData ? c_lightData.siptype === '3' ? '1' : '' : '',
            'itemtype': c_lightData ? c_lightData.siptype === '3' ? '監控項目' : null : null,
            'insptype': c_lightData ? c_lightData.siptype === '3' ? '計量型' : null : null,
          }}
        >
          <Row gutter={[24, 24]}>
            
            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>檢驗項目</label>}
                name="sipitem"
                rules={[{ required: true, message: '請輸入檢驗項目!' }]}
              >
                <Input
                  placeholder="輸入檢驗項目" 
                  // disabled={c_lightData.siptype === '3' ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>檢驗類別</label>}
                name="itemtype"
                rules={[{ required: true, message: '請選擇類別!' }]}
              >
                <Select placeholder="選擇類別" allowClear disabled={c_lightData.siptype === '3' ? true : false}>
                  <Select.Option value='一般項目'>一般項目</Select.Option>
                  <Select.Option value='重要項目'>重要項目</Select.Option>
                  <Select.Option value='監控項目'>監控項目</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>檢驗型態</label>}
                name="insptype"
                rules={[{ required: true, message: '請選擇檢驗型態!' }]}
              >
                <Select placeholder="選擇檢驗型態" disabled={c_lightData.siptype === '3' ? true : false}>
                  <Select.Option value='計數型'>計數型</Select.Option>
                  <Select.Option value='計量型'>計量型</Select.Option>
                </Select>
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[24, 24]}>
            {form.getFieldValue('insptype') === '計數型' ? (
              <Col span={8}>
                <Form.Item 
                  label={<label style={{color: '#6C6C6C'}}>檢驗要點</label>}
                  name="inspnote"
                  rules={[{ required: true, message: '請輸入檢驗要點!' }]}
                >
                  <Input placeholder="輸入檢驗要點" />
                </Form.Item>            
              </Col>
            ) : null}

            {form.getFieldValue('insptype') === '計量型' ? (
              <Col span={8}>
                <Form.Item
                  label={<label style={{color: '#6C6C6C'}}>監控參數</label>}
                  name='drawingno'
                  rules={c_lightData.siptype === "3" ? (
                    [{ required: true, message: '請選擇監控參數!' }]
                  ) : null}
                >
                  {form.getFieldValue('insptype') === '計量型' ? (
                    <Select 
                      placeholder="選擇監控參數"
                      allowClear
                      disabled={c_lightData.siptype === "4" ? true : false}
                    >
                      {s_drawingno.length > 0 && s_drawingno.map((item, index) => {
                        return <Select.Option key={index} value={item.itemID}>{item.tagNM}</Select.Option>
                      })}
                    </Select>
                  ) : (
                    <Input placeholder="輸入監控參數" />
                  )}
                </Form.Item>
              </Col>
            ) : null}
          </Row>

          {form.getFieldValue('insptype') === '計量型' ? <Divider style={{margin: '12px'}} /> : null}
          
          <Row gutter={[24, 24]}>
            {form.getFieldValue('insptype') === '計量型' ? (
              <Col span={8}>
                <Form.Item 
                  label={<label style={{color: '#6C6C6C'}}>標準值</label>}
                  name="value"
                >
                  <Input.TextArea style={textArea} rows={2} placeholder="輸入標準值" />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={16}>
              {/* 規格上限 */}
              <Row gutter={[24, 24]}>
                {form.getFieldValue('insptype') === '計量型' ? (
                  // <Row gutter={[24, 24]}>
                  <>
                    <Col span={12}>
                      <Form.Item 
                      style={{marginBottom: '12px'}}
                        label={<label style={{color: '#6C6C6C'}}>規格上限</label>}
                        name="USL"
                        rules={[{ required: true, message: '請輸入規格上限!' }]}
                      >
                        <InputNumber style={{width: '100%'}} placeholder="輸入規格上限" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item 
                        label={<label style={{color: '#6C6C6C'}}>管制上限</label>}
                        name="UCL"
                        rules={[{ required: true, message: '請輸入管制上限!' }]}
                        // rules={[
                        //   { required: true, message: '請輸入管制上限!' },
                        //   ({ getFieldValue }) => ({
                        //     validator(_, value) {
                        //       // 管制上限不可超過規格上限
                        //       const USL = form.getFieldValue('USL');
                        //       if (value > USL) {
                        //         return Promise.reject(new Error('數值不可超過管制上限!'));
                        //       } else {
                        //         return Promise.resolve();
                        //       }
                        //     },
                        //   })
                        // ]}
                      >
                        {/* <InputNumber disabled={s_isEditUCL} style={{width: '100%'}} placeholder="輸入管制上限" /> */}
                        <InputNumber style={{width: '100%'}} placeholder="輸入管制上限" />
                      </Form.Item>
                    </Col>
                  </>      
                  // </Row>
                ) : null}
              </Row>
              
              
              {/* 規格下限 */}
              <Row gutter={[24, 24]}>
                
                

              {form.getFieldValue('insptype') === '計量型' ? (
                <>
                  <Col span={12}>
                    <Form.Item 
                      label={<label style={{color: '#6C6C6C'}}>規格下限</label>}
                      name="LSL"
                      rules={[{ required: true, message: '請輸入規格下限!' },]}
                    >
                      <InputNumber style={{width: '100%'}} placeholder="輸入規格下限" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label={<label style={{color: '#6C6C6C'}}>管制下限</label>}
                      name="LCL"
                      rules={[{ required: true, message: '請輸入管制下限!' }]}
                      // rules={[
                      //   { required: true, message: '請輸入管制下限!' },
                      //   ({ getFieldValue }) => ({
                      //     validator(_, value) {
                      //       // 管制上限不可超過規格上限
                      //       const LSL = form.getFieldValue('LSL');
                      //       if (value < LSL) {
                      //         return Promise.reject(new Error('數值不可低於管制下限!'));
                      //       } else {
                      //         return Promise.resolve();
                      //       }
                      //     },
                      //   })
                      // ]}
                    >
                      {/* <InputNumber disabled={s_isEditLCL} style={{width: '100%'}} placeholder="輸入管制下限" /> */}
                      <InputNumber style={{width: '100%'}} placeholder="輸入管制下限" />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              </Row>
            </Col>
          </Row>
          
          {form.getFieldValue('insptype') === '計量型' ? <Divider style={{margin: '12px'}} /> : null}

          <Row gutter={[24, 24]}>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>檢測單位</label>}
                name="unit"
                // rules={[{ required: true, message: '請輸入計量單位!' }]}
              >
                <Input placeholder="輸入檢測單位" />
              </Form.Item>            
            </Col>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>檢測環境</label>}
                name="sipcond"
                // rules={[{ required: true, message: '請輸入檢測環境!' }]}
              >
                <Input placeholder="輸入檢測環境" />
              </Form.Item>            
            </Col>

            <Col span={8}>
              <Form.Item 
                label={<label style={{color: '#6C6C6C'}}>允收水準AQL</label>}
                name="AQL"
                // rules={[{ required: true, message: '請輸入品質水準!' }]}
              >
                <Input placeholder="輸入AQL" />
              </Form.Item>            
            </Col>

          </Row>

          <Row gutter={[24, 24]}>
            
            <Col span={24}>
              <Form.Item
                label={<label style={{color: '#6C6C6C'}}>備註</label>}
                name="note"
              >
                <Input.TextArea name='note' rows={3} />
              </Form.Item>
            </Col>

          </Row>

          <Row gutter={[24, 24]}>
            <Col offset={12} span={12}>
              <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                  取消
                </Button>
                <Form.Item>
                  <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                    確定
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>

        </CustomForm>
      </Form.Provider>
    </>
  )

};

export default CreateSIPItem;
