import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得製程分析資料 ----------------------------------------
export const analysis = (data) => {
  console.log(data)
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/analysis`, {params: data});
};

//----------------------------------------- 取得製程分析資料 ----------------------------------------
// export const getNgItem = (data) => {
//   return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/analysis`, {params: data});
// };