import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import SIP from "../pages/SIP";
import SIP_BK from "../pages/SIP_BK";
import SOP from "../pages/SOP";
import Users from "../pages/Users";
import SystemLogin from "../pages/SystemLogin";
import WosPermissions from "../pages/WosPermissions";
import Scada from "../pages/Scada";
import BasicProductInfo from "../pages/BasicProductInfo";
import ProductWpc from "../pages/ProductWpc";
import AbcBoard from "../pages/AbcBoard";
import Trends from "../pages/Trends";
import StopProject from "../pages/StopProject";
import BadProject from "../pages/BadProject";
import GoodProject from "../pages/GoodProject";
import FunctionProject from "../pages/FunctionProject";
import FunctionString from "../pages/FunctionString";
import BatchQuery from "../pages/BatchQuery";
import QualityRecordSearch from "../pages/QualityRecordSearch";

const Routes = () => {
  let location = useLocation();

  return (
    <React.Fragment>
      <Switch location={location}>
        <Route path="/sip" component={SIP} />
        <Route path="/sip_bk" component={SIP_BK} />
        <Route path="/sop" component={SOP} />

        <Route path="/Users" component={Users} />
        <Route path="/SystemLogin" component={SystemLogin} />
        <Route path="/WosPermissions" component={WosPermissions} />

        <Route path="/Scada" component={Scada} />

        <Route path="/BasicProductInfo" component={BasicProductInfo} />
        <Route path="/ProductWpc" component={ProductWpc} />

        <Route path="/BatchQuery" component={BatchQuery} />

        <Route path="/AbcBoard" component={AbcBoard} />

        <Route path="/Trends" component={Trends} />

        <Route path="/StopProject" component={StopProject} />
        <Route path="/BadProject" component={BadProject} />
        <Route path="/GoodProject" component={GoodProject} />
        <Route path="/FunctionProject" component={FunctionProject} />
        <Route path="/quality-record-search" component={QualityRecordSearch} />

        <Route path="/FunctionString" component={FunctionString} />

        <Redirect exact from="*" to="/Scada" />

        {/* <Route path="/sop_bk" component={SOP_BK} /> */}
        {/* <Redirect exact from="/" to="/about" /> */}
      </Switch>
    </React.Fragment>
  );
};
export default Routes;
