import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//----------------------------------------- 取得全部停工資料 ----------------------------------------
export const getStopItem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStopItem`, {params: data});
};

//----------------------------------------- 刪除停工資料 ----------------------------------------
export const deleteStopItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStopItem`, data);
};

//----------------------------------------- 新增停工資料 ----------------------------------------
export const addStopItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStopItem`, data);
};

//----------------------------------------- 修改停工資料 ----------------------------------------
export const modifyStopItem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStopItem`, data);
};