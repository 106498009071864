import React, { useState } from "react";

import HD from './HD';
import TB from './TB';

export const DataContext = React.createContext();

const SIP = () => {
  
  const [ c_lightData, set_c_lightData ] = useState({});   // 要亮起來的資料

  return (
    <DataContext.Provider value={{c_lightData, set_c_lightData}}>
      <div style={{height: 'calc(100vh - 64px)'}}> {/* 64px => header的高度 */}
        <HD 
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
        />
        {Object.keys(c_lightData).length > 0 ? <TB /> : null}
      </div>
    </DataContext.Provider>
  );
};

export default SIP;