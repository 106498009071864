import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

// /getAllSophd 取得所有SOP表頭
export const getAllSophd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllSophd`, { params: _params });
};

// addSophd 新增SOP表頭
export const addSophd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addSophd`,
    data
  );
};

// modifySophd 編輯SOP表頭
export const modifySophd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifySophd`, data);
};

// /deleteSop 刪除SOP表頭
export const deleteSop = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteSophd`, data);
};

// 表身 get
export const getAllSoptb = (_params) => {
  console.log('_params = ', _params)
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllSoptb`, { params: _params });
};

// 表身 post
export const modifySoptb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifySoptb`, data);
};
