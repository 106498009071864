import React, { useEffect, useContext } from 'react';
import { DataContext } from '../index';
import styled from 'styled-components';
import { Form, Button, Row, Col, Input, message } from 'antd';
import { addPS, modifyPS } from '../../../services/api/PSData';

message.config({
  duration: 1.5
})

const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = () => {

  const { set_c_showModal, c_editData, set_c_editData } = useContext(DataContext);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
  }, [form, c_editData]);

  useEffect(() => {

    return () => set_c_editData({});

  }, []);

  const onSubmit = async values => {

    const type = Object.keys(c_editData).length === 0 ? 'create' : 'modify';

    if(type === 'create') {
      // 新增車間資料
      const res = await addPS(values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_c_showModal(false);
    };

    if(type === 'modify') {
      // 修改車間資料
      const res = await modifyPS(values.psID, values);
      res.status ? message.success(res.msg) : message.error(res.msg);
      set_c_showModal(false);
    };
    
  }

  const handleCancel = () => {
    set_c_showModal(false)
  }

  return (
    <>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          psID: Object.keys(c_editData).length > 0 ? c_editData.psID : '',
          psNM: Object.keys(c_editData).length > 0 ? c_editData.psNM : '', 
          psNotes: Object.keys(c_editData).length > 0 ? c_editData.psNotes : '', 
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item 
              //labelCol={{offset:2}} 
              wrapperCol={{span:22}}
              label={<label style={{color: '#6C6C6C'}}>車間ID</label>}
              name="psID"
              rules={Object.keys(c_editData).length === 0 ? [{ required: true, message: '請填寫車間ID!' }] : null}
            >
                <Input disabled={Object.keys(c_editData).length === 0 ? false : true} name='psID' placeholder="請填寫車間ID" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:22, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>車間名稱</label>}
              name="psNM"
              rules={[{ required: true, message: '請填寫車間名稱!' }]}
            >
              <Input name='psNM' placeholder="請填寫車間名稱" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} >
            <Form.Item 
              // labelCol={{offset:2}} 
              wrapperCol={{span:24}}
              label={<label style={{color: '#6C6C6C'}}>車間註記</label>}
              // rules={[{ required: true, message: '請填寫車間註記!' }]}
              name="psNotes"
            >
              <Input.TextArea 
                rows={4} 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </>
  )
};
export default ModalContainer