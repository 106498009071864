import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

export const getAllRun81 = (_params) => {
  return instance.get(
    `${apiBasic.conn}://${apiBasic.url}/api/scada/getAllRun81`,
    { params: _params }
  );
};

export const feedRun81 = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/scada/feedRun81`,
    data
  );
};

export const checkRun81 = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/scada/checkRun81`,
    data
  );
};
