const apiBasic = {
  // 自己
  // url: "localhost:7352",
  // conn: "http",

  // 自己
  // url: "192.168.0.104:7352",
  // conn: "http",

  // 屏東-律勝
  url: "pds.mc.sat.com.tw",
  conn: "https",
};

export default apiBasic;
