import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

//-----------------------------------------異常看版----------------------------------------
export const getAllAbc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/abc/getAllAbc`, {
    params: _params,
  });
};

export const getAbcSelect = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/abc/getAbcSelect`);
};

export const judge = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/abc/judge`, data);
};