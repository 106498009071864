import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { addWosPerm, modifyWosPerm } from '../../../services/api/WosPermissions';
import { getAllStaff } from '../../../services/api/Users';

import { Form, Button, Row, Col, Input, Select, Radio, Divider, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

message.config({
  duration: 1.5
})

const Wrapper = styled.div`
  width: 100%;
`
const StyledForm = styled(Form)`
  
  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }

  .center {
    display: flex;
    justify-content: flex-start
  }

  .flexEnd {
    display: flex;
    justify-content: flex-end;
  }

  .mb12 > div {
    margin-bottom: 12px
  }
`;
const ModalContainer = ({s_wosData, set_s_wosData, set_s_showModal}) => {

  const [form] = Form.useForm();
  const [s_AllStaff, set_s_AllStaff] = useState([]); // 全部員工基本資料

  useEffect(() => {
    // form.resetFields()
    // console.log('s_wosData = ', s_wosData)
  }, [form, s_wosData]);

  //取得全部員工基本資料(做下拉選單用)
  useEffect(() => {
    const call_getAllStaff = async () => {
      try {
        //取得所有員工資料
        const res = await getAllStaff();
        if(res.status && res.data.length > 0) {
          const newData = res.data.filter(item => {
            return item.quitTString === ''
          });
          set_s_AllStaff(newData)
        }
        //console.log('res.data = ', res.data)
        
      } catch (e) {
        console.log("全部員工基本資料-資料獲取失敗", e);
      } 
    };
    call_getAllStaff(); //------------------ API 先關閉 ------------------
  }, []);

  const onSubmit = async (values) => {
    const type = Object.keys(s_wosData).length === 0 ? 'create' : 'modify';
    const tmpValues = {...values};
    Object.entries(values).forEach(([key, value]) => (value && key !== 'staffpwd') ? value : '2')
    console.log('準備送給後端建立的資料 = ',values)

    if(type === 'create') { // 建立現場權限
      const res = await addWosPerm(values);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      set_s_showModal(false)
    }
    if(type === 'modify') { // 修改
      const res = await modifyWosPerm(values);
      if(res.status === true) {
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }
      set_s_showModal(false)
    }
  }

  const handleCancel = () => {
    set_s_showModal(false)
  }

  // 據此條目創建
  const createNew = () => {

    const template = {...s_wosData};
    // 先清空被編輯的資料
    set_s_wosData({});

    // 根據表單不同塞資料，不要的都塞null
    form.setFieldsValue({
      'peopleNM': null,
      'account': null,
      'wosID': null,
      'staffpwd': null,
    })
  }

  return (
    <>
      <Form.Provider
        
        onFormChange={(formName, info) => {
          const { changedFields } = info;
          // 如果切換員工姓名, 要切換員工工號(帳號)
          if(changedFields.length > 0 && changedFields[0].name[0] === 'peopleNM') {
            //console.log('here')
            const obj = s_AllStaff.find(item => item.peopleNM === changedFields[0].value);
            //console.log('obj = ', obj)
            form.setFieldsValue({
              'account': obj ? obj.staffID : null,
              'wosID': obj ? obj.staffID : null,
            });
          }
        }}
      >
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={{
          peopleNM: s_wosData ? s_wosData.peopleNM : null,
          account: s_wosData ? s_wosData.account ? s_wosData.account : s_wosData.pdsID  : '', 
          wosID: s_wosData ? s_wosData.wosID : null, // key 不可修改
          //staffpwd: Object.keys(s_wosData).length > 0 ? s_wosData.staffpwd : null,
          wocWo: Object.keys(s_wosData).length > 0 ? s_wosData.wocWo : '0',
          wocSys: Object.keys(s_wosData).length > 0 ? s_wosData.wocSys : '0',
          sfcWo: Object.keys(s_wosData).length > 0 ? s_wosData.sfcWo : '0',
          sfcMc: Object.keys(s_wosData).length > 0 ? s_wosData.sfcMc : '0',
          sfcQc: Object.keys(s_wosData).length > 0 ? s_wosData.sfcQc : '0',
          sfcNg: Object.keys(s_wosData).length> 0 ? s_wosData.sfcNg : '2',//
          sfcMrb: Object.keys(s_wosData).length > 0 ? s_wosData.sfcMrb : '2',
          wocNgJudge: Object.keys(s_wosData).length > 0 ? s_wosData.wocNgJudge : '2',
          wocAbnRpt: Object.keys(s_wosData).length > 0 ? s_wosData.wocAbnRpt : '2',
          
        }}
      >

        <Row style={{marginBottom: '12px', display: 'none'}}>
          <Col span={8}>
            <Row>
              <Col offset={2}>
                <Button disabled size='large' style={{borderRadius: '5px'}} onClick={() => alert('施工中')}>
                  <LeftOutlined />
                  上一筆
                </Button>
              </Col>
            </Row>
          </Col>
          <Col offset={8} span={8}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button size='large' style={{borderRadius: '5px', marginRight: '30px'}} onClick={() => alert('施工中')}>
                下一筆
                <RightOutlined />
              </Button>
            </div>

          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{color: '#6C6C6C'}}>員工姓名</label>}
              name="peopleNM"
            >
              {Object.keys(s_wosData).length > 0 ? (
                <Input disabled name='peopleNM' />
              ) : (
                <Select 
                  //disabled={Object.keys(s_staffData).length === 0 ? false : true}
                  placeholder="選擇員工姓名" 
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                {s_AllStaff.length > 0 && s_AllStaff.map(item => {
                  return <Select.Option key={item.staffID} value={item.peopleNM}>{item.peopleNM}</Select.Option>
                })}
              </Select>
              )}
              
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>帳號</label>}
              name="account"
            >
              <Input name='account' placeholder="請填寫員工帳號" />
            </Form.Item>
            <Form.Item  // 影藏欄位，用來讓後端當 uuid
              style={{display: 'none'}}
              labelCol={{span:4}} 
              wrapperCol={{span:16}} 
              label={<label>帳號</label>}
              name="wosID"
              rules={[{ required: true, message: '請輸入帳號!' }]}
            >
              <Input style={{display: 'none'}} name='wosID' />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}
              label={<label style={{color: '#6C6C6C'}}>{Object.keys(s_wosData).length === 0 ? '密碼' : '新密碼'}</label>}
              name="staffpwd" 
              rules={Object.keys(s_wosData).length === 0 ? [{ required: true, message: '請輸入密碼!' }] : null}
            >
              <Input name='staffpwd' placeholder={Object.keys(s_wosData).length === 0 ? "請輸入密碼" : "請輸入新密碼"} />
            </Form.Item>
          </Col>

        </Row>

        <Divider style={{margin: '6px'}} />

        <Row>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}  
              label={<label style={{color: '#6C6C6C'}}>報工作業</label>}
              name='wocWo'
            >
              <Radio.Group className="center" name="wocWo">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}   
              label={<label style={{color: '#6C6C6C'}}>報工系統設置</label>}
              name='wocSys'
            >
              <Radio.Group className="center" name="wocSys">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}  
              label={<label style={{color: '#6C6C6C'}}>工站作業權限</label>}
              name='sfcWo'
            >
              <Radio.Group className="center" name="sfcWo">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

        </Row>

        <Row>
          
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}  
              label={<label style={{color: '#6C6C6C'}}>過站-物控作業權限</label>}
              name='sfcMc'
            >
              <Radio.Group className="center" name="sfcMc">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}   
              label={<label style={{color: '#6C6C6C'}}>過站-質控作業權限</label>}
              name='sfcQc'
            >
              <Radio.Group className="center" name="sfcQc">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>過站-隔離處置權限</label>}
              name='sfcNg'
            >
              <Radio.Group disabled className="center" name="sfcNg">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

        </Row>

        <Row>
          
          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>過站-MRB處置權限</label>}
              name='sfcMrb'
            >
              <Radio.Group disabled className="center" name="sfcMrb">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}} 
              label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>隔離處置權限</label>}
              name='wocNgJudge'
            >
              <Radio.Group disabled className="center" name="wocNgJudge">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item 
              labelCol={{offset:2}} 
              wrapperCol={{span:20, offset:2}}  
              label={<label style={{ color: "rgba(0, 0, 0, 0.25)" }}>WOC異常提報權限</label>}
              name='wocAbnRpt'
            >
              <Radio.Group disabled className="center" name="wocAbnRpt">
                <Radio value='1'>啟用</Radio>
                <Radio value='0'>關閉</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={[24, 24]}>
          <Col span={18}>
            {Object.keys(s_wosData).length > 0 ? (
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={createNew}>
                據此創建新條目
              </Button>
            ) : null}
          </Col>
          <Col span={6}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{marginRight: '30px', borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>

      </StyledForm>
      </Form.Provider>
    </>
  )
};

export default ModalContainer;