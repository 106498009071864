import React, { useState, useContext } from 'react';
import { Modal } from 'antd';

import { DataContext } from '../index';

import { MySubtitleH4 } from '../../../styled/publicTag';
import { message } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { deletePS } from '../../../services/api/PSData';

import {
  IconTrash,
  IconPen
} from '../../../components/Icon';

import CustomTable from '../../../components/antdCustom/CustomTable';

message.config({
  duration: 1.5
})

const PSdata = () => {

  const innerWidth = window.innerWidth;

  const { call_getAllPS, set_c_editData, set_c_showModal, c_tableData } = useContext(DataContext);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  //編輯車間資料
  const editPS = rowData => {
    set_c_showModal(true);
    set_c_editData(rowData);
  }

  // 刪除車間資料
  const removePS = rowData => {
    Modal.confirm({
      title: '車間刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的車間無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const tmpObj = {
          'psID': rowData.psID
        }
        const res = await deletePS(tmpObj);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllPS();
      },
      cancelText: '取消',
    });
  }

  const tableColumns = [
    {
      title: '車間ID',
      dataIndex: 'psID',
      key: 'psID',
      align: 'center',
      // width: 100
    },
    {
      title: '名稱',
      dataIndex: 'psNM',
      key: 'psNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${400}px` : `${600}px`
    },
    {
      title: '車間註記',
      dataIndex: 'psNotes',
      key: 'psNotes',
      align: 'center',
      width: innerWidth <= 1440 ? `${400}px` : `${600}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          <IconPen className='icon' onClick={() => editPS(rowData)} />
          <IconTrash className='icon' onClick={() => removePS(rowData)} />
        </div>
      )
    },
  ]
  return (
    <CustomTable 
      scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
      columns={tableColumns}
      dataSource={c_tableData.map((item, index) => {
        return {
          key: index,
          ...item
        }
      })}
      pagination={{
        defaultCurrent: 1,
        total: pageInfo.totalItems,
        showSizeChanger: true,
        pageSize: pageInfo.pageSize,
        pageSizeOptions: ["10", "20", "30"],
        onChange: (current, size) => {
          setPageInfo({
            ...pageInfo,
            pageSize: size
          })
        },
        showTotal: (total, range) => (
          <MySubtitleH4>
            共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
          </MySubtitleH4>
        ),
      }}
    />
  )
}
export default PSdata;