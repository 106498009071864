import React, { useState, useContext } from 'react';
import { Modal } from 'antd';

import { DataContext } from '../index';

import { MySubtitleH4 } from '../../../styled/publicTag';
import { message } from 'antd';

import { ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleFilled } from '@ant-design/icons';
import { deleteStation } from '../../../services/api/Station';

import CustomTable from '../../../components/antdCustom/CustomTable';

import {
  IconTrash,
  IconPen
} from '../../../components/Icon'

message.config({
  duration: 1.5
})

const Station = () => {

  const innerWidth = window.innerWidth;

  const { call_getAllStation, set_c_editData, set_c_showModal, c_tableData } = useContext(DataContext);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  //編輯工站資料
  const editStation = rowData => {
    set_c_showModal(true);
    set_c_editData(rowData);
  }

  // 刪除工站資料
  const removeStation = rowData => {
    Modal.confirm({
      title: '工站刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的工站無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteStation(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllStation();
      },
      cancelText: '取消',
    });
  }
  
  const tableColumns = [
    {
      title: '工站ID',
      dataIndex: 'wsID',
      key: 'wsID',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${160}px`,
    },
    {
      title: '名稱',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      // width: innerWidth <= 1440 ? `${100}px` : `${160}px`,
    },
    {
      // title: '設定短單',
      title: '短單',
      dataIndex: 'set0wpc',
      key: 'set0wpc',
      width: innerWidth <= 1440 ? `${70}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set0wpc;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定短單',
      title: '設線',
      dataIndex: 'setline',
      key: 'setline',
      width: innerWidth <= 1440 ? `${70}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.setline;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定工段',
      title: '工段',
      dataIndex: 'set1gpc',
      key: 'set1gpc',
      width: innerWidth <= 1440 ? `${70}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set1gpc;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定模具同步',
      title: '模具同步',
      dataIndex: 'set2mold',
      key: 'set2mold',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set2mold;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定工時',
      title: '工時',
      dataIndex: 'set3whm',
      key: 'set3whm',
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set3whm;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定原料投料',
      title: '原料投料',
      dataIndex: 'set4feedlot',
      key: 'set4feedlot',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set4feedlot;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    // {
    //   title: '批號管理',
    //   dataIndex: 'setblotlife',  // ??????????
    //   key: 'bbb',
    //   // width: 100
    // },
    {
      // title: '設定條碼管理',
      title: '條碼管理',
      dataIndex: 'set5pid',
      key: 'set5pid',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set5pid;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定輔線',
      title: '輔線',
      dataIndex: 'set6alm',
      key: 'set6alm',
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set6alm;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定淨重劑量',
      title: '淨重劑量',
      dataIndex: 'set7netwt',
      key: 'set7netwt',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set7netwt;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }

    },
    {
      // title: '設定SOP監控',
      title: 'SOP監控',
      dataIndex: 'set8sop',
      key: 'set8sop',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set8sop;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定SIP監控',
      title: 'SIP監控',
      dataIndex: 'set9sip',
      key: 'set9sip',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.set9sip;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      // title: '設定配方',
      title: '配方',
      dataIndex: 'setarpm',
      key: 'setarpm',
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      align: 'center',
      render: (text, rowData, index) => {
        const key = rowData.setarpm;
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div className="checkIcon">
             {key === 'N' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === 'Y' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#d2f8d2' }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'space-around'}}>
              <div style={{display: 'flex'}}>
                <IconPen className='icon' onClick={() => editStation(rowData)} />
                <IconTrash className='icon' onClick={() => removeStation(rowData)} />
              </div>
            </div>
          )
        }
      }
    },
  ]
  return (
    <CustomTable 
      scroll={{x: 'max-content', y: window.innerHeight - 60 - 45 - 220 }}
      columns={tableColumns}
      dataSource={c_tableData.map((item, index) => {
        return {
          key: index,
          ...item
        }
      })}
      pagination={{
        defaultCurrent: 1,
        total: pageInfo.totalItems,
        showSizeChanger: true,
        pageSize: pageInfo.pageSize,
        pageSizeOptions: ["10", "20", "30"],
        onChange: (current, size) => {
          setPageInfo({
            ...pageInfo,
            pageSize: size
          })
        },
        showTotal: (total, range) => (
          <MySubtitleH4>
            共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
          </MySubtitleH4>
        ),
      }}
    />
  )
}
export default Station;