import React, { useState, useEffect, useContext } from 'react';

import { message, Button } from 'antd';

import { MySubtitleH4 } from '../../../styled/publicTag';

import { DataContext } from '../../SIP';
import useAPI from '../../../hooks/useApi';

import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';

import CreateSIPItem from './ModalContainer/CreateSIPItem';
import ItemModalContainer from './ModalContainer/ItemModalContainer';

import{ getAllSiptb } from "../../../services/api/sipAPI";

import { FullscreenOutlined, FullscreenExitOutlined, PlusOutlined } from '@ant-design/icons';

const TB = () => {

  const innerWidth = window.innerWidth;
  const call_getAllSiptb = useAPI(getAllSiptb);

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);            // 是否開啟 dialog
  const [s_showItemModal, set_s_showItemModal] = useState(false);    // 是否開啟 dialog

  const { c_lightData, set_c_lightData } = useContext(DataContext);

  const [scrollY, setScrollY] = useState("");

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  useEffect(() => {
    call_getAllSiptb.request({sipID: c_lightData.sipID, version: c_lightData.version, spotID: c_lightData.spotID});
  }, [c_lightData]);

  useEffect(() => {
    if(call_getAllSiptb.status === 'suc') {
      const tmpData = call_getAllSiptb.data.map((item, index) => {
        return {
          ...item,
          key: index
        }
      });
      set_s_tableData(tmpData)
    }
    if(call_getAllSiptb.status === 'err') {
      message.error(call_getAllSiptb.msg);
      set_s_tableData([]);
    }
  }, [call_getAllSiptb.status]);

  // 表身有資料、RWD 時計算高度
  useEffect(() => {
    const ROWHEIGHT = 40;
    // macbook 尺寸
    if(innerWidth >= 1440) {
      // alert(innerWidth)
      // 沒有表身資料
      setScrollY(ROWHEIGHT * 12);
    }
    
    // 一般螢幕尺寸
    if(innerWidth >= 1920) {
      // 沒有表身資料
      setScrollY(ROWHEIGHT * 4);
    }

  }, [innerWidth, c_lightData]);

  const tableColumns = [
    // {
    //   title: "sip編號",
    //   dataIndex: "sipID",
    //   key: "sipID",
    //   align: "center",
    //   // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    // },
    {
      title: "SIP項次",
      dataIndex: "sipno",
      key: "sipno",
      align: "center",
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
    },
    
    {
      title: "類別",
      dataIndex: "itemtype",
      key: "itemtype",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: "型態",
      dataIndex: "insptype",
      key: "insptype",
      align: "center",
      width: innerWidth <= 1440 ? `${70}px` : `${70}px`
    },
    {
      title: "檢驗項目",
      dataIndex: "sipitem",
      key: "sipitem",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: "檢驗要點",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "inspnote",
      key: "inspnote",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`
    },
    {
      title: "監控參數",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "drawingno",
      key: "drawingno",
      align: "center",
      // render: (text, rowData, index) => {
      //   const item = c_drawingno.find((item) => item.itemID === rowData.drawingno);
      //   return {
      //     props: {
      //       style: { textAlign: 'left' }
      //     },
      //     children: item ? item.tagNM : rowData.drawingno
      //   };
      // },
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: "標準值",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "value",
      key: "value",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "規格上限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "USL",
      key: "USL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "規格下限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "LSL",
      key: "LSL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "管制上限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "UCL",
      key: "UCL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "管制下限",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "LCL",
      key: "LCL",
      align: "center",
      width: innerWidth <= 1440 ? `${90}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "AQL",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "AQL",
      key: "AQL",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`
    },
    {
      title: "單位",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "unit",
      key: "unit",
      align: "center",
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`
    },
    {
      title: "檢測環境",
      // colSpan: c_lightData.soptype === 'B' ? 1 : null,
      dataIndex: "sipcond",
      key: "sipcond",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
    }
  ] 
  const title = `${Object.keys(c_lightData).length > 0 ? `檢驗項目明細檔__${c_lightData.sipID}` : ''}`;
  
  return (
    <div>
      <Title title={title} >
        <div>
          <Button 
            onClick={() => set_s_showItemModal(true)} 
            type="primary" 
            style={{borderRadius: '5px'}} 
            icon={<FullscreenOutlined />} 
            size='large'
          >
            SIP檢驗項目明細編輯
          </Button>
          <Button
            type='danger'
            size='large'
            style={{marginLeft: '10px', marginRight: '10px', borderRadius: '5px'}} 
            icon={<FullscreenExitOutlined />} 
            onClick={() => set_c_lightData({})} 
          >
            隱藏明細檔
          </Button>
          <Button 
            onClick={() => set_s_showModal(true)} 
            type="primary" 
            style={{borderRadius: '5px'}} 
            icon={<PlusOutlined />} 
            size='large'
          >
            新增
          </Button>
        </div>
      </Title>
      <CustomTable 
        scroll={{x: 'max-content', y: scrollY }}
        columns={tableColumns}
        size={innerWidth <= 1440 ? '14px' : null} // 表頭字體大小
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

        <CustomModal 
          width='60%' 
          title={(
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>新增SIP檢驗項目</span>
              <div>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>SIP文件編號:  {c_lightData.sipID}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>版碼：{c_lightData.version}</span>
              </div>
            </div>
          )}
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          forceRender
          footer={null}
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          pagination={null}
          >
          {s_showModal ? (
            <CreateSIPItem 
              s_tableData={s_tableData}
              set_s_tableData={set_s_tableData}
              set_s_showModal={set_s_showModal} 
              />
            ) : null}
        </CustomModal>

        <CustomModal
          width='100%' 
          title={(
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>SIP全局檢驗項目編輯</span>
              <div>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>SIP文件編號:  {c_lightData.sipID}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>版碼：{c_lightData.version}</span>
              </div>
            </div>
          )}
          visible={s_showItemModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showItemModal(false)}
          >
          {s_showItemModal ? (
            <ItemModalContainer 
              s_tableData={s_tableData}
              set_s_tableData={set_s_tableData} 
              set_s_showItemModal={set_s_showItemModal} 
            />
          ) : null}
        </CustomModal>
    </div>
  )
};

export default TB;