import React, { useState, useEffect, useContext, useRef } from 'react';

import { message } from 'antd';

import Header from './Header';

import { MySubtitleH4 } from '../../../../styled/publicTag';

import Title from '../../../../components/Custom/Title';
import CustomTable from '../../../../components/antdCustom/CustomTable';
import CustomModal from '../../../../components/antdCustom/CustomModal';

import { getAllSoptb } from "../../../../services/api/sopAPI";
import { DataContext } from '../../index';
import ItemModalContainer from './ItemModalContainer';

message.config({
  duration: 1.5
});

const ItemTable = () => {

  const innerWidth = window.innerWidth;
  let countRef = useRef(null);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);    // 是否開啟 dialog

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const [scrollY, setScrollY] = useState("")

  const { c_lightData, set_c_lightData } = useContext(DataContext);

  // 取得所有投料項目明細
  const call_getAllSoptb = async () => {
    try {
      //console.log(c_lightData)
      const res = await getAllSoptb({ sopID: c_lightData.sopID, version: c_lightData.version, spotID: c_lightData.spotID });
      console.log('res = ', res)
      if (res.data) {
        console.log('全部投料項目明細 = ', res.data);
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_s_tableData(tmpData)
      }

    } catch (e) {
      console.log('---------- api getAllSoptb error ----------');
      console.log(e)
    }
  } 

  const handleClick = () => {
    set_s_showModal(true)
  }

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  }

  // 計算 Table 的高度
  const getTableScroll = ({ extraHeight, ref } = {}) => {
    if (typeof extraHeight == "undefined") {
      //  默认底部分页64 + 边距10
      extraHeight = 74
    }
    let tHeader = null
    if (ref && ref.current) {
      tHeader = ref.current.getElementsByClassName("ant-table-thead")[0]
    } else {
      tHeader = document.getElementsByClassName("ant-table-thead")[0]
    }
    //表格内容距离顶部的距离
    let tHeaderBottom = 0
    if (tHeader) {
      tHeaderBottom = tHeader.getBoundingClientRect().bottom
    }
    // 窗体高度-表格内容顶部的高度-表格内容底部的高度
    // let height = document.body.clientHeight - tHeaderBottom - extraHeight
    let height = `calc(100vh - ${tHeaderBottom + extraHeight + 60}px)`
    // 空数据的时候表格高度保持不变,暂无数据提示文本图片居中
    if (ref && ref.current) {
      let placeholder = ref.current.getElementsByClassName('ant-table-placeholder')[0]
      if (placeholder) {
        placeholder.style.height = height
        placeholder.style.display = "flex"
        placeholder.style.alignItems = "center"
        placeholder.style.justifyContent = "center"
      }
    }
    return height
  }

  // 更新 c_lightData 重新抓資料
  useEffect(() => {
    if (Object.keys(c_lightData).length > 0) {
      call_getAllSoptb()
      setScrollY(getTableScroll({ ref: countRef }))
    } else {
      set_s_tableData([])
      setScrollY(getTableScroll({ ref: countRef }))
    }
  }, [c_lightData, s_showModal]);

  const tableColumns = c_lightData.soptype === 'A' ? (
    [
      {
        title: "SOP控程",
        dataIndex: "sopno",
        key: "sopno",
        align: "center",
        width: innerWidth <= 1440 ? `${80}px` : `${150}px`,
      },
      {
        title: "觸發序",
        dataIndex: "flagC",
        key: "flagC",
        align: "center",
        width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(231, 247, 231)' }
            },
            children: text
          };
        }
      },
      {
        title: "段數序",
        dataIndex: "stepC",
        key: "stepC",
        align: "center",
        width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(231, 247, 231)' }
            },
            children: text
          };
        }
      },
      {
        title: "投料序",
        dataIndex: "feedC",
        key: "feedC",
        align: "center",
        width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(231, 247, 231)' }
            },
            children: text
          };
        }
      },
      {
        title: "核對項目",
        dataIndex: "sopitem",
        key: "sopitem",
        align: "center",
        width: innerWidth <= 1440 ? `${100}px` : `${200}px`
      },
      {
        title: "投料",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck1",
        key: "setcheck1",
        align: "center",
        width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(214, 222, 239)' }
            },
            children: text
          };
        }
      },
      {
        title: "投量",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck2",
        key: "setcheck2",
        align: "center",
        width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(214, 222, 239)' }
            },
            children: text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          };
        }
      },
      {
        title: "投量上限",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck2UCL",
        key: "setcheck2UCL",
        align: "center",
        width: innerWidth <= 1440 ? `${120}px` : `${150}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(214, 222, 239)' }
            },
            children: text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          };
        }
      },
      {
        title: "投量下限",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck2LCL",
        key: "setcheck2LCL",
        align: "center",
        width: innerWidth <= 1440 ? `${120}px` : `${150}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(214, 222, 239)' }
            },
            children: text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          };
        }
      },
      {
        title: "時距(分鐘)",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck3",
        key: "setcheck3",
        align: "center",
        width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(226, 214, 239)' }
            },
            children: text
          };
        }
      },
      {
        title: "時距上限",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck3UCL",
        key: "setcheck3UCL",
        align: "center",
        width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(226, 214, 239)' }
            },
            children: text
          };
        }
      },
      {
        title: "時距下限",
        // colSpan: c_lightData.soptype === 'A' ? 1 : null,
        dataIndex: "setcheck3LCL",
        key: "setcheck3LCL",
        align: "center",
        width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
        render: (text, rowData, index) => {
          return {
            props: {
              style: { backgroundColor: 'rgb(226, 214, 239)' }
            },
            children: text
          };
        }
      },
      // {
      //   title: "公差",
      //   // colSpan: c_lightData.soptype === 'A' ? 1 : null,
      //   dataIndex: "setcheck2tol",
      //   key: "setcheck2tol",
      //   align: "center",
      //   width: innerWidth <= 1440 ? `${80}px` : `${130}px`
      // },
      // {
      //   title: "備註",
      //   dataIndex: "note",
      //   key: "note",
      //   align: "center",
      //   // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      // },
      {
        title: "更新時間",
        dataIndex: "newUpdtT",
        key: "newUpdtT",
        align: "center",
        width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
    ]
  ) : (
    [
      {
        title: "SOP控程",
        dataIndex: "sopno",
        key: "sopno",
        align: "center",
        // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
      },
      {
        title: "觸發序",
        dataIndex: "flagC",
        key: "flagC",
        align: "center",
        width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
      },
      {
        title: "段數序",
        dataIndex: "stepC",
        key: "stepC",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "投料序",
        dataIndex: "feedC",
        key: "feedC",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "核對項目",
        dataIndex: "sopitem",
        key: "sopitem",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "控制標準值",
        // colSpan: c_lightData.soptype === 'B' ? 1 : null,
        dataIndex: "value",
        key: "value",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "控制上限",
        // colSpan: c_lightData.soptype === 'B' ? 1 : null,
        dataIndex: "UCL",
        key: "UCL",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "控制下限",
        // colSpan: c_lightData.soptype === 'B' ? 1 : null,
        dataIndex: "LCL",
        key: "LCL",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "備註",
        dataIndex: "note",
        key: "note",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
      {
        title: "更新時間",
        dataIndex: "newUpdtT",
        key: "newUpdtT",
        align: "center",
        // width: innerWidth <= 1440 ? `${100}px` : `${150}px`
      },
    ]
  )
  const title = `${Object.keys(c_lightData).length > 0 ? `投料項目明細檔__${c_lightData.sopID}` : ''}`;

  const ModalTitle = `${Object.keys(c_lightData).length > 0 ? `${c_lightData.sopID}版碼：${c_lightData.version}` : ''}`;
  return (
    <div>

      <Title title={title} >
        <Header handleClick={handleClick} handleClose={handleClose} />
      </Title>

      <div ref={countRef}>
        <CustomTable
          size={innerWidth <= 1440 ? '16px' : null} // 表頭字體大小
          scroll={{ x: 'max-content', y: scrollY }}
          columns={tableColumns}
          dataSource={s_tableData.map((item, index) => {
            return {
              key: index,
              ...item
            }
          })}
          pagination={{
            defaultCurrent: 1,
            total: pageInfo.totalItems,
            // current: abcBoardConfig.page,
            showSizeChanger: true,
            pageSize: pageInfo.pageSize,
            pageSizeOptions: ["10", "20", "30"],
            onChange: (current, size) => {
              setPageInfo({
                ...pageInfo,
                pageSize: size
              })
            },
            showTotal: (total, range) => (
              <MySubtitleH4>
                共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
              </MySubtitleH4>
            ),
          }}
        />
      </div>

      {s_showModal ? (
        <CustomModal
          width='90%'
          // title='SOP投料全局項目編輯'
          title={(
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <span>SOP投料全局項目編輯</span>
              <div>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>{c_lightData.sopID}</span>
                <span style={{marginRight: '30px', color: 'rgb(167, 164, 164)' }}>版碼：{c_lightData.version}</span>
              </div>
            </div>
          )}
          visible={s_showModal}
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ItemModalContainer
            s_tableData={s_tableData}
            set_s_tableData={set_s_tableData}
            set_s_showModal={set_s_showModal}
          />
        </CustomModal>
      ) : null}

    </div>
  )


};

export default ItemTable;