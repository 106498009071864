import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { fontSize } from '../../../styled/publicTag';

import DragBox from './DragBox';

import { DataContext } from '../index';

const Wrapper = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 5px;
  /* flex-grow: 1; */
  
  .Header {
    font-size: ${fontSize.h4};
  }

  .Container {
    margin-top: 5px;
    /* padding: 5px 20px 5px 20px; */
    flex-grow: 1;
    width: 100%;
    overflow: auto;
  }
`
const Container = styled.div`
  padding: 5px 20px 5px 20px;
  height: 100%;
  ${({isDraggingOver}) => isDraggingOver && css`
    background-color: #cfe3fb;
  `}
`

const DragContainer = ({data, droppableId, Header}) => {

  const { c_all_param, set_c_drag } = useContext(DataContext);

  return (
    <Wrapper>
      <div className='Header'>{Header}</div>
      <div className='Container'>
        <Droppable droppableId={droppableId} type="TASK">
          {/* <div className='Container'> */}
            {(provided, snapshot) => {
              // console.log('provided = ', provided)
              return(
                <Container
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {data.map((item, index) => (
                    <Draggable
                      draggableId={item} // 
                      index={index}
                      key={item}
                      type="TASK"
                      // isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => {
                        // console.log('snapshot = ', snapshot)
                        // set_c_drag(snapshot.draggingOver)
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DragBox 
                              draggingOver={snapshot.draggingOver} 
                              isDragging={snapshot.isDragging} 
                              droppableId={droppableId}
                              itemID={item} 
                              itemNM={c_all_param[item]}
                            />
                          </div>
                        )
                      }}  
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Container>
              )
            }}
          {/* </div> */}
        </Droppable>
      </div> 
    </Wrapper>
  )
};

export default DragContainer;