import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Col } from 'antd';

import { trend } from '../../../../services/api/trend';

import { DataContext } from '../../index';

import { Chart, Point, View, Tooltip, Schema, Axis, Interaction } from 'bizcharts';

import { DataView } from '@antv/data-set';

const Container = styled.div`
  width: 100%;
  height: 240px;
  padding: 20px 5px 20px 5px;
  align-self: flex-start;
  margin-top: 20px;

  .title {
    color: ${({ theme }) => theme.Color3};
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 10px;
  }

`

const ChartContainer = ({ paramName }) => {

  const { c_searchData, c_all_param, Col_num } = useContext(DataContext);

  const [s_data, set_s_data] = useState([]);

  const [s_max, set_s_max] = useState(0); // Y軸上限

  // 計算兩個日期差幾分鐘
  const DateDiff = (sDate1, sDate2) => {
    let aDate, oDate1, oDate2, iDays
    aDate = sDate1.split("-")
    oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
    aDate = sDate2.split("-")
    oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
    iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60) // 把相差的毫秒數轉換為分鐘
    return iDays;
  };

  useEffect(() => {

    const obj = {
      column: paramName,
      wlID: c_searchData.wlID,
      freq: c_searchData.freq,
      start: c_searchData.start,
      end: c_searchData.end,
    };

    // 取得何須圖的所有資料
    const call_trend = async () => {

      const res = await trend(obj);

      if (res.status === true) {

        // 取得Y軸的上限
        const max = Math.max(...res.data.map(item => item.max));

        // console.table(res.data)

        // 取得搜尋日期的時間差，並除以搜尋分鐘
        const diffTime = DateDiff(c_searchData.start, c_searchData.end);

        const tmpArray = res.data.map((item, index) => {
          return {
            x: item.time,
            low: item.min,
            q1: item.q1,
            median: item.q2,
            q3: item.q3,
            high: item.max
          }
        })

        set_s_data(tmpArray);
        set_s_max(max);

      } else {
        set_s_data([]);
      }
      console.log('res = ', res)
    };

    call_trend();


  }, [paramName, c_searchData]);

  const datax = [
    // { x: 'Oceania', low: 1, q1: 9, median: 16, q3: 22, high: 24 },
    // { x: 'East Europe', low: 1, q1: 5, median: 8, q3: 12, high: 16 },
    // { x: 'Australia', low: 1, q1: 8, median: 12, q3: 19, high: 26 },
    // { x: 'South America', low: 2, q1: 8, median: 12, q3: 21, high: 28 },
    // { x: 'North Africa', low: 1, q1: 8, median: 14, q3: 18, high: 24 },
    // { x: 'North America', low: 3, q1: 10, median: 17, q3: 28, high: 30 },
    // { x: 'West Europe1', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe2', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe3', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe4', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe5', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe6', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe7', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe8', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe9', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Europe10', low: 1, q1: 7, median: 10, q3: 17, high: 22 },
    // { x: 'West Africa', low: 1, q1: 6, median: 8, q3: 13, high: 16 }
  ];
  const dv = new DataView().source(s_data);
  dv.transform({
    type: 'map',
    callback: obj => {
      obj.range = [obj.low, obj.q1, obj.median, obj.q3, obj.high];
      return obj;
    }
  });
  return (
    <Col span={Col_num}>
      <Container>
        <div className='title'>{c_all_param[paramName]}</div>
        <Chart
          height={200}
          width='95%'
          data={dv.rows}
          autoFit
          scale={{
            range: {
              max: s_max,
              nice: true
            }
          }}
        >
          <Tooltip
            showTitle={false}
            showMarkers={false}
            itemTpl={
              '<li class="g2-tooltip-list-item" data-index={index} style="margin-bottom:4px;padding: 5px">'
              + '<span style="background-color:{color};" class="g2-tooltip-marker"></span>'
              + '{name}<br/><br/>'
              + '<span style="padding-left: 16px">最大值：{high}</span><br/><br/>'
              + '<span style="padding-left: 16px">上四分位数：{q3}</span><br/><br/>'
              + '<span style="padding-left: 16px">中位数：{median}</span><br/><br/>'
              + '<span style="padding-left: 16px">下四分位数：{q1}</span><br/><br/>'
              + '<span style="padding-left: 16px">最小值：{low}</span><br/>'
              + '</li>'
            }
          />
          <Schema
            position={'x*range'}
            shape="box"
            // size={5}
            style={{
              stroke: '#ababab',
              fill: '#1890FF',
              fillOpacity: 0.2
            }}
            tooltip={[
              'x*low*q1*median*q3*high',
              (x, low, q1, median, q3, high) => {
                return {
                  name: x,
                  low,
                  q1,
                  median,
                  q3,
                  high
                };
              }
            ]}
          />
          <Interaction type={'active-region'} />
        </Chart>
      </Container>
    </Col>
  )
};

export default ChartContainer;