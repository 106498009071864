import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { DataContext } from '../index';
import { IconRightArrow, IconLeftArrow } from '../../../components/Icon';

const Wrapper = styled.div`
  width: 20%;
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  align-self: center;
`

const Pointer = ({is_Left}) => {

  const { c_drag } = useContext(DataContext);

  return (
    <Wrapper >
      {c_drag === 'display' || c_drag === '' ? (
        <IconRightArrow c_drag={c_drag} />
      ) : (
        <IconLeftArrow c_drag={c_drag} style={{color: c_drag ? '#cfe3fb' : ''}} />
      )}
    </Wrapper>
  )
};

export default Pointer;