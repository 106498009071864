import React, { useState, useEffect } from 'react';

import { Button, Input, message } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import Title from '../../components/Custom/Title';

import CustomModal from '../../components/antdCustom/CustomModal';
import CustomTabs from '../../components/antdCustom/CustomTabs';

// 車間相關資料
import PSdata from './PSdata/index';
import PSdataModal from './PSdata/ModalContainer';
import { getAllPS, getPS } from '../../services/api/PSData';

// 工站相關資料
import Station from './Station/index';
import StationModal from './Station/ModalContainer';
import { getAllStation, getStation } from '../../services/api/Station';

// 工線相關資料
import Line from './Line/index';
import LineModal from './Line/ModalContainer';
import { getAllLine, getLine } from '../../services/api/Line';

message.config({
  duration: 1.5
})

export const DataContext = React.createContext();

const FunctionProject = () => {

  const [ c_searchValue, set_c_searchValue ] = useState('');
  const [ c_editData, set_c_editData ] = useState({});

  const [ c_tableData, set_c_tableData ] = useState([]);

  const [ c_showModal, set_c_showModal ] = useState(false);  // 是否開啟 dialog
  const [ s_TabKey, set_s_TabKey ] = useState('PSdata');     // 目前是在哪個Tab上
  const [ s_placeholder, set_s_placeholder ] = useState('');

  const TabsData = [
    {
      'key': 'PSdata',
      'tabName': '車間資料'
    },
    {
      'key': 'Station',
      'tabName': '工站資料'
    },
    {
      'key': 'Line',
      'tabName': '工線資料'
    },
  ]

  const placeholder_Map = {
    'PSdata': '車間資料',
    'Station': '工站資料',
    'Line': '工線資料',
  }

  // 更改 Tab 時更新 placeholder、Modal title
  useEffect(() => {
    const placeholder = placeholder_Map[s_TabKey];
    set_s_placeholder(placeholder)
  
  }, [s_TabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  // 更改 Tab 時清空搜尋資料
  useEffect(() => {
    set_c_searchValue('');
  }, [s_TabKey]);

  // 更改 Tab 時重新取得 table 資料
  useEffect(() => {
    
    if(s_TabKey === 'PSdata') {
      call_getAllPS()
    };

    if(s_TabKey === 'Station') {
      call_getAllStation()
    };

    if(s_TabKey === 'Line') {
      call_getAllLine()
    }

  }, [s_TabKey, c_showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // 搜尋
  const onSearch = () => {
    
    if(s_TabKey === 'PSdata') {
      call_getAllPS()
    };

    if(s_TabKey === 'Station') {
      call_getAllStation()
    };

    if(s_TabKey === 'Line') {
      call_getAllLine()
    }
  }

  // 更改搜尋資料
  const handleChange = async event => {
    const value = event.target.value;
    set_c_searchValue(value);

    if(value === '') {
      if(s_TabKey === 'PSdata') {
        try {
          const res = await getAllPS();
          if(res.status === true) {
            const tmpData = res.data.map((item, index) => {
              return {
                ...item,
                key: index
              }
            });
            set_c_tableData(tmpData)
          } else {
            set_c_tableData([])
          }
          
        } catch (e) {
          console.log('---------- api getAllStaffPerm error ----------');
          console.log(e)
        }
      };
  
      if(s_TabKey === 'Station') {
        try {
          const res = await getAllStation();
          if(res.status === true) {
            console.log('全部工站資料 = ', res.data)
            set_c_tableData(res.data)
          } else {
            set_c_tableData([])
          }
        } catch (e) {
          console.log('---------- api getAllStaffPerm error ----------');
          console.log(e)
        }
      };
  
      if(s_TabKey === 'Line') {
        try {
          const res = await getAllLine();
          if(res.status === true) {
            console.log('全部工線資料 = ',res.data)
            set_c_tableData(res.data)
          } else {
            set_c_tableData([])
          }
          
        } catch (e) {
          console.log('---------- api getAllStaffPerm error ----------');
          console.log(e)
        }
      }
    }
  }

  // 取得所有模具資料
  const call_getAllPS = async () => {
    try {
      console.log('c_searchValue = ', c_searchValue)
      const res = c_searchValue === '' ? await getAllPS() : await getPS(c_searchValue);
      
      if(res.status === true) {
        const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        set_c_tableData(tmpData)
      } else {
        set_c_tableData([])
      }
      
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }

  // 取得所有工站資料
  const call_getAllStation = async () => {
    try {
      const res = c_searchValue === '' ? await getAllStation() : await getStation(c_searchValue);
      if(res.status === true) {
        
        console.log('全部工站資料 = ', res.data)
        set_c_tableData(res.data)
      } else {
        set_c_tableData([])
      }
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }

  // 取得所有工線資料
  const call_getAllLine = async () => {
    try {
      const res = c_searchValue === '' ? await getAllLine() : await getLine(c_searchValue);
      if(res.status === true) {
        
        console.log('全部工線資料 = ',res.data)
        set_c_tableData(res.data)
      } else {
        set_c_tableData([])
      }
      
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }

  // 建立
  const Create = () => {
    set_c_showModal(true);
  }

  return (
    <>
      <DataContext.Provider 
        value={{
          c_searchValue,
          set_c_searchValue,
          c_editData,
          set_c_editData,
          c_showModal,
          set_c_showModal,
          c_tableData,
          call_getAllPS,
          call_getAllStation,
          call_getAllLine
        }}
      >
        <Title title='技術資料維護'>
          <div style={{display: 'flex'}}>
            <Input.Search 
              className='Input'
              size='large' 
              enterButton
              onChange={handleChange}
              value={c_searchValue}
              placeholder={`請輸入欲查詢的${s_placeholder}資料`}
              onSearch={onSearch}
            />
            <Button 
              onClick={Create} 
              type="primary" 
              size='large'
              style={{borderRadius: '5px'}} 
              icon={<PlusOutlined />} 
            >
              新增
            </Button>
          </div>
        </Title>

        {/* 決定目前要顯示哪個page */}
        <CustomTabs 
          onChange={activeKey => set_s_TabKey(activeKey)}
          activeKey={s_TabKey}
          TabsData={TabsData}
        />

        {/* 根據 Tab 顯示 page */}
        {s_TabKey === 'PSdata' ? (
          <PSdata />
        ) : s_TabKey === 'Station' ? (
          <Station />
        ) : s_TabKey === 'Line' ? (
          <Line />
        ) : null}


        {c_showModal ? ( 
          <CustomModal 
            width='60%' 
            title={Object.keys(c_editData).length > 0 ? `修改${s_placeholder}資料` : `新建${s_placeholder}資料`} 
            visible={c_showModal} 
            maskClosable={false}
            destroyOnClose
            footer={null}
            forceRender
            getContainer={false}
            onCancel={() => set_c_showModal(false)}
            afterClose={() => {
            }}
          >
            {/* 根據 Tab 顯示 ModalContainer */}
            {s_TabKey === 'PSdata' ? (
              <PSdataModal />
            ) : s_TabKey === 'Station' ? (
              <StationModal />
            ) : s_TabKey === 'Line' ? (
              <LineModal />
            ) : null}
          </CustomModal>
        ) : null}
      </DataContext.Provider>
    </>
  )
};

export default FunctionProject;