import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import moment from 'moment';

import Title from '../../components/Custom/Title';

import TitleContainer from './TitleContainer';
import ChartContainer from './ChartContainer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DataContext = React.createContext();

const Trends = () => {

  // 要搜尋的資料
  const [ c_searchData, set_c_searchData ] = useState({    
    'freq': 60,                                           // 多久一次
    'pdtlot': '',                                         // 批號
    'wlID': '',                                           // 線別
    'start': moment(new Date()).format('YYYY-MM-DD'),     // 開始日期
    'end': moment(new Date()).format('YYYY-MM-DD'),       // 結束日期
  });

  const [ Col_num, set_Col_num ] = useState(24);

  // 控制箭頭要顯示哪個方向
  const [ c_drag, set_c_drag ] = useState('');

  // 所有指標參數
  const [ c_all_param, set_c_all_param ] = useState({
    'n2tube': '01_氮氣',
    'o2tube': '02_氮氣',
    'cwpipe': '03_冰水',
    'wpipe': '04_冷卻水',
    'airtube': '05_氣壓',
    'outTemp': '06_煤油溫度(槽外溫度)',
    'timer': '07_段數時間',
    'setTemp': '08_設定溫度',
    'inTemp': '09_槽內溫度',
    'flowN': '10_氮氣流量',
    'flowO': '10_氧氣流量',
    'frequency': '11_轉速',
    'torque': '12_扭力',
    'weight': '15_重量',
    'catchW': '16_收集槽重量',
  });

  // 指標參數顯示設定
  const [ c_param, set_c_param ] = useState({
    'show': [
      'inTemp',
      'frequency',
      'torque',
      'weight',
    ],
    'display': [
      'n2tube',
      'o2tube',
      'cwpipe',
      'wpipe',
      'airtube',
      'outTemp',
      'timer',
      'setTemp',
      'flowN',
      'flowO',
      'catchW',
    ]
  });

  // useEffect(() => {
  //   console.log('c_searchData = ', c_searchData)
  // })

  return (
    <DataContext.Provider value={{c_all_param, c_param, set_c_param, c_drag, Col_num, set_Col_num, set_c_drag, c_searchData}}>
      <Wrapper>
        <Title title="製程趨勢分析">
          <TitleContainer c_searchData={c_searchData} set_c_searchData={set_c_searchData} />
        </Title>
        <ChartContainer />
      </Wrapper>
    </DataContext.Provider>
  )
};

export default Trends;