import React, { useState, useEffect } from 'react';

import { Input, Button } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';


const Header = ({ handleClick, handleClose}) => {

  return (
    <div className="flex-r">

      <Button 
        onClick={handleClick} 
        type="primary" 
        style={{borderRadius: '5px'}} 
        icon={<FullscreenOutlined />} 
        size='large'
      >
        SOP投料全局項目編輯
      </Button>

      <Button
        type='danger'
        size='large'
        style={{marginLeft: '10px', borderRadius: '5px'}} 
        icon={<FullscreenExitOutlined />} 
        onClick={handleClose} 
      >
        隱藏明細檔
      </Button>
      
    </div>
  )
};

export default Header;