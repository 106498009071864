/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { MySubtitleH4 } from '../../../styled/publicTag';
import Title from '../../../components/Custom/Title';
import CustomTable from '../../../components/antdCustom/CustomTable';
import CustomModal from '../../../components/antdCustom/CustomModal';
import { CheckCircleFilled, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  IconTrash,
  IconPen
} from '../../../components/Icon';
import ModalContainer from './ModalContainer';
import useAPI from '../../../hooks/useApi';
import { getAllSiphd, deleteSiphd } from '../../../services/api/sipAPI';
import { message, Modal } from 'antd';

const innerWidth = window.innerWidth;

const HD = ({ c_lightData, set_c_lightData }) => {

  const [ s_tableData, set_s_tableData ] = useState([]);

  const call_getAllSiphd = useAPI(getAllSiphd);
  const call_deleteSiphd = useAPI(deleteSiphd);

  const [ is_showModal, set_is_showModal ] = useState(false);     // 是否開啟 dialog
  const [ s_editData, set_s_editData ] = useState({});          // 要編輯的資料

  const [scrollY, setScrollY] = useState("");

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const tableColumns = [
    {
      title: "SIP文件編號",
      dataIndex: "sipID",
      key: "sipID",
      align: "center",
      // width: innerWidth <= 1440 ? `${200}px` : `${300}px`
    },
    {
      title: "版碼",
      dataIndex: "version",
      key: "version",
      align: "center",
      width: innerWidth <= 1440 ? `${110}px` : `${100}px`,
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      align: "center",
      width: innerWidth <= 1440 ? `${150}px` : `${180}px`,
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      align: "left",
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
    },
    {
      title: "品檢類型",
      dataIndex: "siptype",
      key: "siptype",
      align: "center",
      render: (item) => {
        const map = {
          '1': 'lQC進料檢驗',
          '2': 'lPQC製中檢驗',
          '3': 'AlPQC自動製中檢驗',
          '4': 'SQC過站檢驗',
          '5': 'FQC終品檢驗',
          '6': 'OQC出貨檢驗',
          '7': 'FAl首件檢驗'
        }
        return map[item]
      },
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      key: "ctrlspot",
      align: "center",
      render: (item) => {
        return item === "wl" ? "工線檢驗" : "工站檢驗"
      },
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
    },
    {
      title: "控點編號",
      dataIndex: "ctrlspot",
      key: "ctrlspot",
      align: "center",
      render: (_, items) => {
        return items.ctrlspot === "wl" ? items.wlNM : items.wsNM
      },
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
    },
    {
      title: "備註",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${280}px`,
    },
    {
      title: "有效",
      dataIndex: "isvalid",
      key: "isvalid",
      align: "center",
      render: (item) => {
        return (
          <div className="checkIcon">
            {item === "0" ? <CloseCircleOutlined style={{ color: 'gray' }} /> : <CheckCircleFilled className='successIcon' />}
          </div>
        )
      },

      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: "更新時間",
      dataIndex: "newUpdtT",
      key: "newUpdtT",
      align: "center",
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
    },
    {
      title: "操作",
      dataIndex: "render",
      key: "render",
      align: "center",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex" }}>
            <div className="icon">
              <IconPen
                onClick={(e) => {
                  e.stopPropagation();
                  editHD(rowData);

                }}
              />
            </div>
            <div className="icon">
              <IconTrash
                onClick={(e) => {
                  e.stopPropagation();
                  removeHD(rowData);
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const onSearch = () => {
    alert('wait');
  };

  const handleChange = () => {
    alert('wait');
  };

  const editHD = rowData => {
    set_s_editData(rowData);
    set_is_showModal(true);
  };

  const removeHD = rowData => {
    Modal.confirm({
      title: '檢驗標準資料刪除確認',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      content: '請先確認要刪除的檢驗標準資料無誤，再進行刪除',
      okText: '確定',
      onOk: () => call_deleteSiphd.request(rowData),
      cancelText: '取消',
    });
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    call_getAllSiphd.request();
  }, []);
   
  // 取得表頭資料API
  useEffect(() => {
    if(call_getAllSiphd.status === 'suc') {
      message.success(call_getAllSiphd.msg);
      const tmpData = call_getAllSiphd.data?.map((item, index) => {
        return {
          ...item,
          key: index
        }
      }) ?? [];
      set_s_tableData(tmpData);
    }
    if(call_getAllSiphd.status === 'err') {
      message.error(call_getAllSiphd.msg);
      set_s_tableData([]);
    }
  }, [call_getAllSiphd.status]);

  // 刪除表頭資料
  useEffect(() => {
    if(call_deleteSiphd.status === 'suc') {
      message.success(call_deleteSiphd.msg);
      set_c_lightData({});
      call_getAllSiphd.request();
    }
    if(call_deleteSiphd.status === 'err') {
      message.error(call_deleteSiphd.msg);
      set_c_lightData({});
    }
  }, [call_deleteSiphd.status]);

   // 表身有資料、RWD 時計算高度
   useEffect(() => {
    const ROWHEIGHT = 40;
    // macbook 尺寸
    if(innerWidth >= 1440) {
      // alert(innerWidth)
      // 沒有表身資料
      if(Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 12);
      } 
      else {
        setScrollY(ROWHEIGHT * 6);
      }
    }
    
    // 一般螢幕尺寸
    if(innerWidth >= 1920) {
      // 沒有表身資料
      if(Object.keys(c_lightData).length === 0) {
        setScrollY(ROWHEIGHT * 15);
      } 
      else {
        setScrollY(ROWHEIGHT * 9);
      }
    }

  }, [innerWidth, c_lightData]);

  return (
    <div>
      <Title
        title='檢驗標準管理'
        placeholder='SIP文件編號查詢'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={() => set_is_showModal(true)}
      />
      <CustomTable
        scroll={{ x: 'max-content', y: scrollY }}
        columns={tableColumns}
        dataSource={s_tableData.map((item, index) => {
          return {
            key: index,
            ...item
          }
        })}
        rowClassName={(record) => {
          const rowKey = record.sipID + record.version + record.spotID;
          const dataKey = c_lightData.sipID + c_lightData.version + c_lightData.spotID;
          return rowKey === dataKey ? "clickRowStyle" : "";
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              const rowKey = record.sipID + record.version + record.spotID;
              const dataKey = c_lightData.sipID + c_lightData.version + c_lightData.spotID;
              if (rowKey === dataKey) {
                set_c_lightData({})
              } else {
                set_c_lightData(record)
              }
            }
          }
        }}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      <CustomModal
        width='60%'
        title={Object.keys(s_editData).length > 0 ? "修改檢驗標準資料" : "新建檢驗標準資料"}
        visible={is_showModal}
        maskClosable={false}
        destroyOnClose
        footer={null}
        forceRender
        getContainer={false}
        onCancel={() => set_is_showModal(false)}
        
      >
        {is_showModal && (
          <ModalContainer 
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            set_is_showModal={set_is_showModal}
            call_getAllSiphd={call_getAllSiphd}
          />
        )}
      </CustomModal>

    </div>
  )
};

export default HD;