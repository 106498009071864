import React, { useContext } from 'react';
import styled from 'styled-components';
import { DataContext } from '../../index';

import { Form, Button, Row, Col, Select } from 'antd';

const Wrapper = styled.div`

`
const StyledForm = styled(Form)`
  
  .ant-input {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({ set_is_showModal, s_keys, set_s_keys }) => {

  const [form] = Form.useForm();
  const { c_searchData, c_param, c_all_param } = useContext(DataContext);

  const options = c_param.show.length > 0 && c_param.show.map(item => {
    return { id: item, title: c_all_param[item].slice(3) }
  })
  console.log(options)
  // const options = [
  //   { id: 'inTemp', title: '槽內溫度' },
  //   { id: 'weight', title: '重量' },
  //   { id: 'flowN', title: '氮氣流量' },
  //   { id: 'torque', title: '扭力' },
  //   { id: 'frequency', title: '攪拌頻率' },
  // ]

  const onSubmit = values => {
    let tmpPlots = [...s_keys];
    tmpPlots.push(values);
    set_s_keys(tmpPlots);
    set_is_showModal(false);
    // console.log('values = ', values)
  }

  const handleCancel = () => {
    set_is_showModal(false)
  }

  return (
    <Wrapper>
      <StyledForm
        onFinish={onSubmit}
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label={<label style={{ color: '#6C6C6C' }}>X Field</label>}
              name="xField"
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: '請選擇 X Field!' }]}
            >
              <Select placeholder="請選擇 X Field" allowClear >
                {options.map(item => {
                  return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item
              label={<label style={{ color: '#6C6C6C' }}>Y Field</label>}
              name="yField"
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: '請選擇 Y Field!' }]}
            >
              <Select placeholder="請選擇 Y Field" allowClear >
                {options.map(item => {
                  return <Select.Option key={item.id} value={item.id}>{item.title}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size='large' style={{ marginRight: '20px', borderRadius: '5px' }} onClick={handleCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{ borderRadius: '5px' }}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </Wrapper>
  )
};

export default ModalContainer;