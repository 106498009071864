import { useState, useEffect } from "react";
import { changeTheme } from "../redux/action";
import { useDispatch } from "react-redux";
import { change_antd_theme, antdLight, antdDark } from "../styles/antdStyles";

const useTheme = () => {
  const dispatch = useDispatch();

  const thisTheme = localStorage.getItem("theme");

  const [theme, setTheme] = useState(thisTheme ? thisTheme : "light");

  useEffect(() => {
    switch (theme) {
      case "light":
        localStorage.setItem("theme", "light");
        change_antd_theme(antdLight);
        dispatch(changeTheme("light"));
        break;
      case "dark":
        localStorage.setItem("theme", "dark");
        change_antd_theme(antdDark);
        dispatch(changeTheme("dark"));
        break;
      default:
        localStorage.setItem("theme", "light");
        change_antd_theme(antdLight);
        dispatch(changeTheme("light"));
    }
    // eslint-disable-next-line
  }, [theme]);

  return [theme, setTheme];
};

export default useTheme;
