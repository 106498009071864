import { combineReducers } from 'redux'
import loadingReducer from './loading'
import navBarReducer from './navBarState'
import nameReducer from './nameState';
import navBarInlineReducer from './navBarInline'
import themeReducer from './theme';
import userStateReducer from './userState'

//註冊所有Reduser
const allReducers = combineReducers({
  loading: loadingReducer,
  navBar: navBarReducer,
  navBarInline: navBarInlineReducer,
  name: nameReducer,
  theme: themeReducer,
  userState: userStateReducer
})
export default allReducers