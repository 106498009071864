import apiBasic from "../apiBasic";
import { instance } from "../handelApi";

// /getAllProduct 取得所有料號
export const getAllProduct = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllProduct`);
};

export const getAllWs = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWs`);
};

export const getAllWl = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWl`);
};

export const getUserPerm = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getUserPerm`);
};

export const login = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/login`, data);
};

export const logout = () => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/logout`);
};